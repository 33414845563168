import imgExtratoDisabled from "../../../assets/img/extrato-disabled.svg";
import { EmptyStyled } from "./styles";

export function Empty() {
    return (
        <EmptyStyled>
            <img src={imgExtratoDisabled} alt="" />
            <p>Não há movimentações para exibição do extrato</p>
        </EmptyStyled>
    );
}
