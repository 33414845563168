/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";

import { UseLogin } from "../../../Hook/user/UseLogin";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { UseModal } from "../../../Hook/Usemodal";
import { FooterLogin } from "./footer";
import { ContentLogin } from "./contentLogin";
import { Manutencao } from "./manutencao";
import ModalRegister from "../ModalRegisterLogin";
import Modal from "../../NovoModal";
import ModalBottom from "../../ModalBottom";

import { Container } from "./styles";

import { VincularUsuario } from "../VincularUsuario";
import { ModalCheckUser } from "./modalCheckUser";

export function Logindesktop() {
    const width = window.screen.width;
    const { modal, setModal, user } = UseLogin();
    const { state, handleClose } = UseModal();
    const { resetForm } = useFormLogin();
    const [register, setRegister] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const hasRegister = ref.current && register;

    useEffect(() => {
        if (hasRegister) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: "smooth",
            });
        }
    }, [register, hasRegister]);

    return (
        <Container className={`${register ? "active" : ""}`}>
            <div className="content-img-text">
                <ContentLogin
                    ref={ref}
                    hasRegister={hasRegister}
                    setRegister={setRegister}
                />

                {/* <Manutencao /> */}
            </div>

            {width <= 768 && !hasRegister && <FooterLogin />}

            {modal ? (
                <ModalRegister
                    open={modal}
                    handleClose={() => setModal(false)}
                />
            ) : (
                <></>
            )}

            <Modal
                open={state.vincularUsuarioCampanha}
                handleClose={() => {
                    handleClose("vincularUsuarioCampanha");
                    resetForm();
                }}
            >
                <VincularUsuario />
            </Modal>
            <ModalBottom
                open={state.loginCheck}
                handleClose={() => {
                    handleClose("vincularUsuarioCampanha");
                    resetForm();
                }}
            >
                {state.loginCheck ? (
                    <ModalCheckUser phone={user?.phone_number || ""} />
                ) : (
                    <></>
                )}
            </ModalBottom>
        </Container>
    );
}
