import styled from "styled-components";
import { Button } from "../Mobile/Button";

export const Container = styled.div`
    text-align: center;
    ${Button} {
        border: 1px solid ${(props) => props.theme.colors.secondary};
        &:hover {
            background-color: ${(props) =>
                props.theme.colors.secondarydark} !important;
        }
    }
    button {
    }
`;
