import { EmptyStyles } from "./styles";
import { useEffect, useState } from "react";
import { Button } from "../../Desktop/Button";

import { UseModal } from "../../../Hook/Usemodal";
import { podesolicitarCartao, getCampanha } from "../../../util/helper";
import { options } from "../../NovoSelect";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

export function Empty() {
    const { setState, state, handleOpen } = UseModal();
    const { cartoes } = UseCartoes();
    const [options, setOptions] = useState<options[]>([]);

    function handleClick() {
        if (!options.length) {
            setState({
                ...state,
                successGeral: { ...state?.successGeral },
                errorGeral: {
                    active: true,
                    message: cartoes.length
                        ? "VOCÊ NÃO PODE MAIS SOLICITAR CARTÕES"
                        : "VOCÊ NÃO POSSUI SALDO SUFICIENTE PARA SOLICITAR UM CARTÃO.",
                    title: "Erro",
                },
            });
        } else {
            handleOpen("diferencaCartoes");
        }
    }

    useEffect(() => {
        if (getCampanha().id) {
            setOptions(podesolicitarCartao([getCampanha()], cartoes));
        }
    }, [cartoes]);

    return (
        <EmptyStyles>
            <h3>Meus cartões</h3>

            <div className="content-itens">
                <img src="" alt="" />
                <p>
                    VOCÊ NÃO POSSUI CARTÕES NO MOMENTO. <br />
                    CLIQUE NO BOTÃO ABAIXO pARA SOLICITAR.
                </p>

                <Button onClick={handleClick} p="0 20px">
                    SOLICITAR / INSERIR CARTÃO{" "}
                </Button>
            </div>
        </EmptyStyles>
    );
}
