import { SucessoStyles } from "./styles";
import imgSuccess from "../../../assets/img/sucesso.svg";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";

export function Sucesso() {
    const { handleClose } = UseModal();

    return (
        <SucessoStyles>
            <img src={imgSuccess} alt="" />
            <h1>Senha Alterada<br/>
                com Sucesso!
            </h1>
            <p>Use sua nova senha para acessar seu<br/>
            dashboard na YETZPAY!</p>
            <Button onClick={() => handleClose("esqueciSenha")}>
                Efetuar Login
            </Button>
        </SucessoStyles>
    );
}
