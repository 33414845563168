import { Text } from "alisson-application";

export function TextPadrao() {
    return (
        <>
            <Text as="h3">Precisamos validar sua solicitação</Text>
            <Text as="p" color="primaryDark">
                Para isso enviaremos um código de verificação para o número:
            </Text>
        </>
    );
}
