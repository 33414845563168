import { useHistory } from "react-router-dom";
import { PageSegundaVia } from "./segundaVia";
import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { HeaderPassos } from "../HeaderPassos";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { Container } from "./styles";
import { useEffect } from "react";
import { Validadar } from "../Validar";
import { FormDigitarSenha } from "../../Mobile/FormDigitarSenha";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
];

export function SegundaVia() {
    const { t } = useTranslation();
    const { passos, setPassos, nextPasso, dados, setDados } = usePassos();
    const { state, setState } = UseModal();
    const { cancelarCartao } = UseCartoes();
    const width = window.screen.width;
    const history = useHistory();
    const hasPath = history.location.pathname.includes("/cancelar-cartao");

    async function handleCancelar() {
        const { cardActive, currentCard, ...resto } = dados;

        const response = await cancelarCartao(resto);

        if (response.status !== 500) {
            setState({
                ...state,
                segundaVia: false,
                successGeral: {
                    active: true,
                    message: "Cartão cancelado com sucesso!",
                    title: "Sucesso",
                },
            });
        }
        setPassos(arrPassos);
    }

    function handleSubimit(values: any) {
        setDados(values);
        nextPasso(3);
    }

    useEffect(() => {
        if (state?.segundaVia || hasPath) {
            setPassos(arrPassos);
        }
    }, [setPassos, hasPath, state]);

    return (
        <Container>
            <HeaderPassos
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, segundaVia: false });
                    }
                }}
                title={t("CANCELAR CARTÃO")}
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active && <PageSegundaVia />}
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                        <FormDigitarSenha handleSubimit={handleSubimit} />
                    ) : null}
                </div>
                <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                    {passos[2]?.active && (
                        <Validadar handleClick={handleCancelar} />
                    )}
                </div>
            </div>
        </Container>
    );
}
