import { Container } from "./styles";
import { Loader } from "../../../Loader";
import { useEffect, useState } from "react";
import { Delivery } from "../../../Entrega";

import imgEntrega from "../../../../assets/img/entrega.svg";

interface CardProps {
    loading: boolean;
    dados: Delivery[];
}

export function Card(props: CardProps) {
    const { dados = [], loading } = props;
    const [date, setDate] = useState("");
    const [link, setLink] = useState("");
    const [lastItem, setLastItem] = useState<number | null>(null);

    useEffect(() => {
        if (loading) {
            setLink("");
        }
    }, [loading]);

    useEffect(() => {
        if (dados.length > 0) {
            const dias = dados[0]?.date ? dados[0]?.date : "";

            const haslink = dados.find((item) => item.linkrastreio);
            if (haslink?.linkrastreio) {
                setLink(haslink.linkrastreio);
            }
            const findLastIndexActive = dados
                .map((item) => item.active)
                .lastIndexOf(true);
            setLastItem(findLastIndexActive);

            setDate(dias);
        }
    }, [dados]);

    return (
        <Container>
            <div className="container-title">
                <img src={imgEntrega} alt="" />
                <p>status da entrega</p>
                <span>{date ? `Previsão de entrega: ${date}` : ""}</span>
            </div>

            {loading ? (
                <div className="loading">
                    <Loader height={128} width={128} />
                </div>
            ) : dados.length ? (
                <ul>
                    {dados?.map((item, key) => {
                        if (!item.descricao) return <></>;
                        
                        const last = lastItem === key ? "last" : "";
                        const done = (lastItem ?? 0) >= key ? "done" : "";
                        return (
                            <li
                                key={key}
                                className={`${
                                    item.active ? `active ${last}` : ""
                                }`}
                            >
                                <h4 className={done}>
                                    {item.descricao}
                                </h4>
                                <p>{item.date}</p>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <span>CARTÃO SOLICITADO. AGUARDANDO RETORNO DA POSTAGEM.</span>
            )}
            

            {link &&
                <div className="container-title bottom">
                        <div className="link">
                            <p>
                                <a
                                    href={link}
                                    target="_blank"
                                    className="container-text"
                                    rel="noreferrer"
                                >
                                    Clique aqui
                                </a>
                                para acompanhar o <br /> status no site da
                                transportadora.
                            </p>
                        </div>
                    
                </div>
            }
        </Container>
    );
}
