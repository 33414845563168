import styled from "styled-components";

export const EmptyStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        margin-bottom: 20.23px;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primaryDark};
    }
`;
