/* eslint-disable react/style-prop-object */
import { Text } from "alisson-application";

import { isMobile } from "mobile-device-detect";

import { Container } from "./styles";
import imgSuccess from "../../assets/img/success-user-side.svg";
import { tempoFimPlataforma, tempoInicioPlataforma } from "../../util/helper";
export function LimitesTarifas() {
    // function teste() {
    //     const keys = Object.keys(deviceDetect());

    //     return keys
    //         .map((item) => {
    //             return `${item} => ${deviceDetect()[item]}`;
    //         })
    //         .join(" ---  ");
    // }

    return (
        <Container>
            <img className="img-close" src={imgSuccess} alt="" />
            <div className={`content-tarifa ${isMobile ? "mobile" : ""}`}>
                <Text as="h1">Limites e taRIFAS</Text>

                <div className="container-itens">
                    <ul>
                        <li>
                            <span>Tarifa de emissão de cartão físico¹</span>
                            <p>R$ 15,00</p>
                        </li>
                        {/* <li>
            <span>Tarifa de emissão de cartão virtual</span>
            <p>R$ 1,90</p>
        </li> */}
                        <li>
                            <span>Frete para envio do cartão¹</span>
                            <p>R$ 15,00</p>
                        </li>
                        <li>
                            <span>
                                Limite de saque <br />
                                (até 2 transações diárias)¹
                            </span>
                            <p>R$ 500,00</p>
                        </li>
                        <li>
                            <span>
                                Tarifa de saque <br />
                                (caixa eletrônico do Banco 24h e/ou Rede Saque e
                                Pague e/ou Rede Cirus)¹
                            </span>
                            <p>R$ 6,90</p>
                        </li>

                        <li>
                            <span>Limite de transação por compra</span>
                            <p>R$ 9.990,00</p>
                        </li>

                        <li>
                            <span>Tarifa de SMS (opcional)</span>
                            <p>R$ 2,00 (mensal)</p>
                        </li>

                        <li>
                            <span>
                                Tarifa de Inatividade <br />
                                (após 6 meses)
                            </span>
                            <p>R$ 7,90 (mensal)</p>
                        </li>

                        <li>
                            <span>TARIFA DE PAGAMENTO DE CONTAS</span>
                            <p>R$3,50</p>
                        </li>

                        <li className="linha-unica">
                            <span>
                                <strong>
                                    TRANSFERÊNCIAS DA CARTEIRA PARA:
                                </strong>{" "}
                                <br />
                                <br />
                                <strong>CARTÕES FÍSICOS</strong> ATÉ R$
                                10.000,00 <br />
                                MENSAIS, EM DIAS ÚTEIS, DAS{" "}
                                {tempoInicioPlataforma}H ÀS {tempoFimPlataforma}
                                H.
                                <br />
                                <br />
                                <strong>CARTÕES VIRTUAIS</strong> ATÉ R$ 500,00
                                MENSAIS,
                                <br /> EM DIAS ÚTEIS, DAS{" "}
                                {tempoInicioPlataforma}H ÀS {tempoFimPlataforma}
                                H (PARA
                                <br />
                                TRANSFERIR VALORES MAIS ALTOS, SOLICITE CARTÃO
                                FÍSICO).
                            </span>
                        </li>

                        <li className="linha-unica">
                            <span>
                                <strong>TRANSFERÊNCIA ENTRE CARTÕES:</strong>
                                ATÉ R$
                                <br />
                                500,00 POR SEMANA, EM DIAS ÚTEIS, DAS{" "}
                                {tempoInicioPlataforma}H <br /> ÀS{" "}
                                {tempoFimPlataforma}H.
                            </span>
                        </li>

                        <li className="linha-unica">
                            <span>
                                <strong>PAGAMENTO DE CONTAS</strong> COM:
                                <br />
                                <br />
                                <strong>CARTÕES FÍSICOS:</strong> ATÉ R$
                                10.000,00 MENSAIS, EM DIAS ÚTEIS, DAS{" "}
                                {tempoInicioPlataforma}H ÀS {tempoFimPlataforma}
                                H.
                                <br />
                                <br />
                                <strong>CARTÕES VIRTUAIS</strong>: ATÉ R$ 500,00
                                MENSAIS, EM DIAS ÚTEIS, DAS{" "}
                                {tempoInicioPlataforma}H ÀS {tempoFimPlataforma}
                                H.
                            </span>
                        </li>

                        <li>
                            <span>
                                ¹ Apenas para campanhas que contemplem esta
                                modalidade
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
    );
}
