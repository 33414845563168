import { Text } from "alisson-application";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { objCartaoes } from "../../../util/helper";
import { Card } from "../CardSimple";
import { FormAtivarCartao } from "./form";
import { Container } from "./styles";

interface FormDigitarSenhaProps {
    handleSubimit: (values: any) => void;
}

export function FormDigitarSenha(props: FormDigitarSenhaProps) {
    const { handleSubimit } = props;
    const { cartao } = UseCartoes();
    const card: any = cartao?.id ? objCartaoes([cartao])[0] : {};

    return (
        <Container>
            <Card {...card} />
            <div className="container">
                <Text as="p">Preencha os campos abaixo para prosseguir.</Text>
            </div>
            <FormAtivarCartao handleSubimit={handleSubimit} />
        </Container>
    );
}
