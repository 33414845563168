import { useHistory } from "react-router-dom";
import imgModalComprovante from "../../assets/img/modal-comprovante.svg";
import imgSetaEsq from "../../assets/img/seta-esq-verde.svg";
import { HeaderStyle } from "./styles";
import { UseModal } from "../../Hook/Usemodal";
export function Header() {
    const { handleClose } = UseModal();
    const history = useHistory();
    const width = window.screen.width;

    function handleClick() {
        if (width > 768) {
            handleClose("comprovanteDePagamento");
        } else {
            history.goBack();
        }
    }
    return (
        <HeaderStyle>
            <div className="container-img">
                <img src={imgModalComprovante} alt="comprovante" />
            </div>
            <button onClick={handleClick} className="container-title">
                <img src={imgSetaEsq} alt="" />
                <h4>Comprovantes de pagamento</h4>
            </button>
        </HeaderStyle>
    );
}
