import { useEffect } from "react";

import { Text } from "alisson-application";
import { HeaderPassos } from "./headerPassos";
import { FormDocumento } from "./documento";
import { Login } from "./login";
import { SelectCampanha } from "./selectCampanha";
import { FormCampanha } from "./campanha";
import { CompleteRegister } from "./register";
import { CompleteRegisterCNPJ } from "./registerCNPJ";
import { Endereco } from "./endereco";
import { CartaoNoname } from "./cartaoNoname";
import { usePassos } from "../../../Hook/UsePassos";
import { useFormLogin } from "../../../Hook/user/useFormLogin";

import imgLogo from "../../../assets/img/nova-logo.svg";
import { Container, Card } from "./styles";

const arrPassosPessoaFisica = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
];

const arrPassosPessoaJuridica = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
];

interface FormLoginProps {
    setRegister?: (value: boolean) => void;
}

export function FormLogin(props: FormLoginProps) {
    const { setRegister } = props;
    const width = window.screen.width;
    const { setPassos, passos } = usePassos();
    const { controllerForm, setControllerForm, setState, state } =
        useFormLogin();

    useEffect(() => {
        if (controllerForm.completeRegister) {
            if (state.document.length <= 11) {
                setPassos(arrPassosPessoaFisica);
            } else {
                setPassos(arrPassosPessoaJuridica);
            }
        }
    }, [controllerForm.completeRegister, setPassos, state.document.length]);

    useEffect(() => {
        if (controllerForm.completeRegister && setRegister) {
            setRegister(true);
        }
    }, [controllerForm, setRegister]);

    useEffect(() => {
        if (passos[1]?.active) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [passos]);

    function cancelar() {
        setState({
            document: "",
            campanha: "",
            name: "",
            account: "",
        });

        setControllerForm({
            documento: true,
            campanha: false,
            selectCampanha: false,
            cartaoNoname: false,
            completeRegister: false,
            password: false,
            endereco: false,
        });

        setPassos(arrPassosPessoaFisica);
        if (setRegister) {
            setRegister(false);
        }
    }

    return (
        <Container>
            <Card>
                <div className="text-login ">
                    {!controllerForm.completeRegister && (
                        <>
                            <img src={imgLogo} alt="" />
                            <Text as="p" textAlign="center" className="login">
                                Que bom ter você por aqui! <br /> Para
                                começarmos, informe seus dados de acesso.
                            </Text>
                        </>
                    )}
                </div>
                <div className="container-form-login">
                    {controllerForm.campanha && <FormCampanha />}

                    {controllerForm.documento && <FormDocumento />}

                    {controllerForm.cartaoNoname && <CartaoNoname />}

                    {controllerForm.password && <Login />}

                    {controllerForm.selectCampanha && <SelectCampanha />}

                    {controllerForm.completeRegister && (
                        <>
                            <HeaderPassos title="" />

                            <Text
                                textAlign="center"
                                className="text-passo"
                                m="0px"
                                width="369px"
                            >
                                {width >= 768
                                    ? "Excelente! É sua primeira vez na YETZPAY. Complete seu cadastro para poder acessar."
                                    : passos[0]?.active
                                    ? "Cadastro"
                                    : "ENDEREÇO"}
                            </Text>

                            <div className="container-tabs">
                                <div
                                    className={`tab ${
                                        passos[0]?.active ? "active" : ""
                                    }`}
                                >
                                    {state.document.length <= 11 ? (
                                        <CompleteRegister />
                                    ) : (
                                        <CompleteRegisterCNPJ />
                                    )}
                                </div>
                                <div
                                    className={`tab ${
                                        passos[1]?.active ? "active" : ""
                                    }`}
                                >
                                    <Endereco
                                        onClick={cancelar}
                                        state={state}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Card>
        </Container>
    );
}
