import { Box } from "alisson-application";
import styled from "styled-components";
import imgSms from "../../../assets/img/sms.svg";
import imgWhats from "../../../assets/img/whats.svg";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 18px;
    margin: 0;
`;

export const Title = styled.h1`
    color: #57b847;
    text-align: center;
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
`;

export const Description = styled.p`
    color: #000;
    text-align: center;
    font-family: Oxanium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    margin: 30px 0;

    b {
        font-weight: 700;
    }
`;

export const MobileNumberViewer = styled.input`
    width: 100%;
    border-radius: 20px;
    text-align: center;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: #5e5f5f;
    background-color: #e3e3e3;
    margin-bottom: 15px;
    border: none;
    height: 40px;
`;


export const Container2 = styled(Box)`
    h3 {
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
    }

    h4 {
        text-align: center;
    }

    p {
        padding: 0px !important;
        width: 260px;
        margin: 0 auto;
        text-align: center;
    }

    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    .enviar-sms {
        margin-top: 65px;
        img {
            margin-right: 11px;
        }
    }

    .container-reenviar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
            &:disabled {
                opacity: 0.5;
            }
            &.mais-opcao {
                font-family: "Oxanium";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.secondary};
                width: 100%;
                outline: none;
                &.active {
                    img {
                        transform: rotateX(0deg);
                        transition: all 0.5s ease;
                    }
                }
                img {
                    transform: rotateX(180deg);
                    margin-right: 11px;
                }
            }
        }
    }

    .container-btns {
        height: 0px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        &.active {
            padding-bottom: 40px;
            height: 168px;
            transition: all 0.3s ease-in-out;
        }
        button {
            font-family: "Oxanium";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.secondary};
            width: 100%;
            border: 2px solid ${({ theme }) => theme.colors.secondary};
            border-radius: 50%;
            border-radius: 32px;
            margin: 0px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-right: 8px;
            }
        }
    }

    button {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 29px;
        &.re-eneviar-sms {
            font-family: "Oxanium";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.secondary};
            display: flex;
            align-items: center;
            img {
                margin-right: 11px;
                path {
                    fill: ${({ theme }) => theme.colors.secondary};
                }
            }
        }
    }

    .container-timer {
        padding-top: 30px;
        margin-bottom: 23px;
        &.active {
        }
    }

    .container-submit {
        &.disabled {
            margin-top: 0px;
            .container-tipo {
                .card {
                    cursor: not-allowed;
                    opacity: 0.3;
                }
            }
        }
        p {
            padding-top: 30px;
            padding-bottom: 19px;
        }
    }

    .container-input {
        margin-bottom: 0px;
        margin-top: 32px;
        margin-bottom: 32px;
        input {
            font-style: normal;
            font-weight: bold;
            line-height: 100%;
            text-align: center;
            color: #5e5f5f !important;
            background-color: #e3e3e3;
            font-size: 14px;
            height: 40px;
            border: none;
            padding: 0;
        }
    }

    .container-tipo {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        .card {
            position: relative;
            background: ${({ theme }) => theme.colors.white};
            border: 1px solid ${({ theme }) => theme.colors.secondarydark};
            height: 93px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding-bottom: 21px;
            border-radius: 16px;
            cursor: pointer;
            transition: all 0.5s;
            small {
                color: ${({ theme }) => theme.colors.secondarydark};
                transition: all 0.5s;
            }

            &.active {
                background: ${({ theme }) => theme.colors.secondarydark};
                transition: all 0.5s;
                &:before {
                    background-color: ${(props) =>
                        props.theme.colors.white} !important;
                    transition: all 0.5s;
                }
                small {
                    color: ${({ theme }) => theme.colors.white};
                    transition: all 0.5s;
                }
            }
            &:hover {
                background: ${({ theme }) => theme.colors.secondarydark};
                transition: all 0.5s;
                &:before {
                    background-color: ${(props) =>
                        props.theme.colors.white} !important;
                    transition: all 0.5s;
                }
                small {
                    color: ${({ theme }) => theme.colors.white};
                    transition: all 0.5s;
                }
            }

            &.whats {
                &:before {
                    -webkit-mask: url(${imgWhats}) no-repeat;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.5s;
                }
            }
            &.sms {
                &:before {
                    -webkit-mask: url(${imgSms}) no-repeat;
                    position: absolute;
                    top: 35%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 0.5s;
                }
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 27px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};

                width: 24px;
                height: 24px;
                z-index: 20;
                transition: all 0.5s;
            }
        }
    }

    .container-options {
        .container-reenviar-novo {
            background-color: #efefef;
            border-radius: 100px;
            display: flex;
            align-items: center;
            height: 40px;
            padding-left: 32px;
            padding-right: 14px;
            margin-bottom: 36px;
            .container-img {
                display: flex;
                align-items: flex-end;
            }
            .container-text {
                margin-left: 8px;
                p {
                    margin: 0px;
                    width: 100%;
                    font-size: 12px;
                    text-align: left;
                }

                span {
                    font-size: 10px;
                    margin: 0px;
                    margin-bottom: -4px;
                    margin-top: 4px;
                    font-weight: bold;
                    display: block;
                }
            }
        }
        .container-liberar-bloqueio {
            background-color: #efefef;
            border-radius: 100px;
            display: flex;
            align-items: center;
            height: 40px;
            padding-left: 32px;
            padding-right: 14px;
            margin-bottom: 36px;
            .container-img {
                display: flex;
                align-items: flex-end;
            }
            .container-text {
                margin-left: 8px;
                p {
                    margin: 0px;
                    width: 100%;
                    font-size: 12px;
                    text-align: left;
                    margin-bottom: -8px;
                    margin-top: 4px;
                }

                span {
                    font-size: 10px;
                    margin: 0px;
                    font-weight: bold;
                }
            }
        }

        .container-input {
            display: grid;
            align-items: center;
            grid-template-columns: 24px 1fr 65px;
            grid-gap: 8px;
            margin-bottom: 32px;
            p {
                width: auto;
                margin: 0px;
            }
            .container-text-input {
                width: 100%;
                position: relative;
                p {
                    text-align: left;
                    position: absolute;
                    top: -16px;
                }
                input {
                    height: 40px;
                    font-size: 12px;
                }
            }

            button {
                margin: 0px;
                height: 40px;
                width: 100%;
                font-size: 10px;
            }
        }
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    height: 129px;
`;

