import { useHistory } from "react-router-dom";
import { boleto } from "boleto-brasileiro-validator";
import { Text, Box } from "alisson-application";
import { Button } from "../Mobile/Button";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { usePassos } from "../../Hook/UsePassos";
import { usePagamentoBoleto } from "../../Hook/user/usePagamentoBoleto";

import { When } from "../when";
import { arrPassosBoletoPagamento2 } from "../PagamentoBoleto/config";
import { LimitiCards } from "../Mobile/SolicitarResgate/limitiCards";

export function CodigoDeBarras() {
    const [value, setValue] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { setDados, dados, setPassos } = usePassos();

    const { getBoleto } = usePagamentoBoleto();
    const history = useHistory<{ barCode: string }>();
    const width = window.screen.width;

    useEffect(() => {
        if (history.location?.state?.barCode) {
            setValue(history.location.state.barCode);
            handleNextPasso(history.location.state.barCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleNextPasso(boleto?: string) {
        const currenValue = boleto || value;
        setLoading(true);
        const response = await getBoleto(currenValue.replace(/\D/g, ""));
        if (response.digitable) {
            setDados({
                ...dados,
                boleto: { ...response },
            });

            setPassos(arrPassosBoletoPagamento2);
            setValue("");
        }

        setIsValid(null);
        setLoading(false);
    }

    return (
        <Container>
            <When expr={width <= 1024}>
                <>
                    <Text as="p">Digite o código de barras:</Text>
                </>
            </When>

            <When expr={width >= 1024}>
                <Text as="p">Digite o código de barras:</Text>
            </When>

            <div
                className={`container-input
                ${isValid === true ? "active" : ""} 
                ${isValid === false ? "error" : ""}
                ${loading === true ? "disabled" : ""}
                `}
            >
                <input
                    placeholder="00000.00000 00000.000000 00000.000000 0 00000000000000"
                    type="tel"
                    disabled={loading}
                    onChange={(e) => {
                        const value = e.target.value
                            .replace(/[^\d]+/g, "")
                            .trim();

                        setIsValid(boleto(value.trim()));

                        setValue(value);

                        if (!value) setIsValid(null);
                    }}
                    value={value}
                />
            </div>

            <Box mt="40px">
                <LimitiCards />
            </Box>

            <div
            // className={`container-btn ${isValid === true ? "active" : ""}`}
            >
                <Button
                    disabled={!isValid}
                    onClick={() => handleNextPasso(value)}
                    isLoading={loading}
                >
                    AVANÇAR
                </Button>
            </div>
        </Container>
    );
}
