import { Box, Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { motion } from "framer-motion";
import { useState } from "react";
import Mask from "react-input-mask";

import { UseModal } from "../../../Hook/Usemodal";
import { UseLogin } from "../../../Hook/user/UseLogin";

import { Button } from "../Button";
import { password, Password, validationPassword } from "./config";

import imgOlho from "../../../assets/img/olho.svg";
import { useFormLogin } from "../../../Hook/user/useFormLogin";

export function Login() {
    const { login } = UseLogin();
    const { handleOpen } = UseModal();
    const { controllerForm, setControllerForm, state } = useFormLogin();
    const [loading, setLoading] = useState(false);
    const [olhoSenha, setOlhoSenha] = useState(false);

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={{ ...password, document: state?.document }}
            validationSchema={validationPassword}
            onSubmit={async (values, actions) => {
                setLoading(true);
                const cpf = values.document.replace(/[^\d]+/g, "");

                const response = await login({
                    document: cpf,
                    password: values.password,
                });

                if (response.status === 200) {
                    if (response.data.campanhas.length > 1) {
                        setControllerForm({
                            ...controllerForm,
                            password: false,
                            selectCampanha: true,
                        });
                    } else {
                        handleOpen("loginCheck");
                    }
                }

                if (response.status === 401) {
                    const data: any = response.data;
                    const attempts = data.attempts;

                    handleOpen("avisoSenhaInvalida", { attempts });
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<Password>) => {
                const { values } = props;
                const { document } = values;

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.4,
                            }}
                            className={`container-input chave chave-campanha active disabled`}
                        >
                            <Field
                                placeholder="Campanha"
                                name="campanha"
                                type="text"
                                autoComplete="username"
                                disabled
                                value={state.campanha}
                            />
                        </motion.div>
                        <ErrorMessage name="campanha" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.6,
                            }}
                            className={`container-input user active disabled`}
                        >
                            <Mask
                                mask={
                                    document.length <= 11
                                        ? "999.999.999-99"
                                        : "99.999.999/9999-99"
                                }
                                placeholder="CPF/CNPJ"
                                name="document"
                                type="tel"
                                autoComplete="organization"
                                value={document}
                                disabled
                            />
                        </motion.div>
                        <ErrorMessage name="documento" component="span" />

                        <Box mb="32px">
                            <motion.div
                                animate={{
                                    scale: [0.1, 1],
                                }}
                                transition={{
                                    duration: 0.8,
                                }}
                                className={`container-input olho senha `}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    placeholder="SENHA"
                                    autoComplete="current-password"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </motion.div>
                            <button
                                type="button"
                                className="esqueci-senha"
                                onClick={() => handleOpen("esqueciSenha")}
                            >
                                <Text
                                    color="white"
                                    pl="32px"
                                    textAlign="center"
                                >
                                    Esqueci minha senha
                                </Text>
                            </button>
                            <ErrorMessage name="documento" component="span" />
                        </Box>

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                spinerColor="primary"
                                isLoading={loading}
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                                className="login"
                            >
                                ENTRAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
