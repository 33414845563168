import styled from "styled-components";

export const Container = styled.div`
    .container-datas {
        border-radius: 17px;
        background: #f3f3f3;
        padding: 26px 12px 25px;
        margin-bottom: 33px;
    }

    .container-input {
        input {
            padding-left: 31px;
            font-size: 1rem;
            font-weight: bold;
        }
        text-align: center;
        &.descricao {
            input {
                font-weight: 400;
                text-transform: unset;
            }
        }
        &.disabled {
            input {
                background-color: #e1e2e3 !important;
                border: 1px solid #e1e2e3;
            }
        }
    }

    .container-detalhes {
        text-align: center;
    }
`;
