import styled from "styled-components";
import Yimg from "../../../assets/img/Y.svg";

interface CardProps {
    background: string;
}

export const CardStyles = styled.div<CardProps>`
    background: ${(props) => props.background};
    border-radius: 8px;
    padding: 31px 5.5px 0px 15.36px;
    height: 252.67px;
    width: 158.97px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    margin: 0 auto;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: ${(props) => props.theme.colors.white};
        -webkit-mask: url(${Yimg}) no-repeat;
        width: 158.97px;
        height: 252.67px;
        z-index: 10;
        -webkit-mask-position: 0px 0px;
        -webkit-mask-size: 193.97px;
        transition: background-color 0.5s;
    }
    .container-bandeira-chip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 105px;
        position: relative;
        z-index: 30;
    }

    .container-logo {
        margin-top: auto;
        text-align: right;
    }

    .saldo {
        border: 0.658986px solid ${(props) => props.theme.colors.white};
        height: 35px;
        border-radius: 2.63594px;
    }
`;
