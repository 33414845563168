import { useEffect, useState } from "react";
import { Text, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { HistoryType } from "../DetalheMovimentacao/type";
import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useUser } from "../../../Hook/user/UseUser";
import { useTranslation } from "../../../contexts/Localization";
import { useTheme } from "../../../contexts/ThemeContext";
import {
    formatCurrentMoney,
    formataMoney,
    getCampanha,
    podesolicitarCartao,
} from "../../../util/helper";
import { Card } from "./card";
import { DobleText } from "../DobleText";
import { Button } from "../Button";
import { SolicitarStyles } from "./styles";

import imgSetaDir from "../../../assets/img/seta-dir-verde.svg";
import imgSetaEsq from "../../../assets/img/seta-esq-verde.svg";

import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import { LimitiCards } from "./limitiCards";
import { UseModal } from "../../../Hook/Usemodal";
SwiperCore.use([Navigation, A11y]);

interface SolicitarProps {
    sethasFirstResgate: (value: boolean) => void;
}

export function Solicitar(props: SolicitarProps) {
    const { sethasFirstResgate } = props;
    const history = useHistory<HistoryType>();
    const index = history.location.state?.id;
    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
    const [solicitarCartao, setSolicitarCartao] = useState<boolean>(false);
    const [firstSwiper, setFirstSwiper] = useState<any>({} as any);
    const [value, setValue] = useState<string>("");
    const [indexSwiper, setIndexSwiper] = useState<number>(0);
    const { nextPasso, setDados } = usePassos();
    const { state, setState } = UseModal();
    const { t } = useTranslation();
    const { user } = useUser();
    const { currentTheme } = useTheme();
    const { cartoes, cartaoId } = UseCartoes();
    const saldo = parseFloat(user.saldo + "" || "0");
    const inativo = cartoes[indexSwiper]
        ? cartoes[indexSwiper]?.status !== "ATIVO"
            ? true
            : false
        : false;
    const primeiroResgate = cartoes[indexSwiper]
        ? cartoes[indexSwiper]?.resgates_count === 0
            ? true
            : false
        : false;

    const cards = cartoes?.map((cartao) => {
        const cardNumber = cartao?.acg_account_card_last4digits;
        return {
            price: `R$ ${formatCurrentMoney(parseFloat(user.saldo))}`,
            number:
                cartao.tipo === "cartao_fisico" ||
                cartao?.tipo === "cartao_avulso"
                    ? cartao.acg_account_card_last4digits
                    : cardNumber,
            type:
                cartao?.tipo === "cartao_fisico" ||
                cartao?.tipo === "cartao_avulso"
                    ? t("FÍSICO")
                    : t("VIRTUAL"),
            color:
                cartao?.tipo === "cartao_fisico" ||
                cartao?.tipo === "cartao_avulso"
                    ? currentTheme.colors.tertiarybright
                    : currentTheme.colors.tertiarydark,
            inativo: cartao?.status !== "ATIVO" ? cartao?.status : "",
        };
    });

    function handleClick() {
        user.saldo = user.saldo + "";

        const tempSaldo = parseFloat(user.saldo);

        const tempValue = parseFloat(
            value ? value.replace(/[^0-9!,]/g, "").replace(",", ".") : "0"
        );

        // alert(`${tempSaldo} ${tempValue}`);

        if (tempValue === 0) {
            toast.error(t("Digite um valor"));
            return;
        }

        if (inativo) {
            toast.error("Cartão bloqueado");
            return;
        }

        if (tempValue <= tempSaldo) {
            setDados({
                valor: tempValue,
                saldo: user.saldo,
                subTotal: tempSaldo - tempValue,
                currentCard: cartoes[indexSwiper],
                cartao_id: cartoes[indexSwiper].acg_account_card_id,
            });
            nextPasso(2);
        } else {
            toast.error(t("Saldo insuficiente"));
        }
    }

    useEffect(() => {
        const indexItem = cartoes.findIndex((cartao) => cartao.id === cartaoId);
        setIndexSwiper(indexItem);

        if (firstSwiper.navigation && Number.isInteger(indexItem)) {
            firstSwiper.slideTo(indexItem, 0);
        }
    }, [cartaoId, cartoes, firstSwiper]);

    useEffect(() => {
        if (primeiroResgate) {
            sethasFirstResgate(true);
        } else {
            sethasFirstResgate(false);
        }
    }, [primeiroResgate, sethasFirstResgate]);

    useEffect(() => {
        const cartaoFisico = podesolicitarCartao([getCampanha()], cartoes).find(
            (item) => item.value === "cartao_fisico"
        );

        if (cartaoFisico?.value === "cartao_fisico" && saldo > 30) {
            setSolicitarCartao(true);
        }
    }, [cartoes, saldo]);

    // useEffect(() => {
    //     const currentValue = parseFloat(
    //         value.replace(/[^0-9!,]/g, "").replace(",", ".") || "0"
    //     );
    //     const current
    //     if (currentValue > 500) {
    //         toast.error("Valor máximo de R$ 500,00");
    //         setValue("R$ 0,00");
    //     }
    // }, [value]);

    return (
        <SolicitarStyles className="container">
            <Text as="h3" bold={true}>
                O valor será retirado do saldo da carteira e adicionado no
                cartão abaixo
            </Text>

            <div className="container-slide">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    className="slide-cartoes"
                    onActiveIndexChange={(swiper) => {
                        setIndexSwiper(swiper.realIndex);
                    }}
                    observer={true}
                    onSwiper={(swiper) => setFirstSwiper(swiper)}
                    initialSlide={index ? index : indexSwiper}
                    navigation={{
                        nextEl,
                        prevEl,
                    }}
                >
                    {cards?.map((item, key) => {
                        const { inativo, ...resto } = item;

                        return (
                            <SwiperSlide
                                className={`detalhe-card ${
                                    inativo ? "inativo" : ""
                                }`}
                                key={key}
                            >
                                <Card {...resto} />
                                <div className="container-info">
                                    <Text as="h4">{item.number}</Text>
                                </div>
                                {inativo && (
                                    <span className="bloqueado">{inativo}</span>
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                <div className="controles">
                    <div ref={(node) => setPrevEl(node)} className="rotate-y">
                        <img src={imgSetaEsq} alt="" />
                    </div>
                    <div ref={(node) => setNextEl(node)}>
                        <img src={imgSetaDir} alt="" />
                    </div>
                </div>
            </div>

            <Text textTransform="uppercase" as="small"></Text>

            <div
                className={`container-input valor ${inativo ? "disabled" : ""}`}
            >
                <input
                    type="tel"
                    placeholder="R$ 0,00"
                    value={value}
                    disabled={inativo}
                    onChange={(e) => {
                        if (e.target.value.length) {
                            setValue(formataMoney(e.target.value));
                        }
                    }}
                />
            </div>

            <Box m="0 auto" mb="30px">
                <DobleText
                    title="SALDO DA CARTEIRA DISPONÍVEL"
                    text={`R$ ${formatCurrentMoney(parseFloat(user.saldo))}`}
                />
            </Box>

            <LimitiCards />

            <Box mb="8px">
                <Button
                    type="button"
                    onClick={() => {
                        setState({
                            ...state,
                            solicitarResgate: false,
                            diferencaCartoes: true,
                        });
                    }}
                    disabled={
                        !solicitarCartao ||
                        cartoes[indexSwiper]?.tipo !== "cartao_virtual"
                    }
                >
                    Solicitar cartão físico
                </Button>
            </Box>
            <Button type="button" onClick={handleClick} disabled={inativo}>
                {t("avançar")}
            </Button>
        </SolicitarStyles>
    );
}
