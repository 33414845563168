import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "90%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "auto",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        maxHeight: "95vh",
        overflowY: "unset",
        borderRadius: "10px",
        "& > div": {
            overflowY: "auto",
            alignItems: "center",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
                "-webkit-appearance": "none",
            },
            "&::-webkit-scrollbar:vertical": {
                width: "5px",
            },
            "&::-webkit-scrollbar:horizontal": {
                height: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(89, 92, 118, 0.5)",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
        },
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "375px",
            minWidth: "auto",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
            },
        },
    },
    "@media (max-width: 480px)": {
        "&.popup": {
            ".MuiPaper-root": {
                maxWidth: "90% !important",
                maxHeight: "90% !important",
                minWidth: "auto",
                minHeight: "auto",
                width: "auto",
            },
        },
    },

    "&.popup": {
        ".MuiPaper-root": {
            maxWidth: "900px",
            maxHeight: "600px",
            width: "auto",
        },
    },
}));

export const ModalPopUpStyles = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "90%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "auto",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        maxHeight: "95vh",
        overflowY: "unset",
        backgroundColor: "transparent",
        "& > div": {
            overflowY: "unset",
            alignItems: "center",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
                "-webkit-appearance": "none",
            },
            "&::-webkit-scrollbar:vertical": {
                width: "5px",
            },
            "&::-webkit-scrollbar:horizontal": {
                height: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(89, 92, 118, 0.5)",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
        },
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "375px",
            minWidth: "auto",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
            },
        },
    },
    "@media (max-width: 480px)": {
        "&.popup": {
            ".MuiPaper-root": {
                maxWidth: "90% !important",
                maxHeight: "90% !important",
                minWidth: "auto",
                minHeight: "auto",
                width: "auto",
            },
        },
    },

    "&.popup": {
        ".MuiPaper-root": {
            maxWidth: "900px",
            maxHeight: "600px",
            width: "auto",
        },
    },
}));

export const ModalVideoStyles = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "90%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "fit-content",
        maxWidth: "fit-content",
        maxHeight: "fit-content",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        overflow: "hidden",
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "auto",
            minWidth: "auto",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
            },
        },
    },
    "@media (max-width: 480px)": {
        "& .MuiPaper-root": {
            width: "90%",
            minWidth: "auto",
        },
    },
}));

export const Popup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .container-img {
        position: relative;
        .close {
            position: absolute;
            right: -5px;
            width: 30px;
            top: -30px;
            cursor: pointer;
        }
    }
    .img-banner {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 80vh;
    }

    @media (max-width: 480px) {
        width: 100%;
        height: 80vh;

        .img-pop-up {
            height: 80vh;
        }
        .close {
            top: 0;
        }
    }
`;
