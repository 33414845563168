import { Button } from "../Button";
import { UsuarioNaoEncontradoStyles } from "./styles";
import imgUser from "../../../assets/img/usuarionaoencontrado.svg";
import { usePassos } from "../../../Hook/UsePassos";
import { arrPassos } from ".";

export function UsuarioNaoEncontrado() {
    const { setPassos } = usePassos();

    return (
        <UsuarioNaoEncontradoStyles>
            <img src={imgUser} alt="" />
            <h3>Usuário não encontrado.</h3>

            <Button
                type="submit"
                className="login"
                mt="auto"
                onClick={() => {
                    setPassos(arrPassos);
                }}
            >
                voltar
            </Button>
        </UsuarioNaoEncontradoStyles>
    );
}
