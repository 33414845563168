import styled from "styled-components";

export const Container = styled.div`
    padding: 76px 38px 51px 38px;

    .container-img {
        margin-top: -165px;
        margin-bottom: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
        color: ${({ theme }) => theme.colors.secondary};
        text-align: center;
    }

    p {
        text-align: center;
    }
`;
