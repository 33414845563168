import { Text, Box } from "alisson-application";
import { ModalStyles } from "./styles";
import { Button } from "../../../../Button";
import { useContex } from "../Hook/modal";

import imgXVerde from "../../../../../../assets/img/x.svg";
import imgCadastroCliente from "../../../../../../assets/img/failure-cadastro-carga-infra.svg";

interface SucessProps {
    setSucess: (sucess: boolean | null) => void;
}

export function Failure(props: SucessProps) {
    const { setSucess } = props;
    const { setModalRegulamento } = useContex();

    function handleClose() {
        setModalRegulamento(false);
        setSucess(null);
    }

    return (
        <ModalStyles>
            <div className="container-header">
                <Text as="p">NOVA CARGA DE INFRA</Text>
                <button onClick={() => handleClose()}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>
            <div className="container-sucess">
                <img src={imgCadastroCliente} alt="" />

                <Text as="h3">Algo deu errado na criação do regulamento</Text>

                <Text as="p">Motivo: falha de comunicação com servidor</Text>

                <Box width="234px">
                    <Button
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Entendi
                    </Button>
                </Box>
            </div>
        </ModalStyles>
    );
}
