import { AxiosResponse } from "axios";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Myform } from "../../components/System/NovoAdministrador/config";
import { api } from "../../services/";
import {
    formatDateIso,
    generateSalt,
    getFirsDayMonth,
    getLastDayMonth,
} from "../../util/helper";
import { Cartoes, Extrato } from "../user/UseCartaoes";
import { Extrato as ExtratoCarteira } from "../user/useExtrato";
import { Pessoa } from "../user/UseLogin";

import { HistoryProps, PaginationConfig } from "./config";
import { Campanha } from "./useCampanha";
import { useConfig } from "./useConfig";

interface UseUserProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseUserData {
    getUsersAdm: (page?: number) => Promise<UserData>;
    storeUserAdm(values: Myform): Promise<any>;
    updateUserAdm(values: Myform, id: number): Promise<any>;
    getRoles(page?: number): Promise<Roles[]>;
    storeRole(values: StoreRole): Promise<AxiosResponse<any>>;
    updateRole(values: StoreRole): Promise<AxiosResponse<any>>;
    alterarSenha(values: any, id: number): Promise<any>;
    updateCardsAcg(): Promise<any>;
    searchUser(search: string): Promise<void>;
    user: User;
    users: User[];
    loading: boolean;

    getExtratoCarteira: (id: number, data?: Date) => Promise<any>;
    extratosCarteira: ExtratoCarteira[];
    loadingExtratoCarteira: boolean;

    getExtrato: (id: number, data?: Date) => Promise<any>;
    extratos: Extrato[];
    loadingExtrato: boolean;
    bloqueio(id: number, status: number): Promise<any>;

    resetPasswordUserPlataform: (id: number, password: string) => Promise<any>;

    campanhaId: number | null;
    setCampanhaId: (value: number) => void;

    config: PaginationConfig;

    updateAdmUser(values: any): Promise<any>;
    updateAdmEndereco(id: number, values: any): Promise<any>;
}

export interface User {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string;
    status: number;
    credito_total: string;
    register_status: number;
    image: string | null;
    created_at: null | string;
    updated_at: null | string;
    registered_at: null | string;
    saldo: number;
    campanhas: Campanha[];
    enderecos: Endereco[];
    cartoes: Cartoes[];
    pessoa: Pessoa;
    roles?: Roles[];
}

export interface ValuesUpdateUser {
    name: string;
    document: string;
    phone_number: string;
    email: string;
    nome_da_mae: string;
    data_nascimento: string;
}

export interface ValuesUpdateEndereco {
    cep: string;
    estado: string;
    cidade: string;
    bairro: string;
    nome: string;
    numero: string;
    complemento: string;
}

export interface Endereco {
    id: number;
    user_id: number;
    acg_address_id: string;
    cep: string;
    nome: string;
    numero: string;
    bairro: string;
    complemento: string;
    cidade: string;
    estado: string;
    pais: string;
    created_at: null | string;
    updated_at: null | string;
}

export interface UserData extends PaginationConfig {
    data: User[];
}

export interface Roles {
    id: number;
    name: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
}

interface StoreRole {
    name: string;
    permissions: number[];
}

interface CampanhaHistory extends HistoryProps {
    campanhaId: number;
}

const UseClienteContext = createContext<UseUserData>({} as UseUserData);

export function UserProvider(props: UseUserProviderProps) {
    const { children } = props;

    const history = useHistory<CampanhaHistory>();
    const id = history.location?.state?.id;

    const [users, setUsers] = useState<User[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [user, setUser] = useState<User>({} as User);
    const { search, setSalt } = useConfig();
    const [campanhaId, setCampanhaId] = useState<number | null>(null);

    const [extratosCarteira, setExtratosCarteira] = useState<ExtratoCarteira[]>(
        []
    );
    const [loadingExtratoCarteira, setLoadingExtratoCarteira] = useState(false);

    const [extratos, setExtratos] = useState<Extrato[]>([]);
    const [loadingExtrato, setLoadingExtrato] = useState(false);

    const [loading, setLoading] = useState(false);

    /** Usuário Adm */

    async function getUsersAdm(page?: number) {
        const hasPage = page ? `&page=${page}` : "";
        try {
            const response = await api.get<UserData>(
                `/usuario?role[]=master&role[]=admin&role[]=gerente&role[]=produto&role[]=fale_conosco&role[]=file_uploader&role[]=commercial&role[]=communication${hasPage}`
            );

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            return {} as UserData;
        }
    }

    async function storeUserAdm(values: Myform) {
        try {
            const response = await api.post("usuario", {
                ...values,
            });
            toast.success("Usuário cadastrado com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            return error;
        }
    }

    async function updateUserAdm(values: Myform, id: number) {
        try {
            const response = await api.post("usuario", {
                ...values,
                phone_number: "55" + values.phone_number.replace(/\D/g, ""),
            });
            toast.success("Usuário cadastrado com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            return error;
        }
    }

    async function getRoles(page?: number) {
        // const hasPage = page ? `?page=${page}` : "";
        try {
            const response = await api.get<Roles[]>(`role`);

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            return [] as Roles[];
        }
    }

    async function storeRole(values: StoreRole) {
        try {
            const response = await api.post("/role", {
                ...values,
            });

            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Ops algo deu errado!");
            return error;
        }
    }

    async function updateRole(values: StoreRole) {
        const id = history.location.state?.id;
        try {
            const response = await api.put(`/role/${id}`, {
                ...values,
            });

            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Ops algo deu errado!");
            return error;
        }
    }

    async function updateAdmUser(values: any) {
        try {
            const response = await api.post(`/usuario/${id}`, {
                ...values,
            });

            toast.success("Atualizado com sucesso!");
            return response;
        } catch (error: any) {
            return error;
        }
    }
    async function updateAdmEndereco(id: number, values: any) {
        try {
            const response = await api.put(`/usuario/endereco/${id}`, {
                ...values,
            });

            toast.success("Atualizado com sucesso!");
            return response;
        } catch (error: any) {
            return error;
        }
    }

    /** Usuário da plataforma */

    async function searchUser(search: string) {
        // const hasPage = page ? `?page=${page}` : "";
        setLoading(true);
        try {
            const response = await api.post<UserData>(`usuario/search`, {
                search,
            });

            const { data, ...resto } = response.data;
            setConfig(resto);
            setUsers(data);
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            setUsers([]);
        }
        setLoading(false);
    }

    async function bloqueio(id: number, status: number) {
        try {
            const response = await api.post(`/usuario/${id}`, {
                status,
            });

            await searchUser(search);
            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Ops algo deu errado!");
            return error;
        }
    }

    async function resetPasswordUserPlataform(id: number, password: string) {
        try {
            const response = await api.post(`usuario/${id}`, {
                password: password,
                password_confirmation: password,
            });

            if (response.status === 200) {
                toast.success("Senha alterada com sucesso!");
            }
        } catch (error: any) {
            toast.error("Erro ao alterar senha");
            return error;
        }
    }

    async function alterarSenha(values: any, id: number) {
        try {
            await api.post("cartao/update-password", {
                password: values.password,
                confirm_password: values.confirm_password,
                cartao_id: id,
            });
            toast.success("Senha alterada com sucesso!");
        } catch (error: any) {
            toast.error("Erro ao alterar senha");
            console.log(error);
        }
    }

    async function updateCardsAcg() {
        try {
            setLoading(true);
            await api.get(`/usuario/refresh-cards/${id} `);
            await getUser();
            toast.success("Cartões atualizados com sucesso!");
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            toast.error("Erro ao atualizar cartões");
            console.log(error);
        }
    }

    const getExtrato = useCallback(async (id: number, data?: Date) => {
        setLoadingExtrato(true);
        const primeiroDiaDoMes = formatDateIso(getFirsDayMonth(data))
            .split(" - ")[0]
            .split(".")
            .reverse()
            .join("-");
        const ultimoDiaDoMes = formatDateIso(getLastDayMonth(data))
            .split(" - ")[0]
            .split(".")
            .reverse()
            .join("-");

        try {
            const { data } = await api.get<Extrato[]>(
                `/extrato/${id}?start=${primeiroDiaDoMes}&end=${ultimoDiaDoMes}`
            );
            setExtratos(
                data.sort(
                    (a, b) =>
                        new Date(b.eventDate).getTime() -
                        new Date(a.eventDate).getTime()
                )
            );
            setLoadingExtrato(false);
        } catch (error: any) {
            setLoadingExtrato(false);
            setExtratos([] as Extrato[]);
            return error;
        }
    }, []);

    const getExtratoCarteira = useCallback(
        async (id: number, data?: Date) => {
            setLoadingExtratoCarteira(true);
            const campanhaHistoryId = history.location?.state?.campanhaId;
            const hasCampanha =
                campanhaId || campanhaHistoryId
                    ? `&campanhas[0]=${
                          campanhaId ? campanhaId : campanhaHistoryId
                      }`
                    : "";
            const primeiroDiaDoMes = formatDateIso(getFirsDayMonth(data))
                .split(" - ")[0]
                .split(".")
                .reverse()
                .join("-");
            const ultimoDiaDoMes = formatDateIso(getLastDayMonth(data))
                .split(" - ")[0]
                .split(".")
                .reverse()
                .join("-");

            try {
                const { data } = await api.get<ExtratoCarteira[]>(
                    `/extrato/usuario/${id}?start=${primeiroDiaDoMes}&end=${ultimoDiaDoMes}${hasCampanha}`
                );
                setExtratosCarteira(data.reverse());
                setLoadingExtratoCarteira(false);
            } catch (error: any) {
                setLoadingExtratoCarteira(false);
                setExtratosCarteira([] as ExtratoCarteira[]);
                return error;
            }
        },
        [campanhaId, history.location?.state?.campanhaId]
    );

    const getUser = useCallback(async () => {
        setLoading(true);
        const salt = generateSalt();
        setSalt(salt);
        const campanhaHistoryId = history.location?.state?.campanhaId;
        const hasCampanha =
            campanhaId || campanhaHistoryId
                ? `&campanhas[0]=${campanhaId ? campanhaId : campanhaHistoryId}`
                : "";
        try {
            const response = await api.get<User>(
                `usuario/${id}?${hasCampanha}`
            );
            const update = response.data.cartoes.map((cartao) => {
                const hasLast4digits = cartao.acg_account_card_last4digits
                    ? `**** **** **** ${cartao.acg_account_card_last4digits}`
                    : "-";
                return {
                    ...cartao,
                    acg_account_card_last4digits: hasLast4digits,
                };
            });
            setUser({
                ...response.data,
                cartoes: update,
            });
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            setUser({} as User);
        }
        setLoading(false);
    }, [id, setSalt, campanhaId, history.location?.state?.campanhaId]);

    useEffect(() => {
        if (id) {
            getUser();
        }
    }, [getUser, id]);

    return (
        <UseClienteContext.Provider
            value={{
                searchUser,
                getUsersAdm,
                storeUserAdm,
                updateUserAdm,
                updateAdmUser,
                updateAdmEndereco,
                alterarSenha,
                updateCardsAcg,
                user,
                users,
                loading,
                extratos,
                loadingExtrato,
                getExtrato,

                getExtratoCarteira,
                extratosCarteira,
                loadingExtratoCarteira,

                getRoles,
                storeRole,
                updateRole,
                bloqueio,

                resetPasswordUserPlataform,

                campanhaId,
                setCampanhaId,

                config,
            }}
        >
            {children}
        </UseClienteContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UseClienteContext);

    return context;
}
