import Table, { Pagination } from "../../Table";
import { Loader } from "../../../Loader";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";

import { Data } from "../config";

import imgEditar from "../../../../assets/img/editar.svg";
import imgExcluir from "../../../../assets/img/x-vermelho.svg";
import { formatDateSystem, formataCPF } from "../../../../util/helper";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";

export function TableWhiteList() {
    const { loading, whiteLists, setId, setModaldialog, pagination, getData } =
        useWhiteList();
    const history = useHistory();

    const rows: Data[] = whiteLists?.map((list) => {
        return {
            id: list.id + "",
            approved_user: list?.approved_user?.name || "",
            reproved_user: list?.reproved_user?.name || "",
            cpf: list.document ? formataCPF(list.document) : "",
            nome: list.name,
            data_inclusao: formatDateSystem(list.created_at),
            incluido_por: list.user.name,
            ver_documento: "",
            excluir: "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "cpf",
            headerName: "CPF",
        },
        {
            field: "nome",
            headerName: "NOME",
        },
        {
            field: "data_inclusao",
            headerName: "DATA DE INCLUSÃO",
        },
        {
            field: "incluido_por",
            headerName: "DATA DE INCLUSÃO",
        },
        {
            field: "ver_documento",
            headerName: "VER DOCUMENTO",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            history.push({
                                pathname: `/sistema/white-list/editar/${row.id}`,
                                state: {
                                    id: row.id,
                                },
                            })
                        }
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },

        {
            field: "approved_user",
            headerName: "VALIDADO",
        },
        {
            field: "reproved_user",
            headerName: "REPROVADO",
        },
        {
            field: "excluir",
            headerName: "EXCLUIR",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            setId(row.id);
                            setModaldialog(true);
                        }}
                    >
                        <img src={imgExcluir} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    columns={collumns}
                    rows={rows ? rows : []}
                    nameCSV="ADICIONAR-CAMPANHA"
                />
            )}

            {pagination?.last_page > 1 ? (
                <Pagination
                    total={pagination?.last_page}
                    currentPage={async (page) => {
                        await getData(page);
                    }}
                />
            ) : null}
        </>
    );
}
