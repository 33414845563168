import styled from "styled-components";

export const Container = styled.div`
    padding: 0 5%;
    padding-top: 55px;
    padding-bottom: 75px;

    .img-close {
        background: #bcdfba;
        width: 76px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: -37px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        z-index: 99;
    }
    .container-title {
        text-align: center;
        margin-bottom: 38px;
        h1 {
            font-size: 28px;
            font-weight: 700;
            color: ${(props) => props.theme.colors.secondary};
            margin-bottom: -8px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    .container-detalhes {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 28px;
        p {
            font-size: 16px;
            font-weight: 700;
            color: ${(props) => props.theme.colors.secondary};
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primaryDark};
        }

        small {
            font-size: 14px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }
`;
