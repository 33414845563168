import styled from "styled-components";

export const Container = styled.div`
    .container-itens {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 12px 14px;
        .conatiner-header {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            padding-bottom: 13px;
            border-bottom: 1px solid #57b847;
            grid-column: span 2;
            h3 {
                color: ${(props) => props.theme.colors.secondary};
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 16px */
                letter-spacing: 1.28px;
                text-transform: uppercase;
            }

            p {
                color: ${(props) => props.theme.colors.primaryDark};
                font-family: Oxanium;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 16px */
                letter-spacing: 1.28px;
                margin-left: 7px;
            }
        }
        .container-item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
            grid-gap: 17px 0px;
            border-radius: 8px;
            background: #e9e9e9;
            padding: 37px 22px 31px 33px;
            h5 {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 12px */
                letter-spacing: 0.96px;
                text-transform: uppercase;
            }

            p {
                color: #767b7f;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 12px */
                letter-spacing: 0.96px;
            }
        }
    }
`;
