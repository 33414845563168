import { Text, Flex } from "alisson-application";
import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { Button } from "../Button";
import { Card } from "./card";
import { formataCPF, phoneNumber, formatNameCard } from "../../../util/helper";
import imgUser from "../../../assets/img/user.svg";
// import imgCalendario from "../../../assets/img/calendario.svg";
import imgCelular from "../../../assets/img/celular.svg";
import { ConfereDados } from "./styles";

export function Conferencia() {
    const { nextPasso } = usePassos();
    const { user } = useUser();
    const { t } = useTranslation();
    const dados = {
        price: "R$ 1.000,00",
        number: "**** **** **** ****",
        type: `${t("Digital")}`,
        color: "#16343B;",
    };

    return (
        <ConfereDados className="container">
            <Text as="h3" mb="19px">
                {t("Agora é só dar uma conferida nos")} <br />{" "}
                {t("dados informados e confirmar o pedido")}
            </Text>

            <div className="container-dados">
                <Card {...dados} />

                <div className="container-text-detalhe">
                    <Text as="h4" textTransform="uppercase">
                        {formatNameCard(user?.pessoa?.nome_cpf)}
                    </Text>
                    <Text as="h5">**** **** **** ****</Text>
                </div>
            </div>

            <ul>
                <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgUser} alt="" />
                        </div>
                        <Text color="primaryDark" fontSize="xss">
                            CPF
                        </Text>
                    </Flex>

                    <Text color="primaryDark" fontSize="xss">
                        {formataCPF(user?.document)}
                    </Text>
                </li>
                <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgCelular} alt="" />
                        </div>
                        <Text
                            textTransform="uppercase"
                            color="primaryDark"
                            fontSize="xss"
                        >
                            {t("celular")}
                        </Text>
                    </Flex>

                    <Text color="primaryDark" fontSize="xss">
                        {phoneNumber(user?.phone_number)}
                    </Text>
                </li>
                {/* <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgCalendario} alt="" />
                        </div>
                        <Text
                            textTransform="uppercase"
                            color="primaryDark"
                            fontSize="xss"
                        >
                            {t("data de nasc")}.
                        </Text>
                    </Flex>

                    <Text color="primaryDark" fontSize="xss">
                        {user?.pessoa?.data_nascimento.replace(
                            /^([0-9]{4})-([0-9]{2})-([0-9]{2})/g,
                            "$3/$2/$1"
                        )}
                    </Text>
                </li> */}
                {/* <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgUser} alt="" />
                        </div>
                        <Text
                            textTransform="uppercase"
                            color="primaryDark"
                            fontSize="xss"
                        >
                            {t("nome da mãe")}
                        </Text>
                    </Flex>

                    <Text
                        textTransform="uppercase"
                        color="primaryDark"
                        fontSize="xss"
                    >
                        {getLocalStore()?.nome_da_mae}
                    </Text>
                </li> */}
            </ul>

            <Button onClick={() => nextPasso(4)}>{t("avançar")}</Button>
        </ConfereDados>
    );
}
