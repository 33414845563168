import { Container } from "./styles";
import { formatCurrentMoney, formatDateIso } from "../../util/helper";
import imgPolygon from "../../assets/img/polygon 1.svg";
import { ExtratoBoleto } from "../../Hook/user/UseCartaoes";

export function CardComprovante(props: ExtratoBoleto) {
    const { created_at, customerDescription, file, value } = props;
    const dateTime = formatDateIso(created_at).split(" - ");
    const date = dateTime[0];
    const time = dateTime[1];

    function handleDownload() {
        window.open(file, "_blank");
    }

    return (
        <Container>
            <div className="container-data">
                <h3>{date}</h3>
            </div>

            <div className="container-pagamento">
                <div className="container-img">
                    <img src={imgPolygon} alt="polygon" />
                </div>

                <div className="container-title">
                    <h4>pagamento de contas</h4>
                    <h5>{customerDescription}</h5>
                    <h6>{time}</h6>
                </div>

                <div className="container-valor">
                    <h4>R$ {formatCurrentMoney(parseFloat(value))}</h4>
                    <button onClick={handleDownload}>Ver Detalhes</button>
                </div>
            </div>
        </Container>
    );
}
