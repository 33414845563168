import styled from "styled-components";

export const Container = styled.div`
    .container {
        p {
            text-align: center;
            width: 260px;
            margin: 0 auto;
            margin-top: 19px;
            margin-bottom: 26px;
        }
    }
`;

export const SegundaViaStyles = styled.div`
    width: 90%;
    margin: 0 auto;
    padding-bottom: 60px;
    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 38px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryDark};
    }

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 62px;
        padding-top: 2px;
        white-space: nowrap;
    }
`;

export const FormStyles = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-bottom: 23px;
    button {
        &.open-modal-cvv {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
            width: 100%;
            margin-bottom: 68px;
        }
    }
`;
