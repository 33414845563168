import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { usePassos } from "../../Hook/UsePassos";
import { CodigoDeBarras } from "../CodigoDeBarras";
import { HeaderPassos } from "../Mobile/HeaderPassos";
import { DadosBoleto } from "../DadosBoleto";
import { ConfirmacaoPagamento } from "../ConfirmacaoPagamento";
import { PagamentoConta } from "../PagamentoConta";
import { PagamentoRealizado } from "../PagamentoRealizado";
import { ComprovantePagamento } from "../ComprovantePagamento";
import { Container } from "./styles";
import { UseModal } from "../../Hook/Usemodal";
import { arrPassosBoletoPagamento } from "./config";
import { foraDoHorario, getCampanha } from "../../util/helper";
import { useUser } from "../../Hook/user/UseUser";

export function PagamentoBoleto() {
    const width = window.screen.width;
    const permission = ["/pagamento-boleto"];
    const history = useHistory<{ valid: boolean }>();
    const hasPath = permission.includes(history.location.pathname);
    const { user } = useUser();
    const hasActivePayment = !user?.all_campaigns_bill_payment_available
        ? user?.all_campaigns_bill_payment_available
        : getCampanha().allow_bill_payment_available;
    const ref = useRef<HTMLDivElement>(null);
    const { passos, setPassos, setDados } = usePassos();
    const { state, setState } = UseModal();

    useEffect(() => {
        if (hasPath || state.boleto) {
            setDados({});
            setPassos(arrPassosBoletoPagamento);
        }
    }, [hasPath, setDados, setPassos, state.boleto, history]);

    if (foraDoHorario() || !hasActivePayment) {
        history.push("/dashboard");
        return <></>;
    }

    return (
        <Container ref={ref}>
            <HeaderPassos
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, boleto: false });
                        setPassos([]);
                    }
                }}
                title={"pagamento de contas"}
            />
            <div className="container">
                <div className="container-tabs">
                    <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                        {passos[0]?.active && <CodigoDeBarras />}
                    </div>
                    <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                        {passos[1]?.active && <DadosBoleto />}
                    </div>
                    <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                        {passos[2]?.active && (
                            <ConfirmacaoPagamento toTop={ref} />
                        )}
                    </div>
                    <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                        {passos[3]?.active && <PagamentoConta />}
                    </div>
                    <div className={`tab ${passos[4]?.active ? "active" : ""}`}>
                        {passos[4]?.active && <PagamentoRealizado />}
                    </div>
                    <div className={`tab ${passos[5]?.active ? "active" : ""}`}>
                        {passos[5]?.active && <ComprovantePagamento />}
                    </div>
                </div>
            </div>
        </Container>
    );
}

/**
 * https://forms.gle/32F1jWJshkkWwhaE9
 */
