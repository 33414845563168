import { SucessoStyles } from "./styles";
import imgSuccess from "../../assets/img/sucesso.svg";
import { UseModal } from "../../Hook/Usemodal";
import { Button } from "../Mobile/Button";

export function Sucesso() {
    const { handleClose } = UseModal();
    return (
        <SucessoStyles>
            <img src={imgSuccess} alt="" />
            <h1>
                Senha Alterada
                <br/>
                com Sucesso!
            </h1>
            <Button onClick={() => handleClose("alterarSenha")}>
                OK
            </Button>
        </SucessoStyles>
    );
}
