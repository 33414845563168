import { useEffect, useState } from "react";
import { EmptyStyles } from "./styles";
import { Button } from "../Button";
import { UseModal } from "../../../Hook/Usemodal";
import { podesolicitarCartao, getCampanha } from "../../../util/helper";
import { options } from "../../NovoSelect";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
export function Empty() {
    const { state, setState, handleOpen } = UseModal();
    const [options, setOptions] = useState<options[]>([]);
    const { cartoes } = UseCartoes();
    function handleClick() {
        console.log(options);
        if (!options.length) {
            setState({
                ...state,
                successGeral: { ...state?.successGeral },
                errorGeral: {
                    active: true,
                    message: cartoes.length
                        ? "VOCÊ NÃO PODE MAIS SOLICITAR CARTÕES"
                        : "VOCÊ NÃO PODE MAIS SOLICITAR CARTÕES.",
                    title: "Erro",
                },
            });
        } else {
            handleOpen("diferencaCartoes");
        }
    }

    useEffect(() => {
        if (getCampanha().id) {
            setOptions(podesolicitarCartao([getCampanha()], cartoes));
        }
    }, [cartoes]);

    return (
        <EmptyStyles>
            <div className="info">
                <p>
                    VOCÊ NÃO POSSUI CARTÕES NO MOMENTO. <br />
                    CLIQUE NO BOTÃO ABAIXO PARA SOLICITAR.
                </p>

                <Button onClick={handleClick}>SOLICITAR CARTÃO</Button>
            </div>
        </EmptyStyles>
    );
}
