import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useSepro } from "../../../Hook/system/useSepro";
import { Container } from "./styles";
import Table from "../Table";
import { Header } from "../Header";
import { Data } from "./config";
import imgSepro from "../../../assets/img/sepro.svg";
import { formatCurrentMoney } from "../../../util/helper";
import { Loader } from "../../Loader";
import { Export } from "./export";

export function Sepro() {
    const { sepro, loading } = useSepro();
    const width = 1645 / 6;
    const dados: Data[] = sepro.map((sepro, key) => {
        console.log(sepro)

        return {
            id: key,
            mes: `${sepro.mes} ${sepro.year}`,
            consulta_cpf: sepro.cpf,
            consulta_cnpj: sepro.cnpj,
            total: sepro.total,
            valor_estimado: `R$ ${formatCurrentMoney(
                parseFloat(sepro.valor_estimado)
            )}`,
            month: sepro.month,
            year: sepro.year
        };
    });

    const columns: GridColumns = [
        {
            field: "mes",
            headerName: "MÊS",
            width,
        },
        {
            field: "consulta_cpf",
            headerName: "CONSULTA CPF",
            width,
        },
        {
            field: "consulta_cnpj",
            headerName: "CONSULTA CNPJ",
            width,
        },
        {
            field: "total",
            headerName: "TOTAL",
            width,
        },
        {
            field: "valor_estimado",
            headerName: "VALOR ESTIMADO",
            width,
        },
        {
            field: "export",
            headerName: "EXPORTAR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (<Export ano={row.year} mes={row.month} />);
            },
            width,
        },
    ];

    return (
        <Container>
            <Header
                title="CONTADOR SERPRO"
                permission="faq.index"
                image={imgSepro}
            />

            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    rows={dados ? dados : []}
                    columns={columns}
                    nameCSV="administradores"
                />
            )}
        </Container>
    );
}
