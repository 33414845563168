import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 80px;
    /* min-height: 559px; */
    /* min-height: 657px; */
    .container-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 99px;
        border-bottom: 1px solid #e1e2e3;
        margin: 0 5%;
    }
    /* .container-tabs {
        form {
            input {
                text-transform: unset;
            }
        }
    } */

    .container-input {
        &::after {
            display: none;
        }
    }

    .container-tabs {
        width: 90%;
        margin: 0 auto;
    }
`;

export const FormStyles = styled.div`
    padding-bottom: 32px;
    p {
        text-align: center;
        margin-bottom: 26px;
    }

    form {
        height: 231px;
        display: flex;
        flex-direction: column;
        ul {
            margin-bottom: 0px;
        }
        .mt-auto {
            margin-top: 16px;
            button {
                margin-top: 0px;
            }
        }
    }
`;

export const SucessoStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 11px;
        
    img {
        margin:16px 0 22px;        
    }

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.secondarydark};
        margin-bottom: 10px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 45px;
    }

    button{
        max-width: 273px;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
    }
`;
