import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 50px 9px 35px;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondarydark};
        margin-bottom: 15px;
        margin-top:23px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: #5E5F5F;
        width: 100%;
        margin-bottom: 40px;
        padding: 0 50px;
    }

    button {
        height:40px;
        text-transform: none;
        font-weight: 700;
        margin-top:7px;
        letter-spacing:0;
        &.goBackBtn{
            font-weight: 400;
            color:#5E5F5F;
            margin-top:0;
        }
    }
`;
