import { useState } from "react";
import { Button } from "../Mobile/Button";

import { Container } from "./styles";
import { usePassos } from "../../Hook/UsePassos";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { usePagamentoBoleto } from "../../Hook/user/usePagamentoBoleto";
import { PasswordCartao } from "../PasswordCartao";

export function PagamentoConta() {
    const [value, setValue] = useState("");
    const [errorCode, setErrorCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { cartao } = UseCartoes();
    const { pagamentoBoleto } = usePagamentoBoleto();
    const { nextPasso, dados, setDados } = usePassos();

    async function handleClick() {
        setLoading(true);

        const response = await pagamentoBoleto({
            cartao_id: cartao?.id as number,
            digitable: dados.boleto.digitable,
            customerDescriptionsh: dados.boleto.customerDescription,
            password: value,
        });

        if (response?.status === 200) {
            const blob = response.data;
            const headers = response.headers["content-type"];
            const type = headers.split("/")[1];
            const reader = new FileReader();
            reader.onload = function () {
                const buffer = reader.result;
                const blob = new Blob([buffer as any], { type: headers });
                const file = new File([blob], `comprovante.${type}`, {
                    type: headers,
                });

                nextPasso(5);
                setLoading(false);
                setDados({
                    ...dados,
                    boleto: { ...dados.boleto, file: file },
                });
            };
            reader.readAsArrayBuffer(blob);
        } else {
            setLoading(false);
        }
    }

    return (
        <Container>
            <PasswordCartao
                errorCode={errorCode}
                setErrorCode={setErrorCode}
                getCode={(values) => setValue(values)}
            />

            <Button
                mt="61px"
                onClick={handleClick}
                isLoading={loading}
                disabled={value.length !== 4}
            >
                AVANÇAR
            </Button>
        </Container>
    );
}
