import styled from "styled-components";
import { Button } from "../../Desktop/Button";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.tertiary};
    overflow: hidden;
`;

export const EmptyStyles = styled.div`
    border-radius: 24px 24px 0px 0px;
    height: calc(100vh - 100px - 96px - 106px - 99px);
    box-shadow: 0px 0px 75px rgba(0, 0, 0, 0.05);
    background-color: ${(props) => props.theme.colors.white};
    padding: 30px 5% 41px;
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 26px;
    }

    .content-itens {
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin: 6px 0 20px;
        }

        ${Button} {
            width: auto;
            height: 37px;
            margin: 0 auto;
        }
    }
`;
