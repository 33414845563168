import imgBandeira from "../../../assets/img/bandeira.svg";
import imgChip from "../../../assets/img/chip-card.svg";
import LogoCartao from "../../../assets/img/logo-cartao.svg";
import { CardStyles } from "./styles";

interface CardProps {
    color: string;
}

export function Card(props: CardProps) {
    const { color } = props;
    return (
        <CardStyles background={color}>
            <div className="container-bandeira-chip">
                <img src={imgBandeira} alt="" />
                <img src={imgChip} alt="" />
            </div>

            <div className="container-logo">
                <img src={LogoCartao} alt="" />
            </div>
        </CardStyles>
    );
}
