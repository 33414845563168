import { Box, Flex, Text } from "alisson-application";
import { useTranslation } from "../../../contexts/Localization";
import {
    foraDoHorario,
    formatCurrentMoney,
    getCampanha,
    sleep,
    tempoFimPlataforma,
    tempoInicioPlataforma,
} from "../../../util/helper";
import { useUser } from "../../../Hook/user/UseUser";
import { UseModal } from "../../../Hook/Usemodal";
import { Container, LinkStyled } from "./styles";
import imgUser from "../../../assets/img/user.png";
import imgCupom from "../../../assets/img/cupom.svg";
import imgExtrato from "../../../assets/img/extrato.svg";
import imgCartao from "../../../assets/img/cartao-ativar-branco.svg";
import imgTransferirCarteira from "../../../assets/img/transferir-carteira-mobile.svg";
import IconOlho from "../../../assets/img/olho.svg";
import { Button } from "../Button";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useCallback, useState } from "react";

interface SubHeaderProps {}

export function SubHeader(props: SubHeaderProps) {
    const { user } = useUser();
    const { t } = useTranslation();
    const { state, setState, handleOpen } = UseModal();
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);
    const { cartao } = UseCartoes();
    const tranferenciaEntreUsuarios =
        getCampanha().allow_transference_between_users;

    const apenasVirtual = getCampanha()?.produtos?.every((item) => {
        return item.produto.tipo === "cartao_virtual";
    });

    const money = user.saldo
        ? formatCurrentMoney(parseFloat(user.saldo))
        : "0,00";

    const fetchData = useCallback(async () => {
        if (active) {
            setShow(false);
            setActive(false);
            return;
        }
        setShow(false);
        setActive(true);
        await sleep(3000);
        setShow(true);
    }, [active]);

    return (
        <Container>
            <div className="container">
                <div className="container-user">
                    <Flex alignItems="center">
                        <Box>
                            <img
                                src={user?.image ? user?.image : imgUser}
                                alt=""
                            />
                        </Box>
                        <Box ml="15px" className="container-text">
                            <Text as="p" textTransform="uppercase">
                                {t("Olá")},
                            </Text>
                            <Text fontWeight={700} textTransform="uppercase">
                                {user?.pessoa?.apelido
                                    ? user?.pessoa?.apelido
                                    : ""}
                            </Text>
                        </Box>
                    </Flex>
                </div>

                <div className="container-btns">
                    {!apenasVirtual ? (
                        <LinkStyled
                            onClick={() =>
                                setState({
                                    ...state,
                                    ativarCartaoAvulsoSubHeader: true,
                                })
                            }
                            className="branco"
                        >
                            <img src={imgCartao} alt="" />
                            <Text as="p">
                                ATIVAR <br />
                                CARTÃO FÍSICO
                            </Text>
                        </LinkStyled>
                    ) : null}

                    {getCampanha()?.allow_cupom ? (
                        <LinkStyled
                            onClick={() => setState({ ...state, cupom: true })}
                        >
                            <img src={imgCupom} alt="" />
                            <Text as="p">
                                ADICIONAR <br /> CUPOM
                            </Text>
                        </LinkStyled>
                    ) : (
                        <></>
                    )}

                    <LinkStyled
                        onClick={() => setState({ ...state, extrato: true })}
                    >
                        <img src={imgExtrato} alt="" />
                        <Text as="p">
                            EXTRATO <br /> CARTEIRA
                        </Text>
                    </LinkStyled>

                    {tranferenciaEntreUsuarios ? (
                        <LinkStyled
                            onClick={() => {
                                handleOpen("transferirEntreCarteiras");
                            }}
                        >
                            <img src={imgTransferirCarteira} alt="" />
                            <Text as="p">
                                TRANSFERIR <br />
                                ENTRE CARTEIRAS
                            </Text>
                        </LinkStyled>
                    ) : null}
                </div>

                <div className={`container-saldo ${active ? "active" : ""}`}>
                    <Text textTransform="uppercase" as="span" mb="8px">
                        {t("SALDO DA CARTEIRA")}
                    </Text>

                    <Flex
                        alignItems="start"
                        justifyContent="flex-start"
                        pl="36px"
                        position="relative"
                    >
                        <Text mr="0px" fontWeight="600" mt="2px">
                            R$
                        </Text>
                        <Text
                            fontWeight="600"
                            fontSize="mdl"
                            ml="-4px"
                            className={`dinheiro ${show ? "show" : ""}`}
                            onClick={fetchData}
                        >
                            {money}
                        </Text>

                        <button
                            className={`container-olho ${active ? "hide" : ""}`}
                            onClick={fetchData}
                            disabled={active && !show}
                        >
                            <img src={IconOlho} alt="" />
                        </button>
                    </Flex>

                    <Button
                        mb="16px"
                        mt="16px"
                        disabled={cartao?.status === "CANCELADO"}
                        textTransform="uppercase"
                        onClick={() => {
                            if (foraDoHorario()) {
                                handleOpen("horarioFuncionamento", {
                                    text: (
                                        <Text as="p" textAlign="center" bold>
                                            Transferência da Carteira para o{" "}
                                            <br />
                                            Cartão disponível das{" "}
                                            {tempoInicioPlataforma}h às{" "}
                                            {tempoFimPlataforma}h <br />
                                            em dias úteis.
                                        </Text>
                                    ),
                                });
                                return;
                            }
                            if (
                                cartao?.status === "BLOQUEADO" ||
                                cartao?.first_active === 0
                            ) {
                                setState({
                                    ...state,
                                    errorGeral: {
                                        message:
                                            "Você só pode inserir crédito após ativar o cartão.",
                                        active: true,
                                        title: "Erro",
                                    },
                                });
                            } else {
                                setState({
                                    ...state,
                                    solicitarResgate: true,
                                });
                            }
                        }}
                    >
                        <strong>TRANSFERIR SALDO</strong>

                        <br />
                        <strong>PARA CARTÃO YETZPAY</strong>
                    </Button>
                </div>
            </div>
        </Container>
    );
}
