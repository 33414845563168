import styled from "styled-components";

export const FirstUpdateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 30px;

    figure {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 68px;
        height: 68px;
        border-radius: 50%;
        background-color: #dff3de;

        img {
            width: 29px;
            height: 29px;
        }
    }

    h1 {
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        color: #57b847;

        margin: 20px 0 25px;
    }

    p {
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        max-width: 290px;

        & + p {
            margin: 15px 0 30px;
        }
    }
`;

export const CustomBtn = styled.button`
    width: 100%;
    height: 40px;
    max-width: 327px;
    text-align: center;

    font-family: Oxanium;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    color: #fff;
    background: #57b847;
    cursor: pointer;
    border-radius: 100px;

    transition: all 0.4s;

    &:hover {
        transform: scale(1.1);
    }
`;
