import { Text, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Button } from "../Mobile/Button";
import imgPerdaRoubo from "../../assets/img/cardLossTheft.svg";
import { UseModal } from "../../Hook/Usemodal";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { Container } from "./styles";

export function PerdaRoubo() {
    const { state, setState, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const history = useHistory();
    const width = window.screen.width;

    return (
        <Container>
            <img src={imgPerdaRoubo} alt="" /> 
            <Text as="h3">Perda ou Roubo</Text>
            <Text as="p">
                Escolha a opção com a qual deseja continuar:
            </Text>

            <Box width="90%" m="0 auto">
                {cartao?.status === "ATIVO" ? (
                    <Button
                        onClick={() => {
                            setState({
                                ...state,
                                perdaRoubo: false,
                                acaoBloquearCartao: true,
                            });
                        }}
                    >
                        Bloqueio Temporário
                    </Button>
                ) : null}

                <Button
                    onClick={() => {
                        if (width <= 480) {
                            history.push("/cancelar-cartao");
                            handleClose("perdaRoubo");
                        } else {
                            setState({
                                ...state,
                                perdaRoubo: false,
                                segundaVia: true,
                            });
                        }
                    }}
                >
                    Cancelar Cartão
                </Button>
            </Box>
            <button className="goBackBtn" onClick={() => handleClose("perdaRoubo")}>
                Voltar ao Início
            </button>
        </Container>
    );
}
