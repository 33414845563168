import { Button } from "../Button";
import { Container } from "./styles";
import ImgAlert from "../../../assets/img/alerta-verde.svg";
import { UseModal } from "../../../Hook/Usemodal";

export function SaldoInsuficiente() {
    const { handleClose } = UseModal();
    return (
        <Container>
            <div className="container-img">
                <img src={ImgAlert} alt="" />
            </div>

            <h1>SALDO INSUFICIENTE</h1>

            <p>
                No momento você não possui saldo de R$30,00 (Emissão+Frete) em
                sua Carteira para concluir a solicitação do Cartão Físico
                Personalizado.
            </p>

            <br />

            <p>Aguarde uma nova recarga para solicitar novamente o cartão.</p>

            <br />

            <Button onClick={() => handleClose("saldoInsuficiente")}>
                OK, ENTENDI
            </Button>
        </Container>
    );
}
