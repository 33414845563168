import { HeaderPassos } from "../../Mobile/HeaderPassos";
import { FormFaleConosco } from "./form";
import { Container } from "./styles";
import imgLogo from "../../../assets/img/logo-menu.svg";
import { useEffect } from "react";
import { UseModal } from "../../../Hook/Usemodal";
import { usePassos } from "../../../Hook/UsePassos";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
];

export function FaleConosco() {
    const { state, handleClose } = UseModal();
    const { setPassos, passos } = usePassos();

    useEffect(() => {
        if (!state?.faleConoscoUsuarioDeslogado) {
            setPassos(arrPassos);
        }
    }, [setPassos, state?.faleConoscoUsuarioDeslogado]);

    return (
        <Container>
            <div className="container-menu">
                <img src={imgLogo} alt="" />
            </div>
            <HeaderPassos
                handleClose={() => handleClose("faleConoscoUsuarioDeslogado")}
                title="PRECISO DE AJUDA"
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    <FormFaleConosco />
                </div>
            </div>
        </Container>
    );
}
