import { useState } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { IAlterarSenha, altearSenha, validationAlterarSenha } from "./config";

import { Button } from "../Mobile/Button";
import imgOlho from "../../assets/img/olho.svg";
import { Box } from "alisson-application";
import { usePassos } from "../../Hook/UsePassos";
import { CheckPassword } from "../CheckPassword";

export function FormAlterarSenha() {
    const [olhoSenha, setOlhoSenha] = useState(false);

    const { setDados, nextPasso } = usePassos();

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={altearSenha}
            validationSchema={validationAlterarSenha}
            onSubmit={async (values, actions) => {
                setDados(values);
                nextPasso(2);
            }}
        >
            {(props: FormikProps<IAlterarSenha>) => {
                const { values } = props;

                return (
                    <Form>
                        <div
                            className={`container-input chave-cartao olho ${
                                props.errors.current_password &&
                                props.touched.current_password
                                    ? "error"
                                    : ""
                            }`}
                        >
                            <Field
                                name="current_password"
                                type={`${olhoSenha ? "text" : "password"}`}
                                value={values.current_password}
                                placeholder="SENHA ATUAL"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </div>

                        <ErrorMessage
                            name="current_password"
                            component="span"
                        />

                        <div
                            className={`container-input chave-cartao olho ${
                                props.errors.password && props.touched.password
                                    ? "error"
                                    : ""
                            }`}
                        >
                            <Field
                                name="password"
                                type={`${olhoSenha ? "text" : "password"}`}
                                value={values.password}
                                placeholder="CRIAR SENHA DE ACESSO"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </div>

                        <ErrorMessage name="password" component="span" />

                        <div
                            className={`container-input chave-cartao olho ${
                                props.errors.password_confirmation &&
                                props.touched.password_confirmation
                                    ? "error"
                                    : ""
                            }`}
                        >
                            <Field
                                name="password_confirmation"
                                type={`${olhoSenha ? "text" : "password"}`}
                                value={values.password_confirmation}
                                placeholder="CONFIRMAR SENHA"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </div>
                        <ErrorMessage
                            name="password_confirmation"
                            component="span"
                        />

                        <Box mt="32px">
                            <CheckPassword values={values.password} />
                        </Box>

                        <div className="mt-auto">
                            <Button
                                mt="70px"
                                type="submit"
                                spinerColor="primary"
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                            >
                                ALTERAR SENHA
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
