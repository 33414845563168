import { Text, Flex, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import {
    foraDoHorario,
    formatCurrentMoney,
    tempoFimPlataforma,
    tempoInicioPlataforma,
} from "../../../util/helper";
import { useUser } from "../../../Hook/user/UseUser";
import { useTranslation } from "../../../contexts/Localization";
import { Button } from "../Button";
import { HistoryType } from "./type";
import { HeaderStyles } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";

interface HeaderProps {
    indexSwiper: number;
}

export function Header(props: HeaderProps) {
    const { indexSwiper } = props;
    const { t } = useTranslation();
    const { user } = useUser();
    const { handleOpen } = UseModal();
    const history = useHistory<HistoryType>();

    return (
        <HeaderStyles className="container">
            <Flex
                className="saldo-detalhe-cartao"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <Text textTransform="uppercase" as="p">
                        {t("SALDO DA CARTEIRA")}
                    </Text>

                    <Text as="h3" fontWeight="bold">
                        <span>R$</span>
                        {formatCurrentMoney(
                            user?.saldo ? parseFloat(user?.saldo) : 0
                        )}
                    </Text>
                </Box>

                <Button
                    onClick={() => {
                        if (foraDoHorario()) {
                            handleOpen("horarioFuncionamento", {
                                text: (
                                    <Text as="p" textAlign="center" bold>
                                        Transferência da Carteira para o <br />
                                        Cartão disponível das{" "}
                                        {tempoInicioPlataforma}h às{" "}
                                        {tempoFimPlataforma}h <br />
                                        em dias úteis.
                                    </Text>
                                ),
                            });
                            return;
                        }
                        history.push({
                            pathname: "/solicitar-resgate",
                            state: {
                                id: indexSwiper,
                            },
                        });
                    }}
                >
                    TRANSFERIR SALDO <br />
                    PARA CARTÃO YETZPAY
                </Button>
            </Flex>
        </HeaderStyles>
    );
}
