import { ChangeEvent, useState } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Validation, values, validation } from "./config";
import { StylesForm } from "./styles";
import imgOlho from "../../../assets/img/olho.svg";
import { maskDateRegex } from "../../../util/helper";
import { Button } from "../../Desktop/Button";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { usePassos } from "../../../Hook/UsePassos";
import { CheckPassword } from "../../CheckPassword";

export function FormAlterarSenha() {
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const { firstLogin } = UseLogin();
    const { nextPasso } = usePassos();

    return (
        <StylesForm>
            <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={validation}
                validateOnMount
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    values = {
                        ...values,
                        data_nascimento: values.data_nascimento
                            .split("/")
                            .reverse()
                            .join("-"),
                    };
                    const response = await firstLogin(values);

                    if (response.status === 200) {
                        nextPasso(3);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<Validation>) => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <div className={`container-input olho senha `}>
                                <Field
                                    name="current_password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    placeholder="SENHA ANTIGA"
                                    autoComplete="current-password"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="current_password"
                                component="span"
                            />
                            <div className={`container-input olho senha `}>
                                <Field
                                    name="new_password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    placeholder="NOVA SENHA"
                                    autoComplete="current-password"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="new_password"
                                component="span"
                            />
                            <div className={`container-input olho senha `}>
                                <Field
                                    name="new_password_confirmation"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    placeholder="CONFIRMAR NOVA SENHA"
                                    autoComplete="current-password"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="new_password_confirmation"
                                component="span"
                            />

                            <div className={`container-input  calendario`}>
                                <Field
                                    name="data_nascimento"
                                    placeholder="DATA DE NASCIMENTO"
                                    type="tel"
                                    value={values.data_nascimento}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (e.target.value.length <= 10) {
                                            setFieldValue(
                                                "data_nascimento",
                                                maskDateRegex(e.target.value)
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                name="data_nascimento"
                                component="span"
                            />

                            <CheckPassword values={values.new_password} />

                            <Button
                                type="submit"
                                disabled={!props.isValid}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                ALTERAR SENHA
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </StylesForm>
    );
}
