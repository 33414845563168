import styled from "styled-components";

export const ThirdStrikeModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    height:472px;
    position: relative;
    justify-content: flex-start !important;
    padding: 50px 27px 35px;

    h1 {
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #EB001B;
        text-transform: uppercase;
        white-space: break-spaces;
        margin-top: 20px;
    }

    p,
    strong {
        font-size: 14px;
        font-family: Oxanium;
        line-height: 19.6px;
        text-align: center;
        color: #000000;
        max-width: 300px;
    }

    strong {
        margin: 20px 0 0;
        font-weight: 700;
    }

    p {
        margin: 10px 0 30px;
        font-weight: 400;
        max-width: 240px;
        margin: 20px 0 20px;
    }

    button {
        width: 100%;
        max-width: 273px;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        text-align: center;

        &.primary {
            color: #fff;
            font-weight: 700;
            background-color: #57b847;
            border-radius: 100px;
            text-transform: uppercase;
            margin: 0 0 20px;
            height: 40px !important;
        }
    }
`;
