import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Text } from "alisson-application";

import {
    formatCurrentMoney,
    formatDateIsoSystem,
} from "../../../../../../util/helper";

import { useEstorno, process } from "../Hook";

import { Button } from "../../../../Button/status";
import ModalAprove from "../../../../ModalAlert";
import ModalDisAprove from "../../../../ModalAlert";

import imgVisualizar from "../../../../../../assets/img/visualizar.svg";

import { HistoryProps } from "../../../types";

import { ReeviewStyles } from "./styles";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";

export function Reeview() {
    const { inProcess, aprove, disApproval } = useEstorno();
    const { campanhaById } = useCampanha();
    const [processes, setProcesses] = useState<process[]>([]);
    const history = useHistory<HistoryProps>();

    const [modalAprove, setModalAprove] = useState(false);
    const [modalDisAprove, setModalDisAprove] = useState(false);
    const [id, setId] = useState<number | undefined>(undefined);

    const fetchData = useCallback(async () => {
        const reponse = await inProcess();
        setProcesses(reponse);
    }, [inProcess]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    async function handleAprove() {
        if (id) {
            const response = await aprove(id);
            if (response.status === 200) {
                fetchData();
            }
        }
    }

    async function handleDisAprove() {
        if (id) {
            const response = await disApproval(id);
            if (response.status === 200) {
                fetchData();
            }
        }
    }

    return (
        <>
            {processes.map((processe) => {
                return (
                    <ReeviewStyles key={processe.id}>
                        <ul>
                            <li>
                                <Text as="p">{processe.nome}</Text>
                            </li>
                            {processe.status === "PROCESSANDO" ||
                            processe.status === "AGUARDANDO" ? (
                                <li>
                                    <Text as="p" className="processando">
                                        {processe.status}
                                    </Text>
                                </li>
                            ) : null}
                            <li>
                                <Text as="p">
                                    {formatDateIsoSystem(processe.created_at)}
                                </Text>
                            </li>
                            <li>
                                <Text as="p">{processe.user.name}</Text>
                            </li>
                            <li>
                                <Text as="p">{processe.total_logs}</Text>
                            </li>
                            <li>
                                <Text as="p">
                                    R${" "}
                                    {formatCurrentMoney(
                                        processe.total_noname_credito_log_sum ||
                                            0
                                    )}
                                </Text>
                            </li>
                        </ul>

                        <div className="acoes">
                            <button
                                className="background"
                                onClick={() =>
                                    history.push({
                                        pathname: `/sistema/campanha/carga/${processe.id}/log-estorno`,
                                        state: {
                                            id: processe.id,
                                            type: "creditos_estorno_log",
                                            carga: "estorno",
                                            campanha: campanhaById.nome,
                                        },
                                    })
                                }
                            >
                                <img src={imgVisualizar} alt="" />
                            </button>

                            {/* <button
                                className="background"
                                onClick={() => alert("Em desenvolvimento")}
                            >
                                <img src={imgDownload} alt="" />
                            </button> */}

                            <Button
                                color="#EB001B"
                                background="#FFE3E7"
                                onClick={() => {
                                    setId(processe.id);
                                    setModalDisAprove(true);
                                }}
                            >
                                REPROVAR
                            </Button>

                            <Button
                                color="#fff"
                                background="#5DB356"
                                onClick={() => {
                                    setId(processe.id);
                                    setModalAprove(true);
                                }}
                            >
                                APROVAR
                            </Button>
                        </div>
                    </ReeviewStyles>
                );
            })}

            <ModalAprove
                handleClose={() => setModalAprove(false)}
                reject={() => setModalAprove(false)}
                aceept={() => {
                    handleAprove();
                    setModalAprove(false);
                }}
                title="Aprovar"
                description="Deseja aprovar a carga de estorno"
                open={modalAprove}
            />

            <ModalDisAprove
                handleClose={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                reject={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                aceept={() => {
                    handleDisAprove();
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                title="Reprovar"
                description="Deseja reprovar a carga de estorno?"
                open={modalDisAprove}
            />
        </>
    );
}
