import { memo } from "react";
import { useHistory } from "react-router-dom";
import { Text } from "alisson-application";

import { useTranslation } from "../../contexts/Localization";
import { UseLogin, LoginProvider } from "../../Hook/user/UseLogin";
import { UseModal } from "../../Hook/Usemodal";

import { HeaderModal } from "../HeaderModal";

import { Container } from "./styles";

import imgYetz from "../../assets/img/yetz-verde.svg";
import imgUser from "../../assets/img/user.svg";
import imgNotificacao from "../../assets/img/notificacao-verde.svg";
import imgCampanha from "../../assets/img/campanha-verde.svg";
import imgTermo from "../../assets/img/termo-uso.svg";
import imgContato from "../../assets/img/contato-verde.svg";
import imgSetaDirVerde from "../../assets/img/seta-dir-verde.svg";
import imgSair from "../../assets/img/sair.svg";
import imgEntrega from "../../assets/img/entrega.svg";
import pdfRegulamento from "../../assets/pdf/regulamento.pdf";
import pdfPoliticaDePrivacidade from "../../assets/pdf/Politica_de_privacidade.pdf";
import { UseCartoes } from "../../Hook/user/UseCartaoes";

function MenuModalDeskMobile() {
    const history = useHistory();
    const { t } = useTranslation();
    const { logout } = UseLogin();
    const { state, setState } = UseModal();
    const { cartao } = UseCartoes();
    const width = window.screen.width;

    const handleCloseMenu = () => {
        setState({ ...state, menu: false });
    };

    return (
        <>
            <Container>
                <HeaderModal
                    handleClick={() => setState({ ...state, menu: false })}
                />

                <div className="content-menu">
                    <ul>
                        <li
                            onClick={() => {
                                if (width <= 480) {
                                    history.push("/perfil");
                                    handleCloseMenu();
                                } else {
                                    setState({
                                        ...state,
                                        menu: false,
                                        perfil: true,
                                    });
                                }
                            }}
                        >
                            <Text as="span">
                                <img src={imgUser} alt="" />
                                {t("Perfil")}
                            </Text>

                            <img src={imgSetaDirVerde} alt="" />
                        </li>
                        <li
                            onClick={() => {
                                setState({
                                    ...state,
                                    menu: false,
                                    notificacao: true,
                                });
                            }}
                        >
                            <Text as="span">
                                <img src={imgNotificacao} alt="" />
                                {t("Notificações")}
                            </Text>

                            <img src={imgSetaDirVerde} alt="" />
                        </li>
                        <li
                            onClick={() => {
                                history.push("/tutorial");
                                setState({
                                    ...state,
                                    menu: false,
                                });
                            }}
                        >
                            <Text as="span">
                                <img src={imgYetz} alt="" />
                                {t("TUTORIAIS YETZPAY")}
                            </Text>

                            <img src={imgSetaDirVerde} alt="" />
                        </li>
                        <li
                            onClick={() => {
                                history.push("/campanhas");
                                setState({
                                    ...state,
                                    menu: false,
                                });
                            }}
                        >
                            <Text as="span">
                                <img src={imgCampanha} alt="" />
                                {t("Campanha")}
                            </Text>

                            <img src={imgSetaDirVerde} alt="" />
                        </li>
                        {cartao?.tipo === "cartao_fisico" && (
                            <li
                                onClick={() => {
                                    if (width <= 480) {
                                        history.push("/entrega");
                                        setState({
                                            ...state,
                                            menu: false,
                                        });
                                    } else {
                                        setState({
                                            ...state,
                                            menu: false,
                                            entrega: true,
                                        });
                                    }
                                }}
                            >
                                <Text as="span">
                                    <img src={imgEntrega} alt="" />
                                    status de entrega
                                </Text>

                                <img src={imgSetaDirVerde} alt="" />
                            </li>
                        )}

                        <a
                            href={pdfRegulamento}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <li>
                                <Text as="span">
                                    <img src={imgTermo} alt="" />
                                    {t("Termos e Condições de Uso")}
                                </Text>

                                <img src={imgSetaDirVerde} alt="" />
                            </li>
                        </a>

                        <a
                            href={pdfPoliticaDePrivacidade}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <li>
                                <Text as="span">
                                    <img src={imgTermo} alt="" />
                                    POLÍTICA DE PRIVACIDADE
                                </Text>

                                <img src={imgSetaDirVerde} alt="" />
                            </li>
                        </a>
                        <li
                            onClick={() =>
                                setState({
                                    ...state,
                                    menu: false,
                                    faleConosco: true,
                                })
                            }
                        >
                            <Text as="span">
                                <img src={imgContato} alt="" />
                                {t("Fale Conosco")}
                            </Text>

                            <img src={imgSetaDirVerde} alt="" />
                        </li>
                        <li onClick={() => logout()}>
                            <Text as="span">
                                <img src={imgSair} alt="" />
                                SAir
                            </Text>

                            <img src={imgSetaDirVerde} alt="" />
                        </li>
                    </ul>
                </div>
            </Container>
        </>
    );
}

const MenuModalDesktopMobileWrapper = () => {
    return (
        <LoginProvider>
            <MenuModalDeskMobile />
        </LoginProvider>
    );
};

export const MenuModalDesktopMobileMemorized = memo(
    MenuModalDesktopMobileWrapper
);
