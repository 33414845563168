import styled from "styled-components";

export const HeaderStyles = styled.div`
    height: 111px;
    position: relative;
    border-bottom: 1px solid #e1e2e3;
    .container-header {
        height: 100%;
        width: 1336px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }
    button {
        &.voltar {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            display: flex;
            align-items: center;

            img {
                margin-right: 12px;
            }
        }
    }

    .container-btns {
        width: auto;
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 10px;
        }
    }
`;

export const SubHeaderStyles = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 1366px;
    margin: 0 auto;
    padding-bottom: 32px;
    padding-top: 48px;
    border-bottom: 1px solid #e1e2e3;
    margin-bottom: 29px;
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
    }

    .container-user {
        display: flex;
        align-items: center;

        .container-text {
            margin-left: 17px;
            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: ${(props) => props.theme.colors.primary};
                margin-bottom: 9px;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primary};
            }
        }
    }
`;

export const ErrorStyles = styled.div`
    border: 1px solid #ffdbdf;
    border-radius: 4px;
    height: 46px;
    display: flex;
    align-items: center;
    width: 1366px;
    margin: 0 auto;
    padding: 12px 13px;
    margin-bottom: 65px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #eb001b;
        margin-left: 16px;
    }
`;

export const AlertStyles = styled.div`
    border: 1px solid #ffcc00;
    border-radius: 4px;
    height: 46px;
    display: flex;
    align-items: center;
    width: 1366px;
    margin: 0 auto;
    padding: 12px 13px;
    margin-bottom: 65px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #ffcc00;
        margin-left: 16px;
    }
`;

export const SuccessStyles = styled.div`
    padding: 12px 13px;
    background: ${(props) => props.theme.colors.disabled};
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 1366px;
    margin: 0 auto;
    margin-bottom: 65px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.secondary};
        margin-left: 16px;
    }
`;

export const TableStyles = styled.div`
    width: 1366px;
    margin: 0 auto;
`;
