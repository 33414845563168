import exclamation from "../../../assets/img/exclamation.svg"
import { UseModal } from "../../../Hook/Usemodal";
import { FirstStrikeContainer } from "./styles";

export const FirstStrikeModal = () => {
    const { handleClose } = UseModal();

    function handleCloseModal() {
        handleClose("avisoSenhaInvalida");
    }

    return (
        <FirstStrikeContainer>
            <img className="img-close" src={exclamation} alt="" />

            <h1>Acesso não autorizado</h1>

            <p>
                Confira se os dados foram digitados corretamente e tente
                novamente.
            </p>

            <button onClick={handleCloseModal}>OK</button>
        </FirstStrikeContainer>
    );
};
