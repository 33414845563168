import { ChangeEvent, useState } from "react";
import { Formik, ErrorMessage, FormikProps, Field } from "formik";
import { ConfirmaUser, confirmaUser, validationConfirmaUser } from "./config";
import { formataMoney, getCampanha } from "../../../util/helper";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import Select from "../../NovoSelect";
import { Button } from "../Button";
import { HeaderUser } from "./headerUser";
import { FormConfirmaUserStyles } from "./styles";
import { toast } from "react-toastify";
import { Permissao } from "./permissao";

export function FormConfirmaUser() {
    const { nextPasso, dados, setDados } = usePassos();
    const { user } = useUser();
    const [loading, setLoading] = useState(false);

    const userTransfer = dados[0];

    const tempOption =
        userTransfer?.campanhas?.map((item: any) => {
            return {
                label: item.chave,
                value: item.id,
            };
        }) || [];

    if (dados[0]?.campanhas[0]?.allow_transference_between_users === 0) {
        return <Permissao />;
    }

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={confirmaUser}
            validationSchema={validationConfirmaUser}
            onSubmit={(values, actions) => {
                setLoading(true);
                user.saldo = user.saldo + "";

                const saldoAtual = parseFloat(user.saldo);

                const value = parseFloat(
                    values.valor
                        ? values.valor
                              .replace(/[^0-9!,]/g, "")
                              .replace(",", ".")
                        : "0"
                );

                if (value === 0) {
                    toast.error("Digite um valor");
                    return;
                }

                if (value <= saldoAtual) {
                    const [dadosUser] = dados;
                    setDados({
                        ...dadosUser,
                        //campanha atual do usuário
                        campanha_from_id: getCampanha().id,

                        //campanha do select
                        campanha_to_id: parseInt(values.campanha),

                        //id da busca do cpf
                        user_to_id: userTransfer.id,

                        // valor digitado
                        valor: value,

                        saldoAtual: user.saldo,
                        valorTransferencia: value,
                        subTotal: saldoAtual - value,
                        nameCapanha: tempOption?.find(
                            (item: any) =>
                                item.value === parseInt(values.campanha)
                        )?.label,
                    });
                    nextPasso(3);
                } else {
                    toast.error("Saldo insuficiente");
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<ConfirmaUser>) => {
                const { setFieldValue, values } = props;
                const { valor } = values;

                return (
                    <FormConfirmaUserStyles>
                        <HeaderUser
                            document={userTransfer?.document}
                            img={userTransfer?.image}
                            name={userTransfer?.name}
                        />
                        <Select
                            tipo="campanha"
                            label="Campanha"
                            options={tempOption}
                            onChange={(value) =>
                                setFieldValue("campanha", value.value + "")
                            }
                        />
                        <ErrorMessage name="campanha" component="span" />
                        <div className={`container-input valor`}>
                            <Field
                                type="tel"
                                placeholder="DIGITE O VALOR"
                                value={valor ? valor : ""}
                                name={valor}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setFieldValue(
                                        "valor",
                                        formataMoney(e.target.value)
                                    );
                                }}
                            />
                        </div>
                        <ErrorMessage name="valor" component="span" />
                        <Button
                            type="submit"
                            spinerColor="primary"
                            isLoading={loading}
                            disabled={!props.isValid}
                            spiner="BallTriangle"
                            className="login"
                            mt="auto"
                        >
                            AVANÇAR
                        </Button>
                    </FormConfirmaUserStyles>
                );
            }}
        </Formik>
    );
}
