import { useState } from "react";
import { AcessoBloqueado } from "../../components/AcessoBloqueado";
import { FaleConosco } from "../../components/FaleConosco/UsuarioDeslogado";
import { EsqueciSenha } from "../../components/Mobile/EsqueciSenha";
import { Logindesktop } from "../../components/Mobile/LoginDesktop";
import { ModalAvisoSenhaInvalida } from "../../components/Modais";
import ModalBottom from "../../components/ModalBottom/bottomScroll";
import { ModalInformativo } from "../../components/ModalInformativo";
import Modal from "../../components/NovoModal";
import ModalPopUp from "../../components/NovoModal/popup";
import { ResetarSenha } from "../../components/ResetarSenha";
import { Footer } from "../../components/System/Footer";
import { UseModal } from "../../Hook/Usemodal";
import { UseFormLoginProvider } from "../../Hook/user/useFormLogin";

export function PageLogin() {
    const width = window.screen.width;
    const { handleClose, state } = UseModal();
    const [modalInformativo, setModalInformativo] = useState(true);

    function handleCloseModalInformativo() {
        setModalInformativo(false);
    }

    return (
        <UseFormLoginProvider>
            <Logindesktop />
            {width > 768 ? <Footer /> : <></>}

            {state?.esqueciSenha ? (
                <Modal
                    open={state?.esqueciSenha}
                    handleClose={() => handleClose("esqueciSenha")}
                >
                    <EsqueciSenha />
                </Modal>
            ) : (
                <></>
            )}

            {state.avisoSenhaInvalida ? (
                <ModalAvisoSenhaInvalida
                    open={!!state?.avisoSenhaInvalida}
                    handleClose={() => handleClose("avisoSenhaInvalida")}
                />
            ) : (
                <></>
            )}

            <Modal
                open={state?.faleConoscoUsuarioDeslogado}
                handleClose={() => handleClose("faleConoscoUsuarioDeslogado")}
            >
                <FaleConosco />
            </Modal>

            {state?.resetarSenha ? (
                <ModalBottom
                    open={state?.resetarSenha}
                    handleClose={() => handleClose("resetarSenha")}
                >
                    <ResetarSenha />
                </ModalBottom>
            ) : (
                <></>
            )}

            <ModalBottom
                open={state.acessoBloqueado}
                handleClose={() => handleClose("acessoBloqueado")}
            >
                <AcessoBloqueado />
            </ModalBottom>

            {width > 768 ? <ModalPopUp
                handleClose={handleCloseModalInformativo}
                open={modalInformativo}
            >
                <ModalInformativo handleClose={handleCloseModalInformativo} />
            </ModalPopUp> : <></>

            }
        </UseFormLoginProvider>
    );
}
