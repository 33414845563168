import { Box, Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import imgTransferir from "../../../assets/img/transferir-user.svg";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { formatCurrentMoney } from "../../../util/helper";
import { Button } from "../Button";
import { InformacaoTransferencia } from "./informacaoTransferencia";
import { ContainerCardsTransferir } from "./styles";

export function Sucesso() {
    const { dados } = usePassos();
    const { handleClose } = UseModal();
    const history = useHistory();
    const width = window.screen.width;
    const detalhesDestinoMock = [
        {
            label: "SALDO ATUAL",
            value: dados?.transferirCurrentCard?.saldo
                ? formatCurrentMoney(dados?.transferirCurrentCard?.saldo)
                : "",
        },
        {
            label: "VLR. TRANSFERÊNCIA",
            value: dados?.value ? formatCurrentMoney(dados?.value) : "",
        },
        {
            label: "SALDO FINAL",
            value:
                dados?.transferirCurrentCard?.saldo && dados?.value
                    ? formatCurrentMoney(
                          parseFloat(dados?.transferirCurrentCard?.saldo + "") +
                              dados?.value
                      )
                    : "",
        },
    ];

    return (
        <ContainerCardsTransferir>
            <img src={imgTransferir} alt="" />
            <Text textAlign="center" mb="41px">
                Transferência efetuada com sucesso.
            </Text>

            <InformacaoTransferencia
                detalhes={detalhesDestinoMock}
                carteira=""
                cardNumber={dados?.transferirCurrentCard?.number}
            />

            <Box mb="21px" mt="41px">
                <Button
                    onClick={() => {
                        if (width <= 480) {
                            history.goBack();
                        } else {
                            handleClose("transferir");
                        }
                    }}
                >
                    OK, ENTENDI
                </Button>
            </Box>
        </ContainerCardsTransferir>
    );
}
