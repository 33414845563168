import { Box, Text } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import { useTranslation } from "../../../contexts/Localization";
import { podesolicitarCartao, getCampanha } from "../../../util/helper";
// import { Select } from "../../Select";
import { options } from "../../NovoSelect";
import { usePassos } from "../../../Hook/UsePassos";
import {
    solicitarCartao,
    validaSolicitarCartao,
    SolicitarCartao,
} from "./config";
import { Button } from "../Button";
import { keyOf, TipoCartao } from "./config";
import imgCartao from "../../../assets/img/cartao.svg";

import { SolicitarStyles } from "./styles";
import { useEffect, useState } from "react";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useUser } from "../../../Hook/user/UseUser";
import { UseModal } from "../../../Hook/Usemodal";
interface SolicitarProps {
    setTipoCartao: (tipoCartao: keyOf<TipoCartao>) => void;
}

export function Solicitar(props: SolicitarProps) {
    const { setTipoCartao } = props;
    const { nextPasso, setDados, dados } = usePassos();
    const { handleOpen } = UseModal();
    const { cartoes } = UseCartoes();
    const { user } = useUser();
    const { t } = useTranslation();
    const [options, setOptions] = useState<options[]>([]);
    const saldo = parseFloat(user.saldo + "" || "0");

    useEffect(() => {
        if (getCampanha().id) {
            setOptions(podesolicitarCartao([getCampanha()], cartoes));
        }
    }, [cartoes]);

    return (
        <SolicitarStyles className="container">
            <Text as="p" textAlign="center">
                {!options.length ? (
                    cartoes.length ? (
                        "VOCÊ NÃO PODE MAIS SOLICITAR CARTÕES"
                    ) : (
                        "VOCÊ NÃO POSSUI SALDO SUFICIENTE PARA SOLICITAR UM CARTÃO."
                    )
                ) : (
                    <>
                        É fácil. Em poucos passos vamos <br /> solicitar um
                        cartão. <br /> Selecione uma das opções abaixo:
                    </>
                )}
            </Text>

            <Formik
                isInitialValid={false}
                initialValues={solicitarCartao}
                validationSchema={validaSolicitarCartao}
                onSubmit={(values, actions) => {
                    setDados({
                        ...dados,
                        ...values,
                    });
                    nextPasso(2);
                }}
            >
                {(props: FormikProps<SolicitarCartao>) => {
                    const { setFieldValue, values } = props;
                    const { tipo } = values;

                    return (
                        <Box>
                            <Form>
                                {options
                                    .filter(
                                        (item) => item.value !== "cartao_avulso"
                                    )
                                    .map((item, key) => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => {
                                                    if (
                                                        item.value ===
                                                            "cartao_fisico" &&
                                                        saldo < 30
                                                    ) {
                                                        handleOpen(
                                                            "saldoInsuficiente"
                                                        );
                                                        return;
                                                    }
                                                    setFieldValue(
                                                        "tipo",
                                                        item.value
                                                    );
                                                    setTipoCartao(
                                                        item.value as keyOf<TipoCartao>
                                                    );
                                                }}
                                                className={`container-tipo ${
                                                    tipo === item.value
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <img src={imgCartao} alt="" />
                                                <p>{item.label}</p>
                                            </div>
                                        );
                                    })}
                                <ErrorMessage name="tipo" component="span" />
                                <Button disabled={!props.isValid}>
                                    {t("avançar")}
                                </Button>
                            </Form>
                        </Box>
                    );
                }}
            </Formik>
        </SolicitarStyles>
    );
}
