import styled from "styled-components";

export const Container = styled.div`
    .container-informacoes {
        width: 95%;
        margin: 0 auto;
        .item {
            padding-bottom: 19px;
            border-bottom: 1px solid #e1e2e3;
            margin-bottom: 19px;
        }
    }
`;
