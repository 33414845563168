import { useEffect, useState } from "react";
import { Flex } from "alisson-application";
import { UseCartoes, Extrato } from "../../../Hook/user/UseCartaoes";
import { ModaisMenu } from "../../Modais/Mobile/menu";

// import ModalBottom from "../../ModalBottom";
// import { ModalMovimentacao } from "./modal";
// import { BloquearCartao } from "../BloquearCartao";
// import { DesbloqueioCartao } from "../DesbloquearCartao";
// import { ConfirmaDesbloqueioCartao } from "../DesbloquearCartao/confirmar";
// import { ConfirmaToggleSms } from "./confirmaToggleSms";

import { useTranslation } from "../../../contexts/Localization";

import { GoBack } from "../GoBack";
import { Empty } from "../Empty";
import { Header } from "./header";
import { Cards } from "./Cards";
import { Movimentacao } from "./movimentacao";
import { Relatorio } from "../Relatorio/relatorioAcg";
import { GraficoPositiveNegative } from "../../Desktop/OverView/grafico";
import { Container } from "./styles";
import { Loader } from "../../Loader";
import { FiltroEntradaSaida } from "../Movimentacao/FiltroEntradaSaida";
import { FiltroDate } from "../Movimentacao/FiltroDate";
import { formatDate } from "../../../util/helper";

export function DetalheMovimentacao() {
    const { t } = useTranslation();
    const { extratos, loadingExtrato, getExtrato, cartaoId } = UseCartoes();
    const [indexSwiper, setIndexSwiper] = useState<number>(0);

    const [dados, setDados] = useState<Extrato[][]>([]);
    const [filtro, setFiltro] = useState<Extrato[][]>([]);

    const [entrada, setEntrada] = useState(false);
    const [saida, setSaida] = useState(false);

    const [antigo, setAntigo] = useState(false);
    const [recent, setRecent] = useState(false);

    useEffect(() => {
        if (cartaoId) {
            getExtrato(cartaoId);
        }
    }, [cartaoId, getExtrato]);

    useEffect(() => {
        const update = [...dados];
        if (entrada) {
            setFiltro(
                update.map((item) => {
                    return item.filter((item) => item.isCredit === true);
                })
            );
            return;
        }

        if (saida) {
            setFiltro(
                update.map((item) => {
                    return item.filter((item) => item.isCredit === false);
                })
            );
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entrada, saida]);

    useEffect(() => {
        const update = [...dados];
        if (antigo) {
            setFiltro(update.reverse());
            return;
        }

        if (recent) {
            setFiltro(update);
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [antigo, recent]);

    useEffect(() => {
        const arrDate = [
            ...new Set(
                extratos.map((item) => {
                    return formatDate(item.eventDate);
                })
            ),
        ];

        const arrGroupedDate = arrDate.map((item) => {
            return extratos.filter((extrato) => {
                return formatDate(extrato.eventDate) === item;
            });
        });

        setAntigo(false);
        setRecent(false);
        setDados(arrGroupedDate);
    }, [extratos]);

    return (
        <Container>
            <GoBack title={t("detalhes do seu cartão")} />
            <Header indexSwiper={indexSwiper} />
            <Cards setIndexSwiper={setIndexSwiper} />
            <div className="background-white">
                <Movimentacao />
                {loadingExtrato ? (
                    <div className="container-loader-grafico">
                        <Loader width="100px" />
                    </div>
                ) : (
                    <>
                        {dados?.length !== 0 ? (
                            <>
                                <GraficoPositiveNegative />
                                <Flex
                                    width="90%"
                                    m="0 auto 32px"
                                    justifyContent="space-between"
                                >
                                    <FiltroEntradaSaida
                                        handleEntrada={setEntrada}
                                        handleSaida={setSaida}
                                    />
                                    <FiltroDate
                                        handleAntigo={setAntigo}
                                        handleRecent={setRecent}
                                    />
                                </Flex>
                                {dados.length !== 0
                                    ? filtro.length
                                        ? filtro.map((item: Extrato[], key) => {
                                              if (item.length === 0)
                                                  return <></>;

                                              return (
                                                  <>
                                                      <Relatorio
                                                          key={key}
                                                          dados={item}
                                                      />
                                                  </>
                                              );
                                          })
                                        : dados.map((item: Extrato[], key) => {
                                              if (item.length === 0)
                                                  return <></>;

                                              return (
                                                  <>
                                                      <Relatorio
                                                          key={key}
                                                          dados={item}
                                                      />
                                                  </>
                                              );
                                          })
                                    : null}
                            </>
                        ) : (
                            <Empty />
                        )}
                    </>
                )}
            </div>

            <ModaisMenu />
        </Container>
    );
}
