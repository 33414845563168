import { useEffect, useState } from "react";

import ReactCodeInput from "react-code-input";
import imgSenhacartao from "../../assets/img/senha-cartao.svg";
import { Container } from "./styles";

interface CodeProps {
    getCode?: (value: string) => void;
    errorCode: boolean;
    setErrorCode: (value: boolean) => void;
}

export function PasswordCartao(props: CodeProps) {
    const { getCode, errorCode } = props;
    const [code, setCode] = useState("");
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        if (getCode) {
            getCode(code);
        }

        setCompleted(code.length === 4);
    }, [code, getCode]);

    useEffect(() => {
        if (errorCode) {
            setCode("");
        }
    }, [errorCode, getCode]);

    return (
        <Container className={`container-code ${completed ? "active" : ""}`}>
            <div className="container-img">
                <img src={imgSenhacartao} alt="" />
            </div>

            <div className="container-descricao">
                <p>Digite a senha do seu Cartão YetzPay</p>
                <span>com 4 dígitos</span>
            </div>

            <div className="container-inputs">
                <ReactCodeInput
                    inputMode="numeric"
                    name="password"
                    pattern="[0-9]*"
                    type="password"
                    onChange={(inptCode) => setCode(inptCode)}
                />
            </div>
        </Container>
    );
}
