import TableUser from "../../Table";
import { useHistory } from "react-router-dom";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { Button } from "../../Button/areas";
import { Loader } from "../../../Loader";
import { Data } from "./config";
import imgOlho from "../../../../assets/img/olho.svg";
import { useUser } from "../../../../Hook/system/useUser";
import { formataCPF, formatDateIsoSystem } from "../../../../util/helper";
import { Bloqueio } from "./bloqueio";

export function Table() {
    const { users, loading } = useUser();
    const width = 1645 / 8;
    const history = useHistory();
    const dados: Data[] = users.map((user) => {
        return {
            id: user.id + "",
            nome: user.name,
            document: user?.document ? formataCPF(user?.document) : "",
            campanha: user?.campanhas?.map((campanha) => campanha.nome),
            campanha_id: user?.campanhas.length ? user?.campanhas[0].id : 0,
            status: user?.status ? "ATIVO" : "INATIVO",
            cadastro: user.register_status ? "COMPLETO" : "INCOMPLETO",
            toggleStatus: user?.status ? "1" : "0",
            data_cadastro: user?.registered_at
                ? formatDateIsoSystem(user.registered_at)
                : "",
            visualizar: "",
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "id",
            width: 100,
        },

        {
            field: "nome",
            headerName: "Nome",
            width,
        },

        {
            field: "document",
            headerName: "CPF",
            width,
        },
        {
            field: "campanha",
            headerName: "CAMPANHA",
            width,
        },
        {
            field: "status",
            headerName: "STATUS",
            width,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Button
                        className={`cadastro ${
                            row.status === "ATIVO" ? "" : "ERROR"
                        }`}
                    >
                        {row.status}
                    </Button>
                );
            },
        },
        {
            field: "cadastro",
            headerName: "CADASTRO",
            width,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Button
                        className={`cadastro ${
                            row.cadastro === "COMPLETO" ? "" : "ERROR"
                        }`}
                    >
                        {row.cadastro}
                    </Button>
                );
            },
        },
        {
            field: "data_cadastro",
            width,
            headerName: "DATA DO CADASTRO",
        },
        {
            field: "toggleStatus",
            headerName: "BLOQUEIO",
            width: 150,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Bloqueio
                        checked={row.toggleStatus === "1" ? false : true}
                        id={row.id}
                    />
                );
            },
        },
        {
            field: "visualizar",
            headerName: "",
            width: 50,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            history.push({
                                pathname: `/sistema/usuario/${row.id}`,
                                state: {
                                    id: row.id,
                                    campanhaId: row.campanha_id,
                                },
                            });
                        }}
                    >
                        <img src={imgOlho} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <TableUser
                    rows={dados ? dados : []}
                    columns={columns}
                    nameCSV="USUÁRIOS"
                />
            )}
        </>
    );
}
