import { Box, Text } from "alisson-application";
import { Button } from "../Button";
import { SegundaViaStyles } from "./styles";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { useHistory } from "react-router-dom";
export function PageSegundaVia() {
    const { nextPasso } = usePassos();
    const { handleClose, state, setState } = UseModal();
    const history = useHistory();
    const width = window.screen.width;
    return (
        <SegundaViaStyles>
            <h1>ATENÇÃO!</h1>
            <p>
                Deseja efetuar o CANCELAMENTO do cartão de forma IRREVERSÍVEL? O
                crédito existente no cartão será transferido automaticamente
                para o SALDO DA CARTEIRA.
            </p>
            <br />

            <p>
                Haverá cobrança para emissão e envio de um novo cartão, caso
                solicitado.
            </p>
            <br />

            <p>Atente para a opção de BLOQUEIO TEMPORÁRIO antes de se decidi</p>
            <br />
            <br />
            <Box>
                <Button onClick={() => nextPasso(2)} mb="32px">
                    Sim, seguir com cancelamento.
                </Button>

                <Button
                    onClick={() => {
                        setState({
                            ...state,
                            segundaVia: false,
                            acaoBloquearCartao: true,
                        });
                    }}
                    mb="32px"
                >
                    IR PARA BLOQUEIO TEMPORÁRIO
                </Button>

                <Button
                    onClick={() => {
                        handleClose("segundaVia");
                        if (width <= 480) {
                            history.goBack();
                        }
                    }}
                    mb="32px"
                >
                    <Text fontSize="xs" color="white">
                        NÃO CANCELAR NEM BLOQUEAR O <br />
                        CARTÃO
                    </Text>
                </Button>
            </Box>
        </SegundaViaStyles>
    );
}
