import { useState, useEffect } from "react";
import { CountDownMemo } from "../../Countdown";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { Variant } from "../../../Hook/types";
import imgSms from "../../../assets/img/sms-verde.svg";

interface EnviaProps {
    code: string;
    setErrorCode: (value: boolean) => void;
    setLoading: (value: boolean) => void;
}
export function Acoes(props: EnviaProps) {
    const { code, setErrorCode, setLoading } = props;
    const [timer, setTimer] = useState(true);
    const { getCode, confirmCode } = UseLogin();
    
    async function send(send: Variant) {
        setTimer(true);
        await getCode(send);
    }

    useEffect(() => {
        setTimer(true);
    }, []);


    useEffect(() => {

        const withOutBlanks = code.replaceAll(" ", "");
        if (withOutBlanks.length < 5) return;

        async function validateCode(){
            setLoading(true);
            const responseCode = await confirmCode(code);
            if (responseCode.status === 200) {
                setErrorCode(false);
            } else {
                setErrorCode(true);
            }
            setLoading(false);
        }

        const delayInputCheck = setTimeout(() => {
            validateCode()
            return () => clearTimeout(delayInputCheck);
        }, 1500);
        return () => clearTimeout(delayInputCheck);

    }, [code, confirmCode, setErrorCode, setLoading]);

    return (
        <>
            {timer && (
                <div className={`container-timer`}>
                    <CountDownMemo setTimer={setTimer} />
                </div>
            )}

            <button
                className="re-eneviar-sms"
                disabled={timer}
                onClick={() => send("whatsapp")}
            >
                <img src={imgSms} alt="" /> enviar novamente
            </button>

            {/* <div className={`container-submit ${timer ? "disabled" : ""}`}>
                <Button
                    isLoading={loading}
                    disabled={code.length < 5}
                    spiner="ThreeDots"
                    onClick={async () => {
                        if (code.length < 5) {
                            setErrorCode(true);
                            return;
                        } else {
                            setLoading(true);
                            const responseCode = await confirmCode(code);
                            if (responseCode.status === 200) {
                                setErrorCode(false);
                            } else {
                                setErrorCode(true);
                            }
                            setLoading(false);
                        }
                    }}
                >
                    {t("avançar")}
                </Button>
            </div> */}
        </>
    );
}
