import { Text, Box } from "alisson-application";
import { useHistory } from "react-router-dom";

import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { ModalFormProps, modalInfra, validationModalInfra } from "./config";
import { useContex } from "../Hook/modal";
import { useInfra } from "../Hook";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";
import { HistoryProps } from "../../../types";

import { Button } from "../../../../Button";
import { ModalStyles } from "./styles";
import imgXVerde from "../../../../../../assets/img/x.svg";
import imgCargaModal from "../../../../../../assets/img/carga.svg";
import Select from "../../../../../NovoSelect";
interface FormInfraProps {
    setSucess: (value: boolean) => void;
}

export type keyOfCarga<T> = keyof T;

export function FormInfra(props: FormInfraProps) {
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const { setSucess } = props;
    const { nomeArquivoInfra, setModalInfra } = useContex();
    const { storeCarga, file, setFile } = useInfra();
    const { selectContato } = useCampanha();

    return (
        <ModalStyles>
            <Formik
                enableReinitialize
                initialValues={{ ...modalInfra, nome_arq: nomeArquivoInfra }}
                validationSchema={validationModalInfra}
                onSubmit={async (values, actions) => {
                    const formData = new FormData();

                    const keys = Object.keys(
                        values
                    ) as keyOfCarga<ModalFormProps>[];

                    keys.forEach((key) => {
                        formData.append(key, values[key]);
                    });

                    formData.append("campanha_id", id ? id + "" : "");
                    formData.append("tipo", "USER");
                    formData.append("file", file ? file : "");

                    const response = await storeCarga(formData);

                    if (response.status === 200) {
                        setSucess(true);
                        setFile(null);
                    } else {
                        setSucess(false);
                        setFile(null);
                    }

                    actions.resetForm();
                }}
            >
                {(props: FormikProps<ModalFormProps>) => {
                    const { setFieldValue } = props;

                    return (
                        <Form>
                            <div className="container-header">
                                <Text as="p">NOVA CARGA DE INFRA</Text>
                                <button
                                    type="button"
                                    onClick={() => setModalInfra(false)}
                                >
                                    <img src={imgXVerde} alt="" />
                                </button>
                            </div>

                            <div className="container-content">
                                <div className="container-img">
                                    <img src={imgCargaModal} alt="" />
                                </div>

                                <Text as="span">
                                    Preencha os campos abaixo para enviar o
                                    arquivo.
                                </Text>
                            </div>

                            <div className="container-input-system disabled">
                                <Field
                                    type="text"
                                    value={nomeArquivoInfra}
                                    name="nome_arq"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome_arq" />

                            <div className="container-input-system carga">
                                <Field
                                    type="text"
                                    placeholder="TÍTULO DA CARGA"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <div className="container-input-system cliente">
                                <Field
                                    type="text"
                                    placeholder="JOB"
                                    name="job"
                                />
                            </div>
                            <ErrorMessage component="span" name="job" />

                            <Select
                                options={selectContato}
                                tipo="cliente"
                                label="CONTATO"
                                onChange={(values) => {
                                    setFieldValue(
                                        "cliente_area_contato_id",
                                        values.value
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="cliente_area_contato_id"
                            />

                            <div className="container-input-system">
                                <Field
                                    type="text"
                                    placeholder="OBS."
                                    name="observacao"
                                    component="textarea"
                                />
                            </div>
                            <ErrorMessage component="span" name="observacao" />

                            <Box mt="75px">
                                <Button type="submit">ADICIONAR CARGA</Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </ModalStyles>
    );
}
