import { useState } from "react";
import { Text } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { IAlterarSenha, altearSenha, validationAlterarSenha } from "./config";
import imgOlho from "../../../assets/img/olho.svg";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { Button } from "../Button";
import { FormStyles } from "./styles";
import { crypted } from "../../../util/helper";
import { useHistory } from "react-router-dom";
import { CheckPassword } from "../../CheckPassword";

export function AltearSenha() {
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const { nextPasso, dados } = usePassos();
    const { updatePassword } = useUser();
    const history = useHistory();
    const hasSistem = history.location.pathname === "/sistema";

    return (
        <FormStyles>
            <Text as="p">
                Preencha o campo abaixo com sua nova senha e clique para
                alterar.
            </Text>

            <Formik
                enableReinitialize
                validateOnMount
                initialValues={altearSenha}
                validationSchema={validationAlterarSenha}
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    let response;

                    if (hasSistem) {
                        response = await updatePassword({
                            password: values.password,
                            email: dados.email,
                        } as any);
                    } else {
                        const dadosCrypted = crypted(
                            `password#${values.password}`,
                            `document#${dados.document}`,
                            `email#${dados.email}`
                        );
                        response = await updatePassword({
                            ...dadosCrypted,
                            birth_date: dados.birth_date,
                        });
                    }

                    if (response.status === 200) {
                        nextPasso(4);
                    }
                    setLoading(false);
                }}
            >
                {(props: FormikProps<IAlterarSenha>) => {
                    const { values } = props;

                    return (
                        <Form>
                            <div
                                className={`container-input senha olho ${
                                    props.errors.password &&
                                    props.touched.password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    value={values.password}
                                    placeholder="SENHA"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>

                            <ErrorMessage name="password" component="span" />

                            <div
                                className={`container-input senha olho ${
                                    props.errors.password_confirmation &&
                                    props.touched.password_confirmation
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password_confirmation"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    value={values.password_confirmation}
                                    placeholder="CONFIRMAR SENHA"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="password_confirmation"
                                component="span"
                            />

                            <CheckPassword values={values.password} />

                            <div className="mt-auto">
                                <Button
                                    mt="70px"
                                    type="submit"
                                    spinerColor="primary"
                                    isLoading={loading}
                                    disabled={!props.isValid}
                                    spiner="BallTriangle"
                                >
                                    ALTERAR SENHA
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
