import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Button } from "../Mobile/Button";
import imgBoleto from "../../assets/img/conta.svg";
import imgIconePagamento from "../../assets/img/icone-pagamento.svg";
import { Container } from "./styles";
import { usePassos } from "../../Hook/UsePassos";
import { UseModal } from "../../Hook/Usemodal";
import { arrPassosBoletoPagamento } from "../PagamentoBoleto/config";

export function PagamentoRealizado() {
    const { nextPasso, setPassos, setDados } = usePassos();
    const datePagamento = new Date().toLocaleString().replace(/,/g, " |");
    const { handleClose } = UseModal();
    const history = useHistory();
    const width = window.screen.width;
    return (
        <Container>
            <img src={imgIconePagamento} alt="" />

            <Text as="h3" color="secondary" fontSize="mdl" bold>
                Pagamento <br /> realizado!
            </Text>

            <Text color="primaryBright" fontSize="xss" mb="48px">
                {datePagamento}
            </Text>

            <Button mb="8px" onClick={() => nextPasso(6)}>
                <img src={imgBoleto} alt="" /> &nbsp; exibir comprovante
            </Button>

            <Button
                transparent={true}
                mb="11px"
                color="secondary"
                onClick={() => {
                    setPassos(arrPassosBoletoPagamento);
                    setDados({});
                }}
            >
                pagar outra conta
            </Button>

            <Text
                as="button"
                color="primaryBright"
                fontSize="xs"
                width="100%"
                onClick={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        handleClose("boleto");
                    }
                }}
            >
                Voltar ao início
            </Text>
        </Container>
    );
}
