import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 16px;
        margin-top: 44px;
    }
`;

export const SucessoStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 311px;
    padding: 50px 11px 35px;
        
    img {
        margin-bottom:22px;        
    }

    h1 {
        flex:1;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.secondarydark};
        margin-bottom: 10px;
    }

    button{
        max-width: 273px;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
    }
`;
