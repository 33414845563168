import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import { useHistory } from "react-router-dom";
import { usePassos } from "../../Hook/UsePassos";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

export function VideoDecode() {
    const ref = React.createRef<HTMLDivElement>();
    const { setDados } = usePassos();
    const history = useHistory<{ valid: boolean }>();
    const persion = history.location.state?.valid;
    async function scaner() {
        try {
            const scanner = await BarcodeScanner.createInstance();
            // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode.
            scanner.barcodeFillStyle = "#57b84747";
            if (scanner.isContextDestroyed()) return;
            await scanner.setUIElement(ref.current!);
            // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode.
            if (scanner.isContextDestroyed()) return;
            scanner.onFrameRead = (results) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                for (let result of results) {
                    // console.log(result.barcodeText);
                }
            };
            scanner.onUniqueRead = (txt, result) => {
                setDados({
                    barCode: txt,
                    result,
                });

                history.push({
                    pathname: "/pagamento-boleto",
                    state: {
                        valid: true,
                    },
                });

                document.exitFullscreen();
            };
            await scanner.open();
        } catch (ex: any) {
            if (ex.message.indexOf("network connection error")) {
                toast.error("Algo deu errado, tente novamente mais tarde.");
            }
            throw ex;
        }
    }

    async function fullScreen() {
        document.documentElement.requestFullscreen();
        // eslint-disable-next-line no-restricted-globals
        var promise = screen.orientation.lock("portrait");

        await promise
            .then(function () {})
            .catch(function (err: any) {
                alert("Error: " + err.message);
                document.exitFullscreen();
            });
    }

    useEffect(() => {
        if (!persion) {
            history.push("/dashboard");
            return;
        }
        scaner();
        fullScreen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (persion) return <></>;

    return (
        <div ref={ref} className="component-barcode-scanner">
            <div className="dce-video-container"></div>
            <div className="dce-scanarea">
                <div className="dce-scanlight"></div>
            </div>
        </div>
    );
}
