import { Text } from "alisson-application";
import { Button } from "../Button";
import imgSuccess from "../../../assets/img/success-user-side.svg";
import { Container } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";
export function HorarioFuncionamento() {
    const { handleClose, state } = UseModal();

    return (
        <Container>
            <div className="container">
                <img className="img-close" src={imgSuccess} alt="" />

                {state.dados.text}

                <Text
                    fontSize="xs"
                    textAlign="center"
                    color="primary"
                    mb="39px"
                >
                    Por favor, retorne neste período.
                </Text>

                <Button onClick={() => handleClose("horarioFuncionamento")}>
                    OK, ENTENDI
                </Button>
            </div>
        </Container>
    );
}
