import { useEffect, useState } from "react";

export function PageIdWall() {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [scriptError, setScriptError] = useState(false);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sdkweb-lib.idwall.co/index.js"; // Substitua pelo URL do seu script
        script.async = true;

        const onScriptLoad = () => {
            setScriptLoaded(true);
            loadIdWall();
        };

        const onScriptError = () => {
            setScriptError(true);
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        document.body.appendChild(script);

        return () => {
            script.removeEventListener("load", onScriptLoad);
            script.removeEventListener("error", onScriptError);
            document.body.removeChild(script);
        };
    }, []);

    function loadIdWall() {
        // @ts-ignore
        idwSDKWeb({
            token: "U2FsdGVkX1/R/Gf/cktG9GvGbr8PVLy0gkFiYi+8sBSoaxiiog==",
            onRender: () => {
                console.log("it renders!");
            },
            onComplete: ({ token }: any) => {
                console.log("SDK Token", token);
            },
            onError: (error: any) => {
                alert(error);
            },
        });
    }
    return (
        <div>
            <h1>Meu Componente</h1>
            {scriptLoaded && <div data-idw-sdk-web></div>}
            {scriptError && <p>Falha ao carregar o script.</p>}
            {/* Seu conteúdo aqui */}
        </div>
    );
}
