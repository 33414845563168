import styled from "styled-components";

export const Container = styled.div`
    border-radius: 5px;
    background: ${(props) => props.theme.colors.background};
    padding: 17px 16px 20px 14px;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0px;
    }
    .container-data {
        h3 {
            color: ${(props) => props.theme.colors.primary};
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            text-transform: uppercase;
        }
    }

    .container-pagamento {
        display: grid;
        grid-template-columns: 14px 1fr 90px;
        .container-img {
            padding-top: 3px;
        }

        .container-title {
            margin-left: 6px;
            h4 {
                color: ${(props) => props.theme.colors.primary};
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                text-transform: uppercase;
            }

            h5 {
                color: ${(props) => props.theme.colors.primary};
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }

            h6 {
                color: ${(props) => props.theme.colors.primaryDark};
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
            }
        }

        .container-valor {
            display: flex;
            flex-direction: column;
            h4 {
                color: #ff8740;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                text-transform: uppercase;
                text-align: right;
            }
            button {
                color: #ff8740;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                margin-top: auto;
            }
        }
    }
`;
