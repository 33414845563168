import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.theme.colors.tertiary};
    padding-bottom: 32px;
    .background-white {
        background: ${(props) => props.theme.colors.white};
    }

    .container {
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primary};
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-loader-grafico {
        width: 100%;
        height: 300px;
        position: relative;
    }
`;

export const Cartoes = styled.div`
    padding-top: 27px;
    width: 100%;
    overflow: hidden;
    height: 390px;
    .swiper-pagination {
        bottom: -35px;
        .swiper-pagination-bullets {
            &.swiper-pagination-bullet-active {
                background-color: ${(props) => props.theme.colors.secondary};
            }
        }
    }

    .swiper-slide {
        height: 320px;
        transform: scale(0.9);
        transition: all 0.5s;
        opacity: 0.6;
        &.swiper-slide-active {
            transform: scale(1);
            transition: all 0.5s;
            opacity: 1;
        }
    }

    .slide-cartoes-detalhe {
        padding: 0 23%;
        overflow: unset;
    }
`;

export const ModalStyles = styled.div`
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    border-radius: 24px 24px 0px 0px;
    width: 100vw;
    padding: 52px 26px 38px 26px;

    button {
        &.pagamento-contas {
            border-radius: 32px;
            background-color: ${(props) => props.theme.colors.secondary};
            height: 50px;
            width: 100%;
            margin-bottom: 11px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.disabled {
                opacity: 0.5;
            }
            div {
                position: static !important;
                top: unset !important;
                left: unset !important;
                transform: unset !important;
            }
        }
    }

    ul {
        li {
            height: 56px;
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid ${(props) => props.theme.colors.disabled};
            .icone {
                img {
                    width: 24px;
                }
            }

            a {
                display: block;
                width: 100%;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
`;

export const ContainerMovimentacao = styled.div`
    padding-top: 41px;
    position: relative;
`;

export const Content = styled.div`
    position: relative;
    margin-bottom: 28px;
    width: 100%;
    overflow: hidden;
    height: 151px;
    border-radius: 24px 24px 0px 0px;
    background: ${(props) => props.theme.colors.white};
    margin-top: 23px;
    .slide-mes {
        position: absolute;
        left: 5%;

        bottom: 2px;

        width: 90%;
    }
`;

export const ConfirToggleSmsStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 50px 9px 35px;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondarydark};
        margin-bottom: 15px;
        margin-top:21px;
    }

    p {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        margin-top: 9px;
        padding: 0px 35px;
        margin-bottom: 32px;

        span{
            display: block;
            margin-top:16px;
        }
    }

    button {
        height:40px;
        text-transform: none;
        font-weight: 700;
        margin:0;
        letter-spacing:0;
        &.goBackBtn{
            font-weight: 400;
            color:#5E5F5F;
        }
    }
`;

export const HeaderStyles = styled.div`
    padding-top: 27px;
    .saldo-detalhe-cartao {
        border: 1px solid ${(props) => props.theme.colors.secondary};
        border-radius: 8px;
        height: 58px;
        padding: 13px 0px 13px 11px;
        button {
            width: fit-content;
            background: ${(props) => props.theme.colors.secondary};
            font-size: 12px;
            padding-left: 22px;
            padding-right: 17px;
            border-radius: 8px;
            margin-right: -0.5px;
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 9.26095px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 5px;
        }

        h3 {
            display: flex;
            align-items: flex-start;
            span {
                font-style: normal;
                font-weight: 600;
                font-size: 12.3479px;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: ${(props) => props.theme.colors.primary};
                display: block;
                padding-top: 4px;
            }
        }
    }
`;
