import React, { createContext, useContext, useCallback, useState } from "react";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { getLocalStore } from "../../util/helper";
import { api } from "../../services";
import { PaginationConfig } from "../system/config";

interface UseFaleConoscoProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseFaleConosco {
    faleConosco: FaleConosco[];
    loading: boolean;
    enviarMensagem(dados: FormData): Promise<AxiosResponse>;

    getFaleConosco: () => Promise<void>;
    getFaq: (page?: number, search?: string) => Promise<DataFaq>;
}

const UseFaleConoscoContext = createContext<UseFaleConosco>(
    {} as UseFaleConosco
);

interface FaleConoscoData {
    data: FaleConosco[];
}

export interface FaleConosco {
    id: number;
    campanha_id: number;
    user_id: number;
    phone: string;
    description: string;
    status: string;
    created_at: string;
    updated_at: string;
    campanha: Campanha;
}

interface Campanha {
    id: number;
    cliente_id: number;
    cliente_area_contato_id: number;
    nome: string;
    chave: string;
    regulamento: string;
    status: string;
    max_carga_credito_per_user: null | string;
    custom_fields: string;
    emissao: number;
    start_date_time: string;
    end_date_time: string;
    created_at: string;
    updated_at: string;
    cliente: Cliente;
    cliente_area_contato: ClienteAreaContato;
}

interface Cliente {
    id: number;
    nome: string;
    razao_social: string;
    cnpj: string;
    created_at: string;
    updated_at: string;
    areas: area[];
}

interface area {
    id: number;
    cliente_id: number;
    nome: string;
    created_at: string;
    updated_at: string;
}

interface ClienteAreaContato {
    id: number;
    cliente_area_id: number;
    nome: string;
    telefone: string;
    celular: string;
    email: string;
    created_at: string;
    updated_at: string;
    area: area;
}

export interface Faq {
    created_at: null | string;
    description: string;
    id: number;
    title: string;
    updated_at: string;
}

export interface DataFaq extends PaginationConfig {
    data: Faq[];
}

export function UseFaleConoscolProvider(props: UseFaleConoscoProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [faleConosco, setFaleConosco] = useState<FaleConosco[]>(
        [] as FaleConosco[]
    );

    async function enviarMensagem(dados: FormData) {
        const campanhaId = getLocalStore()?.campanha_user?.id;
        dados.append("campanha_id", campanhaId + "");
        try {
            const response = await api.post(`helpdesk`, dados);
            await getFaleConosco();
            toast.success("Mensagem enviada");
            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error?.response;
        }
    }

    const getFaleConosco = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get<FaleConoscoData>(`helpdesk`);
            setFaleConosco(response.data.data);
        } catch (error) {
            console.log(error);
            toast.error("Algo de errado aconteceu");
            setFaleConosco([] as FaleConosco[]);
        }
        setLoading(false);
    }, []);

    const getFaq = useCallback(async (page?: number, search?: string) => {
        const hasPage = page
            ? `?page=${page}&search=${encodeURI(search || "")}`
            : "";

        try {
            const response = await api.get<DataFaq>(`faq${hasPage}`);
            return response.data;
        } catch (error) {
            toast.error("Erro ao carregar Faq");
            return {} as DataFaq;
        }
    }, []);

    return (
        <UseFaleConoscoContext.Provider
            value={{
                loading,
                faleConosco,
                getFaleConosco,
                enviarMensagem,
                getFaq,
            }}
        >
            {children}
        </UseFaleConoscoContext.Provider>
    );
}

export function useFaleConosco() {
    const context = useContext(UseFaleConoscoContext);
    return context;
}
