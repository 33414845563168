import exclamation from "../../../assets/img/exclamation.svg"
import { UseModal } from "../../../Hook/Usemodal";
import { SecondStrikeContainer } from "./styles";

export const SecondStrikeModal = () => {
    const { handleClose, setState, state } = UseModal();

    function handleCloseModal() {
        handleClose("avisoSenhaInvalida");
    }

    function handleForgotPassword() {
        setState({
            ...state,
            esqueciSenha: true,
            avisoSenhaInvalida: false,
        });
    }

    return (
        <SecondStrikeContainer>
            <img className="img-close" src={exclamation} alt="" />

            <h1>{"ATENÇÃO!\nDados incorretos"}</h1>
            <p>
                <b>
                    Na próxima tentativa incorreta, seu acesso será bloqueado.{" "}
                </b>
                Caso não se lembre da senha, por favor, redefina-a.
            </p>

            <button className="primary" onClick={handleForgotPassword}>
                Redefinir Senha
            </button>

            <button onClick={handleCloseModal}>voltar</button>
        </SecondStrikeContainer>
    );
};
