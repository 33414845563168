import { Container } from "./styles";
import { TableWhiteList } from "./table";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";
export function Content() {
    const { tabs } = useWhiteList();
    return (
        <Container>
            <div
                className={`container-tab remove-padding ${
                    tabs[0]?.active ? "active" : ""
                }`}
            >
                {tabs[0]?.active && <TableWhiteList />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[1]?.active ? "active" : ""
                }`}
            >
                {tabs[1]?.active && <TableWhiteList />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[2]?.active ? "active" : ""
                }`}
            >
                {tabs[2]?.active && <TableWhiteList />}
            </div>
        </Container>
    );
}
