import styled from "styled-components";
import { Form } from "formik";

export const Container = styled.div`
    overflow-y: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .container-tabs {
        width: 90%;
        margin: 0 auto;
        .container-loading {
            position: static;
        }
    }
`;

export const FormCpfStyles = styled(Form)`
    display: flex;
    flex-direction: column;
    padding-bottom: 215px;
    .container-input {
        margin-bottom: 289px;
    }
`;

export const FormConfirmaUserStyles = styled(Form)`
    display: flex;
    flex-direction: column;
    padding-bottom: 215px;
    height: 705px;
`;

export const StylesHeaderUser = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 37px;
    .container-img {
        margin-right: 22px;
        img {
            width: 65px;
            height: 65px;
            border-radius: 50%;
        }
    }

    .container-text {
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primary};
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }
`;

export const UsuarioNaoEncontradoStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
        margin-bottom: 200px;
    }

    button {
        margin-bottom: 75px;
    }
`;

export const AlertaStyles = styled.div`
    .container-alerta {
        margin-top: 38px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 200px;
        .container-img {
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 22px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            /* or 20px */

            /* Gray/02 */
            width: 208px;
            color: #767b7f;
        }
    }

    button {
        margin-bottom: 75px;
    }
`;

export const ContainerCardsTransferir = styled.div`
    img {
        display: block;
        margin: 0 auto;
    }
`;
