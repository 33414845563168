import { ChangeEvent, useState } from "react";
import { Formik, Field, ErrorMessage, FormikProps } from "formik";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { Documento, documento, validationDocumento } from "./config";
import { Button } from "../Button";
import { FormCpfStyles } from "./styles";
import { Text } from "alisson-application";
import { maskCPF_CNPJ } from "../../../util/helper";

export function FormCpf() {
    const { nextPasso, setDados } = usePassos();
    const [loading, setLoading] = useState(false);
    const { consultaUsuario } = useUser();

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={documento}
            validationSchema={validationDocumento}
            onSubmit={async (values, actions) => {
                setLoading(true);

                const response = await consultaUsuario(
                    values.document.replace(/\D/g, "")
                );

                if (response.status === 200) {
                    setDados(response.data);
                    nextPasso(2);
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<Documento>) => {
                const { setFieldValue, values } = props;
                const { document } = values;

                return (
                    <FormCpfStyles>
                        <Text textAlign="center" mb="39px">
                            Para transferir, digite abaixo o CPF de quem vai
                            receber o valor.
                        </Text>
                        <div className={`container-input user`}>
                            <Field
                                placeholder="CPF/CNPJ"
                                name="document"
                                type="tel"
                                autoComplete="organization"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    const document = maskCPF_CNPJ(
                                        e.target.value
                                    );

                                    if (document.length <= 18) {
                                        setFieldValue("document", document);
                                    }
                                }}
                                value={document}
                            />
                        </div>
                        <ErrorMessage name="documento" component="span" />

                        <Button
                            type="submit"
                            spinerColor="primary"
                            isLoading={loading}
                            disabled={!props.isValid}
                            spiner="BallTriangle"
                            className="login"
                            mt="auto"
                        >
                            AVANÇAR
                        </Button>
                    </FormCpfStyles>
                );
            }}
        </Formik>
    );
}
