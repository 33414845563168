import styled from "styled-components";

export const Container = styled.div`
    overflow-y: scroll;
    .linha-unica {
        grid-template-columns: 1fr !important;
    }

    img {
        &.img-close {
            position: absolute;
            top: -87px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
            z-index: 99;
        }
    }

    .content-tarifa {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        position: relative;
        padding: 60px 0 36px;
        &.mobile {
            .container-itens {
                height: 480px;
                overflow-y: scroll;
                background-color: #fff;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 5px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(89, 92, 118, 0.5);
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }

        .container-itens {
            ul {
                max-height: 625px;
                overflow: auto;
                padding: 0 5%;
                scrollbar-width: thin;

                li {
                    display: grid;
                    grid-template-columns: 216px 1fr;
                    padding: 16px 0px;
                    border-bottom: 1px solid #e8e8e8;
                    &:last-child {
                        border-bottom: 1px solid transparent;
                    }
                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 140%;
                        letter-spacing: 0.08em;
                        text-transform: uppercase;
                        color: ${(props) => props.theme.colors.primaryBright};
                    }

                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 140%;
                        letter-spacing: 0.04em;
                        text-transform: uppercase;
                        color: ${(props) => props.theme.colors.primaryDark};
                        text-align: right;
                    }
                }
            }
        }
    }
`;
