import styled from "styled-components";

interface IResendProps {
    $theme?: "primary" | "secondary";
}

export const ResendCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 327px;

    margin: 50px 0 0;
`;

export const ResendFields = styled.section<IResendProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
        text-align: start;
    }

    fieldset {
        border-top: 0px solid;
        text-align: center;

        legend {
            padding: 0 8px;
            font-family: Oxanium;
            text-transform: capitalize;
            font-size: 12px;

            top: -10px;
            right: calc(-50% + 16px);
            position: relative;
            width: max-content;
            background: #fff;
        }
    }

    button {
        background-color: #57b847;
    }
`;

export const UnlockSmsButton = styled.button`
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 20px 0 0;
    cursor: pointer;

    span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        line-height: 1;
    }

    svg path {
        fill: #000;
    }
`;

export const NeedHelp = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    cursor: pointer;
    color: #000;

    span {
        font-size: 12px;
        font-family: Oxanium;
        font-weight: 900;
        margin: 0 0 3px;
    }

    svg {
        margin-right: 10px;

        path {
            stroke: #000;
        }
    }
`;
