import { AxiosResponse } from "axios";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { CartaoAvulso } from "../../components/Mobile/CartaoAvulso/config";
import { api } from "../../services";
import {
    base64Hash,
    formatDateIso,
    getCampanha,
    getFirsDayMonth,
    getLastDayMonth,
    getLocalStore,
} from "../../util/helper";
import { HistoryProps } from "../types";
import { UseModal } from "../Usemodal";
import { useStateUser } from "./UseStateUser";

import { Produto, UserProps, useUser } from "./UseUser";
interface UseCartoesProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseCartoesData {
    cartoes: Cartoes[];
    storeCartao(cartoes: StoreCartao): Promise<any>;
    resgate(values: StoreResgate): Promise<AxiosResponse>;
    getExtrato: (id: number, date?: Date) => Promise<any>;
    // getSaldo: (id: number) => Promise<AxiosResponse<Saldo>>;
    getSaldo(id: number): Promise<void>;
    saldo: string;
    loadingSaldo: boolean;
    setSaldo: (saldo: string) => void;
    // ativar(cartao: IAtivarCartao): Promise<AxiosResponse<ResponseActiveCard>>;
    ativar(cartao: CartaoAvulso): Promise<AxiosResponse<any>>;
    bloquear(): Promise<AxiosResponse>;
    desbloquear(): Promise<AxiosResponse>;
    cancelarCartao(values: Values): Promise<AxiosResponse<any>>;
    alterarSenha(values: AlterarSenha): Promise<AxiosResponse>;
    smsCartaoToggle(): Promise<void>;
    tranferirCartao(values: Transferir): Promise<AxiosResponse>;
    dadosCartao(id: number): Promise<Cartoes>;
    newOrder(list_order: ListOrder[]): Promise<void>;
    setCartaoId(id: number): void;
    cartaoId: number | null;

    cartao: Cartoes | undefined;

    extratos: Extrato[];
    loadingExtrato: boolean;
}

export interface Values {
    hash: string;
    password: string;
}

export interface Cartoes {
    id: number;
    requested_at: string | null;
    user_id: number;
    tipo: string;
    nome_completo: string;
    cpf: string;
    celular: string;
    data_nascimento: string;
    nome_mae: string;
    nome_cartao: string;
    status: string;
    acg_account: string;

    acg_account_id: string;
    acg_account_card_id: string;
    acg_sms_service: number;
    created_at: string;
    updated_at: null | string;
    first_active: number;
    resgates_count: number;
    acg_account_card_cvv: string;
    acg_account_card_expiration: string;
    acg_account_card_number: string;
    acg_account_card_last4digits: string;
    produto: Produto | null;
    campanha_id: number | null;
    list_order: number;
    user?: UserProps;
    acg_tracking_id: string | null;
    first_update_password: 0;
}

export interface Saldo {
    saldo: string;
}

interface StoreCartao {
    tipo: string;
    nome_completo: string;
    cpf: string;
    celular: string;
    data_nascimento: string;
    nome_mae: string;
    nome_cartao: string;
}

interface StoreResgate {
    cartao_id: number;
    valor: number;
}

interface ErrorStoreCartao {
    message: string;
    success: boolean;
}

export interface IAtivarCartao {
    cartao_id: number;
    password: string;
    cvv: string;
}

interface ResponseActiveCard {
    cartao?: {
        id: number;
        user_id: number;
        campanha_id: number;
        tipo: string;
        nome_completo: null | string;
        cpf: null | string;
        celular: null | string;
        data_nascimento: null | string;
        nome_mae: null | string;
        nome_cartao: null | string;
        status: string;
        first_active: number;
        produto_id: number;
        acg_sms_service: number;
        acg_produto_id: string;
        acg_comercial_origin_id: string;
        acg_account: string;
        acg_account_id: string;
        acg_account_card_id: null | string;
        acg_account_card_number: null | string;
        acg_account_card_last4digits: null | string;
        acg_account_card_cvv: null | string;
        acg_account_card_expiration: null | string;
        acg_tracking_id: null | string;
        first_update_password: number;
        activated_at: null | string;
        requested_at: null | string;
        canceled_at: null | string;
        last_delivery_log: null | string;
        created_at: string;
        updated_at: string;
        deleted_at: null | string;
        acg_debug: number;
        resgates_count: number;
    };
    success: true;
    operation: string;
}

export interface AlterarSenha {
    password: string;
}

export interface Extrato {
    event: string;
    cid: string;
    eventDate: string;
    isCredit: boolean;
    principalAmount: number;
    currencyCode: string;
    details: string;
    responsible?: string;
    offsetAccount?: number;
    eventId?: string;
    reversed: boolean;
    mcg: number;
}

export interface ExtratoBoleto {
    allowChangeValue: number;
    assignor: string;
    billDocument: string;
    billName: string;
    cartao_id: number;
    created_at: string;
    customerDescription: string;
    digitable: string;
    discountValue: string;
    documentPayer: string;
    documentRecipient: string;
    dueDate: string;
    file: string;
    file_content: string;
    fineValueCalculated: string;
    id: number;
    interestValueCalculated: string;
    log_consult: string;
    log_payed: string;
    maxValue: string;
    minValue: string;
    payDate: string;
    payer: string;
    protocolId: string;
    recipient: string;
    status: number;
    totalUpdated: string;
    type: number;
    updated_at: string;
    user_id: number;
    value: string;
}

interface ResponseStoreCartao {
    success: boolean;
    message: string;
}

interface Transferir {
    cartao_from: number;
    cartao_to: number;
    value: number;
    password: string;
}

interface ListOrder {
    id: number;
    order: number;
}

const UseCartoesContext = createContext<UseCartoesData>({} as UseCartoesData);

export function UseCartoesProvider(props: UseCartoesProviderProps) {
    const { children } = props;
    const { user, getUser, codeWhatsapp } = useUser();
    const { setForceActivateAccountLogin } = useStateUser();
    const [cartoes, setCartoes] = useState<Cartoes[]>([]);
    const [cartao, setCartao] = useState<Cartoes | undefined>(undefined);
    const [cartaoId, setCartaoId] = useState<number | null>(null);

    const [saldo, setSaldo] = useState("");
    const [loadingSaldo, setLoadingSaldo] = useState(false);
    const { handleOpen, handleClose, closeAllModais } = UseModal();

    const [extratos, setExtratos] = useState<Extrato[]>([]);
    const [loadingExtrato, setLoadingExtrato] = useState(false);
    const history = useHistory<HistoryProps>();
    // const width = window.screen.width;

    async function storeCartao(cartoes: StoreCartao) {
        const campanha_id = getLocalStore().campanha_user?.id;
        try {
            const response = await api.post<ErrorStoreCartao>("/cartao", {
                ...cartoes,
                campanha_id,
            });

            await getCartoes();
            await getUser();

            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function smsCartaoToggle() {
        try {
            const response = await api.post<ErrorStoreCartao>(
                `/cartao/${cartao?.id}/sms`
            );

            await getCartoes();
            toast.success("Sucesso!");
            handleClose("confirmaToggleSms");
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function resgate(values: StoreResgate) {
        const { campanha_user } = getLocalStore();

        try {
            const response = await api.post<ResponseStoreCartao>(
                "/resgate",
                {
                    ...values,
                    campanhas: [campanha_user?.id],
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );

            await getExtrato(values.cartao_id);

            return response;
        } catch (error: any) {
            return error?.response;
        }
    }
    async function dadosCartao(id: number) {
        try {
            const response = await api.get<Cartoes>(`cartao/${id}`);
            return response.data;
        } catch (error: any) {
            return {} as Cartoes;
        }
    }

    // async function ativar(cartao: IAtivarCartao) {
    //     try {
    //         const cryptedData = crypted(
    //             `password.${cartao.password}`,
    //             `cvv.${cartao.cvv}`
    //         );

    //         const response = await api.post<ResponseActiveCard>(
    //             "cartao/activate",
    //             { ...cartao, ...cryptedData }
    //         );

    //         await getCartoes();
    //         await getUser();
    //         return response;
    //     } catch (error: any) {
    //         return error?.response;
    //     }
    // }

    async function ativar(cartao: any) {
        const id = getLocalStore().campanha_user.id;

        if (!id) return history.goBack();

        try {
            const response = await api.post<ResponseActiveCard>(
                `cartao/activate`,
                {
                    ...cartao,
                    campanha_id: id,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );

            // const updateCampanha = {
            //     ...getCampanha(),
            //     force_activate_account_login: 0,
            // };

            // localStorage.setItem("campanha", JSON.stringify([updateCampanha]));
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function bloquear() {
        const id = cartaoId ? cartaoId : history.location.state?.id;
        if (!id) {
            history.goBack();
            return;
        }

        try {
            const response = await api.post("cartao/block", {
                cartao_id: id,
                // ...values,
            });
            await getCartoes();
            setCartaoId(id);
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function desbloquear() {
        const id = cartaoId ? cartaoId : history.location.state?.id;
        if (!id) {
            history.goBack();
            return;
        }

        try {
            const response = await api.post("cartao/unblock", {
                cartao_id: id,
                // ...values,
            });
            await getCartoes();
            setCartaoId(id);
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function alterarSenha(values: any) {
        const id = cartaoId ? cartaoId : history.location.state?.id;
        if (!id) {
            history.goBack();
            return;
        }
        const headers = {
            "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
        };
        try {
            const response = await api.post(
                "cartao/update-password",
                {
                    ...values,
                    password: values.password,
                    confirm_password: values.confirm_password,
                    cartao_id: id,
                },
                {
                    headers,
                }
            );
            await getCartoes();
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function cancelarCartao(values: any) {
        const id = cartaoId ? cartaoId : history.location.state?.id;
        if (!id) {
            history.goBack();
            return;
        }

        try {
            const response = await api.post<AxiosResponse>(
                "cartao/cancel",
                {
                    password: values.password,
                    cartao_id: id,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );
            await getCartoes();
            return response;
        } catch (error: any) {
            // toast.error("Erro ao cancelar cartão");
            console.log(error);
            return error?.response;
        }
    }

    async function tranferirCartao(values: Transferir) {
        try {
            const response = await api.post(
                "cartao/transferencia",
                {
                    ...values,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(codeWhatsapp)}`,
                    },
                }
            );

            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    const getExtrato = useCallback(async (id: number, data?: Date) => {
        setLoadingExtrato(true);
        const primeiroDiaDoMes = formatDateIso(getFirsDayMonth(data))
            .split(" - ")[0]
            .split(".")
            .reverse()
            .join("-");
        const ultimoDiaDoMes = formatDateIso(getLastDayMonth(data))
            .split(" - ")[0]
            .split(".")
            .reverse()
            .join("-");

        try {
            const { data } = await api.get<Extrato[]>(
                `/extrato/${id}?start=${primeiroDiaDoMes}&end=${ultimoDiaDoMes}`
            );
            setExtratos(
                data.sort(
                    (a, b) =>
                        new Date(b.eventDate).getTime() -
                        new Date(a.eventDate).getTime()
                )
            );
            setLoadingExtrato(false);
        } catch (error: any) {
            setLoadingExtrato(false);
            setExtratos([] as Extrato[]);
            return error?.response;
        }
    }, []);

    async function newOrder(list_order: ListOrder[]) {
        try {
            const response = await api.patch("cartao/list-order", {
                list_order: list_order,
            });
            console.log(response);
            toast.success("Ordem alterada com sucesso");
        } catch (error) {
            toast.error("Erro ao alterar ordem");
            console.log(error);
        }
    }

    const getCartoes = useCallback(async () => {
        if (!user.id) return;
        const { campanha_user } = getLocalStore();

        try {
            const { data } = await api.get<Cartoes[]>(
                `/cartao?campanhas[0]=${campanha_user?.id}`
            );

            // if (
            //     data.filter((item) => item.status === "PROCESSANDO").length > 0
            // ) {
            //     return;
            // }

            const hasCartaoAtivo = data.find(
                (item) => item.status === "ATIVANDO"
            );

            if (hasCartaoAtivo) {
                closeAllModais();
                handleOpen("ativarCartaoAutomatico");
            } else {
                if (
                    getCampanha()?.force_activate_account_login === 1 &&
                    data.length === 0
                ) {
                    handleOpen("ativarCartaoAvulsoSubHeader");
                    setForceActivateAccountLogin(true);
                } else {
                    setForceActivateAccountLogin(false);
                }
            }

            setCartoes(
                data.map((item) => {
                    const haslast4digits = item.acg_account_card_last4digits
                        ? `**** **** **** ${item.acg_account_card_last4digits}`
                        : "-";
                    return {
                        ...item,
                        acg_account_card_last4digits: haslast4digits,
                    };
                })
            );

            if (cartaoId) {
                setCartaoId(cartaoId);
                return;
            }

            if (data.length) {
                setCartaoId(data[0].id);
            }
        } catch (error: any) {
            setCartoes([] as Cartoes[]);
            return error?.response;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    async function getSaldo(id: number) {
        if (!cartaoId) return;
        setLoadingSaldo(true);
        try {
            const dados = await api.get<Saldo>(
                `cartao/${id ? id : cartaoId}/saldo`
            );

            setSaldo(dados.data.saldo);
            setLoadingSaldo(false);
        } catch (error: any) {
            setLoadingSaldo(false);
        }
    }

    useEffect(() => {
        getCartoes();
    }, [getCartoes]);

    useEffect(() => {
        const id = cartaoId ? cartaoId : history.location.state?.id;

        if (id) {
            setCartao(cartoes.find((cartao) => cartao.id === id));
        }
    }, [cartoes, cartaoId, history.location.state?.id]);

    // useEffect(() => {
    //     if (cartaoId) {
    //         getExtrato(cartaoId);
    //     }
    // }, [getExtrato, width, cartaoId]);

    return (
        <UseCartoesContext.Provider
            value={{
                cartao,
                cartoes,
                extratos,
                getExtrato,
                loadingExtrato,
                storeCartao,
                resgate,
                ativar,
                bloquear,
                desbloquear,
                cancelarCartao,
                alterarSenha,
                setCartaoId,
                cartaoId,
                getSaldo,
                dadosCartao,
                saldo,
                setSaldo,
                smsCartaoToggle,
                tranferirCartao,
                loadingSaldo,
                newOrder,
            }}
        >
            {children}
        </UseCartoesContext.Provider>
    );
}

export function UseCartoes() {
    const context = useContext(UseCartoesContext);
    return context;
}
