import ImgInformativo from "../../assets/img/regras-seguranca.png";
import close from "../../assets/img/x-branco.svg";
import { Container } from "./styles";

interface ModalInformativoProps {
    handleClose: () => void;
}

export function ModalInformativo(props: ModalInformativoProps) {
    const { handleClose } = props;

    return (
        <Container>
            <div className="container-img">
                <button onClick={handleClose}>
                    <img className="close" src={close} alt="" />
                </button>
                <img src={ImgInformativo} className="img-banner" alt="" />
            </div>
        </Container>
    );
}
