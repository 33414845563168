import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 59px;
    border-bottom: 1px solid #e1e2e3;
    position: relative;
    cursor: pointer;
    img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.primary};
    }

    @media (max-width: 768px) {
        margin: 0 auto;
    }
`;
