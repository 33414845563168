import Mask from "react-input-mask";
import { motion } from "framer-motion";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { maskDateRegex, convertDateDB } from "../../../util/helper";
import { api } from "../../../services";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
// import { CheckBox } from "../CheckBox";
import Select, { options } from "../../NovoSelect";
import imgOlho from "../../../assets/img/olho.svg";

import { register, Register, validationRegister } from "./config";
import { toast } from "react-toastify";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { CheckPassword } from "../../CheckPassword";

interface FormRegisterProps {
    setRegister?: (value: boolean) => void;
}

interface ValidadeDocuments {
    document: string;
    nome_da_mae: string;
    nome_cpf: string;
    data_nascimento: string;
    tries?: number;
}

interface IpreRegister {
    tipo: string;
    name: string;
    document: string;
    apelido: string;
    data_nascimento: string;
    genero: string;
    nome_cpf: string;
    nome_da_mae: string;
    email: string;
    phone_number: string;
    password: string;
    password_confirmation: string;
}

export function CompleteRegister(props: FormRegisterProps) {
    const { setRegister } = props;
    const { nextPasso } = usePassos();
    const { setState: setStateModal, state: stateModal } = UseModal();
    const { setState, state } = useFormLogin();
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preRegisterState, setPreRegisterState] = useState<IpreRegister>(
        {} as IpreRegister
    );
    const [hasDifDate, setHasDifDate] = useState(false);
    const [hasDifName, setHasDifName] = useState(false);
    const [hasDifMae, setHasDifMae] = useState(false);

    const [temValue, setTemValue] = useState({} as Register);

    const genero: options[] = [
        {
            label: "Masculino",
            value: 1,
        },
        {
            label: "Feminino",
            value: 2,
        },
        {
            label: "Outro",
            value: 3,
        },
    ];

    async function checkDocs(values: ValidadeDocuments) {
        setLoading(true);
        try {
            const response = await api.post<ValidadeDocuments>(
                "validate-documents",
                { ...values }
            );
            setLoading(false);
            if (response.data.tries === 2) {
                await preCadastro(preRegisterState);
                await checkDocs(values);
            }
            return response;
        } catch (error: any) {
            console.log(error);
            setLoading(false);
            return error;
        }
    }

    async function preCadastro(values: IpreRegister) {
        setLoading(true);
        try {
            const response = await api.post<IpreRegister>("pre-register", {
                ...values,
            });
            setPreRegisterState(values);
            setLoading(false);
            return response;
        } catch (error: any) {
            console.log(error);
            setLoading(false);
            return error.response;
        }
    }

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={{ ...register, ...state }}
            validationSchema={validationRegister}
            onSubmit={async (values, actions) => {
                if (values.nome_da_mae.trim().indexOf(" ") === -1) {
                    actions.setFieldError(
                        "nome_da_mae",
                        "Preencha o nome completo"
                    );
                    return;
                }

                if (values.nome_cpf.trim().indexOf(" ") === -1) {
                    actions.setFieldError(
                        "nome_igual_cpf",
                        "Preencha o nome completo"
                    );
                    return;
                }

                const preCadastroResponse = await preCadastro({
                    apelido: values.apelido,
                    data_nascimento: convertDateDB(values.data_nascimento),
                    document: state.document,
                    email: values.email,
                    genero: values.genero,
                    name: values.name,
                    nome_cpf: values.nome_cpf,
                    nome_da_mae: values.nome_da_mae,
                    phone_number: "55" + values.phone_number.replace(/\D/g, ""),
                    tipo: state.document.length <= 11 ? "fisica" : "juridica",
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                });

                if (preCadastroResponse.status !== 200) return;

                const response = await checkDocs({
                    data_nascimento: convertDateDB(values.data_nascimento),
                    document: values.document,
                    nome_cpf: values.nome_cpf,
                    nome_da_mae: values.nome_da_mae,
                });

                if (response.data.tries === 2) {
                    if (setRegister) setRegister(true);
                    setState({
                        ...state,
                        ...values,
                    });

                    nextPasso(2);
                    return;
                }

                const validadteUser = response?.data || {};
                delete validadteUser.tries;
                const arrValidateUser = Object.entries(validadteUser);

                const hasError = arrValidateUser.some((item) => !item[1]);
                let arrError: any = [];
                if (hasError) {
                    let erroMessage: any = {};
                    setTemValue(values);
                    arrValidateUser.forEach((item) => {
                        switch (item[0]) {
                            case "document":
                                if (!item[1]) {
                                    arrError.push("CPF");
                                    actions.setFieldError(
                                        "document",
                                        "CPF inválido"
                                    );
                                    erroMessage[item[0]] = "CPF inválido";
                                }
                                break;
                            case "data_nascimento":
                                if (!item[1]) {
                                    arrError.push("DATA DE NASCIMENTO");
                                    erroMessage[item[0]] =
                                        "Data de nascimento incorreta";
                                    setHasDifDate(true);
                                }
                                break;
                            case "nome_da_mae":
                                if (!item[1]) {
                                    arrError.push("NOME DA MÃE");
                                    erroMessage[item[0]] =
                                        "Nome da mãe não corresponde ao cadastro do CPF";
                                    setHasDifName(true);
                                }
                                break;
                            case "nome_cpf":
                                if (!item[1]) {
                                    arrError.push("NOME");
                                    erroMessage[item[0]] =
                                        "Nome não corresponde ao cadastro do CPF";
                                    setHasDifMae(true);
                                }
                                break;

                            default:
                                break;
                        }
                    });

                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setStateModal({
                        ...stateModal,
                        errorGeral: {
                            active: true,
                            title: "DADOS INCORRETOS!",
                            message: `CORRIJA OS CAMPOS DESTACADOS EM VERMELHO ( CONFORME DOCUMENTO RG, CPF OU CNH) E ENVIE NOVAMENTE.`,
                        },
                    });
                    return;
                }

                if (setRegister) setRegister(true);
                setState({
                    ...state,
                    ...values,
                });

                nextPasso(2);
            }}
        >
            {(props: FormikProps<Register>) => {
                const {
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                } = props;

                const { phone_number, confirm_phone_number, name } = values;

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.1,
                            }}
                            className={`container-input user ${
                                state.name ? "active disabled" : ""
                            }`}
                        >
                            <Field
                                name="name"
                                value={name}
                                placeholder="NOME"
                            />
                        </motion.div>
                        <ErrorMessage name="name" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.1,
                            }}
                            className={`container-input user active disabled`}
                        >
                            <Field
                                name="name"
                                value={values.document}
                                placeholder="CPF/CNPJ"
                            />
                        </motion.div>
                        <ErrorMessage name="document" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.3,
                            }}
                            className={`container-input ${
                                props.errors.apelido && props.touched.apelido
                                    ? "error"
                                    : ""
                            } user`}
                        >
                            <Field name="apelido" placeholder="APELIDO" />
                        </motion.div>
                        <ErrorMessage name="apelido" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.4,
                            }}
                            className={`container-input ${
                                (props.errors.data_nascimento &&
                                    props.touched.data_nascimento) ||
                                (temValue?.data_nascimento && hasDifDate)
                                    ? "error"
                                    : ""
                            } calendario`}
                        >
                            <Field
                                name="data_nascimento"
                                placeholder="DATA DE NASCIMENTO"
                                type="tel"
                                value={values.data_nascimento}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (hasDifDate) {
                                        setHasDifDate(false);
                                    }
                                    if (e.target.value.length <= 10) {
                                        setFieldValue(
                                            "data_nascimento",
                                            maskDateRegex(e.target.value)
                                        );
                                    }
                                }}
                            />
                        </motion.div>
                        {hasDifDate ? (
                            <span>Data de nascimento incorreta</span>
                        ) : (
                            <></>
                        )}

                        <ErrorMessage name="data_nascimento" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.5,
                            }}
                            className={`container-input genero ${
                                props.errors.genero && props.touched.genero
                                    ? "error genero"
                                    : ""
                            }`}
                        >
                            <Select
                                tipo="user"
                                label="GÊNERO"
                                options={genero}
                                handleBlur={() =>
                                    setFieldTouched("genero", true)
                                }
                                onChange={(values) =>
                                    setFieldValue(
                                        "genero",
                                        values.label.toUpperCase()
                                    )
                                }
                            />
                        </motion.div>
                        <ErrorMessage name="genero" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.6,
                            }}
                            className={`container-input copiar  ${
                                (props.errors.nome_cpf &&
                                    props.touched.nome_cpf) ||
                                (temValue?.nome_cpf && hasDifName)
                                    ? "error"
                                    : ""
                            } user`}
                        >
                            <Field
                                name="nome_cpf"
                                placeholder="NOME COMPLETO (IDÊNTICO AO CPF/RG)"
                                value={values.nome_cpf}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (hasDifName) {
                                        setHasDifName(false);
                                    }
                                    const value = e.target.value.replace(
                                        /[^a-zA-Z0-9 ]/g,
                                        ""
                                    );

                                    setFieldValue("nome_cpf", value);
                                }}
                            />
                        </motion.div>
                        {hasDifName ? (
                            <span>Nome não corresponde ao cadastro do CPF</span>
                        ) : (
                            <> </>
                        )}

                        <ErrorMessage name="nome_cpf" component="span" />

                        {/* <Box as="li" pl="23px">
                            <CheckBox
                                label="Usar nome do cadastro"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFieldValue("nome_cpf", values.name);
                                    } else {
                                        setFieldValue("nome_cpf", "");
                                    }
                                }}
                            />
                        </Box> */}

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.6,
                            }}
                            className={`container-input ${
                                (props.errors.nome_da_mae &&
                                    props.touched.nome_da_mae) ||
                                (temValue?.nome_da_mae && hasDifMae)
                                    ? "error"
                                    : ""
                            } user`}
                        >
                            <Field
                                name="nome_da_mae"
                                placeholder="NOME DA MÃE (IDÊNTICO AO CPF/RG)"
                                value={values.nome_da_mae}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (hasDifMae) {
                                        setHasDifMae(false);
                                    }
                                    const value = e.target.value.replace(
                                        /[^a-zA-Z0-9 ]/g,
                                        ""
                                    );
                                    setFieldValue("nome_da_mae", value);
                                }}
                            />
                        </motion.div>
                        {hasDifMae ? (
                            <span>
                                Nome da mãe não corresponde ao cadastro do CPF
                            </span>
                        ) : (
                            <> </>
                        )}
                        <ErrorMessage name="nome_da_mae" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.7,
                            }}
                            className={`container-input contato ${
                                props.errors.email && props.touched.email
                                    ? "error"
                                    : ""
                            }`}
                        >
                            <Field
                                name="email"
                                type="text"
                                placeholder="E-MAIL"
                                onCut={(e: KeyboardEvent<HTMLInputElement>) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }
                                    e.preventDefault();
                                }}
                                onCopy={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }

                                    e.preventDefault();
                                }}
                                onPaste={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }
                                    e.preventDefault();
                                }}
                            />
                        </motion.div>
                        <ErrorMessage name="email" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.8,
                            }}
                            className={`container-input ${
                                props.errors.email_confirmation &&
                                props.touched.email_confirmation
                                    ? "error"
                                    : ""
                            } contato`}
                        >
                            <Field
                                name="email_confirmation"
                                type="text"
                                placeholder="CONFIRMAR E-MAIL"
                                onCut={(e: KeyboardEvent<HTMLInputElement>) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                                onCopy={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                                onPaste={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                            />
                        </motion.div>
                        <ErrorMessage
                            name="email_confirmation"
                            component="span"
                        />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.9,
                            }}
                            className={`container-input ${
                                props.errors.phone_number &&
                                props.touched.phone_number
                                    ? "error"
                                    : ""
                            } celular`}
                        >
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder="CELULAR"
                                name="phone_number"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={phone_number}
                            />
                        </motion.div>
                        <ErrorMessage name="phone_number" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className={`container-input ${
                                props.errors.confirm_phone_number &&
                                props.touched.confirm_phone_number
                                    ? "error"
                                    : ""
                            } celular`}
                        >
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder="CONFIRMAR CELULAR"
                                name="confirm_phone_number"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={confirm_phone_number}
                            />
                        </motion.div>
                        <ErrorMessage
                            name="confirm_phone_number"
                            component="span"
                        />

                        {/* <Text
                            as="small"
                            className="informacao-senha"
                            m="17px 0px 32px 0px"
                        >
                            Sua senha deve ser numérica e possuir 8 dígitos.
                        </Text> */}

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.1,
                            }}
                            className={`container-input olho ${
                                props.errors.password && props.touched.password
                                    ? "error"
                                    : ""
                            } senha`}
                        >
                            <Field
                                name="password"
                                type={`${olhoSenha ? "text" : "password"}`}
                                placeholder="CRIAR SENHA DE ACESSO"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </motion.div>

                        <ErrorMessage name="password" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.2,
                            }}
                            className={`container-input olho ${
                                props.errors.password_confirmation &&
                                props.touched.password_confirmation
                                    ? "error"
                                    : ""
                            } senha`}
                        >
                            <Field
                                name="password_confirmation"
                                type={`${olhoSenha ? "text" : "password"}`}
                                placeholder="CONFIRMAR SENHA"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </motion.div>
                        <ErrorMessage
                            name="password_confirmation"
                            component="span"
                        />

                        <CheckPassword values={values.password} />

                        <motion.div
                            whileHover={{ scale: 1 }}
                            whileTap={{ scale: 0.5 }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                disabled={!props.isValid}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                AVANÇAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
