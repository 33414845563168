import { Text } from "alisson-application";
import ImgError from "../../assets/img/modal-error.svg";
import { UseModal } from "../../Hook/Usemodal";
import { Button } from "../Mobile/Button";
import { Container } from "./styles";

export function FalhaAoPagarBoleto() {
    const { closeAllModais, state } = UseModal();

    return (
        <Container>
            <div className="container">
                <img className="img-close" src={ImgError} alt="" />

                <Text
                    fontSize="md"
                    textAlign="center"
                    color="failure"
                    fontWeight="bold"
                >
                    FALHA AO REALIZAR PAGAMENTO
                </Text>

                <Text
                    fontSize="xs"
                    textAlign="center"
                    color="primary"
                    mb="39px"
                >
                    {state.boletoErroMsg}
                </Text>

                <Button
                    onClick={() => {
                        closeAllModais();
                    }}
                >
                    OK, ENTENDI
                </Button>
            </div>
        </Container>
    );
}
