import { Box, Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState, memo } from "react";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { usePassos } from "../../../Hook/UsePassos";
import {
    objCartaoes,
    formataMoney,
    formatCurrentMoney,
    tempoInicioPlataforma,
    tempoFimPlataforma,
} from "../../../util/helper";
import { Card } from "../../Mobile/Cartoes/card";
import { CheckBox } from "../../Mobile/CheckBox";
import { Button } from "../../Mobile/Button";
import { ContainerCards, CardTransferirStyles } from "./styles";
import { Loader } from "../../Loader";
import { UseModal } from "../../../Hook/Usemodal";
import { api } from "../../../services";

interface UpdateCartoes {
    saldo: string;
    id: number;
    price: string;
    number: string;
    date: string;
    type: string;
    status: string;
    color: string;
    active: boolean;
}

function CardTransferir() {
    const { cartoes, cartao, cartaoId } = UseCartoes();
    const { setDados, nextPasso } = usePassos();
    const { setState, state } = UseModal();
    const [updateCards, setUpdateCards] = useState<UpdateCartoes[]>([]);
    const [value, setValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const width = window.screen.width;
    const history = useHistory();
    const [transferirCardActive, setCardActive] = useState<UpdateCartoes>(
        {} as UpdateCartoes
    );
    const [transferirCurrentCard, setCurrentCard] = useState<UpdateCartoes>(
        {} as UpdateCartoes
    );
    const cards = objCartaoes(cartoes);

    const fetchData = useCallback(async () => {
        setLoading(true);
        if (cartao?.id) {
        }

        const cardsActive = cards.filter((item) => item.status === "ATIVO");
        return await Promise.all(
            cardsActive.map(async (item) => {
                const response = await api.get<{ saldo: string }>(
                    `cartao/${item.id}/saldo`
                );
                const saldo = response?.data?.saldo;
                if (item.id === cartaoId) {
                    if (cartao?.id) {
                        const card = objCartaoes([{ ...cartao }])[0];

                        setCardActive({ ...card, saldo });
                    }
                }
                return { ...item, saldo: saldo };
            })
        )
            .then((result) => {
                const checkrequest = result.every((item) => item.saldo);

                if (!checkrequest) {
                    setState({
                        ...state,
                        transferir: false,
                        errorGeral: {
                            active: true,
                            title: "Ops",
                            message:
                                "Algo de errado aconteceu, tente novamente!",
                        },
                    });

                    return;
                }

                setUpdateCards(
                    result.filter(
                        (item) =>
                            item.id !== cartaoId && item.status === "ATIVO"
                    )
                );
                setLoading(false);
            })
            .catch((error) => {
                setState({
                    ...state,
                    transferir: false,
                    errorGeral: {
                        active: true,
                        title: "Ops",
                        message: "Algo de errado aconteceu, tente novamente!",
                    },
                });
                setLoading(false);

                return;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClick() {
        const tempSaldo = parseFloat(transferirCardActive.saldo);

        const tempValue = parseFloat(
            value.replace(/[^0-9!,]/g, "").replace(",", ".")
        );

        if (transferirCardActive?.status !== "ATIVO") {
            toast.error("Cartão bloqueado");
            return;
        }

        if (tempValue <= tempSaldo) {
            setDados({
                transferirCardActive,
                transferirCurrentCard,
                value: tempValue,
                subTotal: tempSaldo - tempValue,
            });

            nextPasso(2);
        } else {
            toast.error("Saldo insuficiente");
        }
    }

    function updateCardsActive(id: number) {
        const newCards = updateCards.map((item) => {
            if (item.id === id) {
                const update = { ...item, active: true };
                setCurrentCard(update);
                return update;
            }
            return { ...item, active: false };
        });
        setUpdateCards(newCards);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (width <= 480) {
            if (cartao?.status !== "ATIVO") {
                history.push("/detalhe-movimentacao");
                toast.error("Seu cartão não está ativado");
            }
        }
    }, [cartao, width, history]);

    if (loading) {
        return (
            <div className="container-loading">
                <Loader />
            </div>
        );
    }

    return (
        <CardTransferirStyles>
            {transferirCardActive?.id && (
                <div className="container-card-active">
                    <Card {...transferirCardActive} />
                    <div className="container-detalhes">
                        <h6>{transferirCardActive?.number}</h6>
                        <h5>SALDO DISPONÍVEL</h5>
                        <h3>
                            R$
                            {formatCurrentMoney(
                                parseFloat(transferirCardActive?.saldo)
                            )}
                        </h3>
                    </div>
                </div>
            )}

            <div
                className={`container-input valor ${
                    transferirCardActive?.status !== "ATIVO" ? "disabled" : ""
                }`}
            >
                <input
                    type="tel"
                    placeholder="R$ 0,00"
                    value={value ? value : ""}
                    disabled={transferirCardActive?.status !== "ATIVO"}
                    onChange={(e) => {
                        setValue(formataMoney(e.target.value));
                    }}
                />
            </div>

            <Text as="span">PARA QUAL CARTÃO DESEJA TRANSFERIR O VALOR?</Text>
            <Box mb="27px">
                {updateCards.map((item) => {
                    const { id, ...rest } = item;

                    return (
                        <ContainerCards
                            className={`${item.active ? "active" : ""}`}
                            onClick={() => updateCardsActive(item.id)}
                        >
                            <div className="container-card">
                                <Card {...rest} />
                            </div>
                            <div className="container-detalhes">
                                <h3>{item.number}</h3>
                                <p>
                                    R$
                                    {formatCurrentMoney(
                                        parseFloat(
                                            item.saldo ? item.saldo : "0"
                                        )
                                    )}
                                </p>
                            </div>

                            <div className="container-check">
                                <CheckBox label="" checked={item.active} />
                            </div>
                        </ContainerCards>
                    );
                })}
            </Box>

            <div className="container-alerta">
                <h3>Atenção!</h3>
                <p>
                    <strong>Transferência entre Cartões:</strong> até R$500,00
                    <br />
                    por semana, em dias úteis, das {tempoInicioPlataforma}h às{" "}
                    {tempoFimPlataforma}h.
                </p>
            </div>

            <Button
                disabled={
                    transferirCurrentCard?.id &&
                    parseFloat(value.replace(/[^\d]+/g, ""))
                        ? false
                        : true
                }
                onClick={handleClick}
            >
                AVANÇAR
            </Button>
        </CardTransferirStyles>
    );
}

export default memo(CardTransferir);
