import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useInfra } from "../Carga/Content/Infra/Hook";
import { useCredito } from "../Carga/Content/Credito/Hook";
import { useCartaoDeCredito } from "../Carga/Content/CartaoDeCredito/Hook";
import { Button } from "../Button/status";
import { HistoryProps } from "../Carga/types";
import { api } from "../../../services";

import imgSetaEsq from "../../../assets/img/seta-esq-sistema.svg";
import { HeaderStyles } from "./styles";
import { Config } from "./types";
import { toast } from "react-toastify";
import { Permissions } from "../Permission";

interface HeaderProps {
    error: boolean;
    empty: boolean;
    config: Config;
}

export function Header(props: HeaderProps) {
    const { error, empty, config } = props;
    const history = useHistory<HistoryProps>();
    const carga = history.location.state?.carga;
    const id = history.location.state?.id;
    const [loadingAprovar, setLoadingAprovar] = useState(false);
    const [loadingReprovar, setLoadingReprovar] = useState(false);
    const [loadingExportar, setLoadingExportar] = useState(false);

    const { aprove: infra, disApproval: infraDisApproval } = useInfra();
    const { aprove: credito, disApproval: creditdisApproval } = useCredito();
    const { aprove: cartoes, disApproval: cardsDisApproval } =
        useCartaoDeCredito();

    async function aprovarCarga() {
        setLoadingAprovar(true);

        if (carga === "infra" || carga === "nonames") {
            const response = await infra(id);
            if (response.status === 200) {
                history.goBack();
            }
        }

        if (carga === "users") {
            const response = await credito(id);
            if (response.status === 200) {
                history.goBack();
            }
        }

        if (carga === "cartoes") {
            const response = await cartoes(id);
            if (response.status === 200) {
                history.goBack();
            }
        }

        setLoadingAprovar(false);
    }

    async function reprovarCargar() {
        setLoadingReprovar(true);
        if (carga === "infra" || carga === "nonames") {
            const response = await infraDisApproval(id);
            if (response.status === 200) {
                history.goBack();
            }
        }

        if (carga === "users") {
            const response = await creditdisApproval(id);
            if (response.status === 200) {
                history.goBack();
            }
        }

        if (carga === "cartoes") {
            const response = await cardsDisApproval(id);
            if (response.status === 200) {
                history.goBack();
            }
        }

        setLoadingReprovar(false);
    }

    async function exportar() {
        setLoadingExportar(true);
        try {
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.get(`carga/${id}/logs-export`, {
                responseType: "blob",
            });

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
            setLoadingExportar(false);
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
            setLoadingExportar(false);
        }
    }

    return (
        <HeaderStyles>
            <div className="container-header">
                <button className="voltar" onClick={() => history.goBack()}>
                    <img src={imgSetaEsq} alt="" />
                    {config?.nome_campanha}
                </button>

                {config.status === "APROVADO" ? (
                    <></>
                ) : (
                    !empty && (
                        <div className="container-btns">
                            <Button
                                color="#fff"
                                background="#5DB356"
                                spiner="BallTriangle"
                                spinerColor="primary"
                                mr="32px"
                                onClick={exportar}
                                disabled={loadingExportar}
                                isLoading={loadingExportar}
                                width="100px"
                            >
                                exportar
                            </Button>
                            <Permissions role="carga.aprovar_valora">
                                <Button
                                    color="#EB001B"
                                    background="#FFE3E7"
                                    onClick={reprovarCargar}
                                    isLoading={loadingReprovar}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    width="160px"
                                    disabled={loadingReprovar || loadingAprovar}
                                >
                                    reprovar carga
                                </Button>

                                {!error && (
                                    <Button
                                        color="#fff"
                                        background="#5DB356"
                                        onClick={aprovarCarga}
                                        isLoading={loadingAprovar}
                                        spiner="BallTriangle"
                                        spinerColor="primary"
                                        width="160px"
                                        disabled={
                                            loadingReprovar || loadingAprovar
                                        }
                                    >
                                        aprovar carga
                                    </Button>
                                )}
                            </Permissions>
                        </div>
                    )
                )}
            </div>
        </HeaderStyles>
    );
}
