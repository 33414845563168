import styled from "styled-components";
import { Container as Mes } from "../Mobile/Mes/styles";

export const Container = styled.div`
    width: 100%;

    .slide-mes {
        position: relative;
        height: 44px;
        margin: 24px auto 29px auto;
        width: 90%;
        .controles {
            display: none;
        }
        ${Mes} {
            position: absolute;
            bottom: 2px;
            padding-left: 5%;
            width: 100%;
            height: 99%;
        }
    }

    .container-cards {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 38px;
    }

    .container-loading {
        position: relative;
    }

    .container-filtros {
        display: flex;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 24px;
        justify-content: flex-end;
    }
`;

export const HeaderStyle = styled.div`
    position: relative;
    height: 112px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-bottom: 1px solid #e1e2e3;
    width: 90%;
    margin: 0 auto;
    .container-img {
        position: absolute;
        top: -89px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        img {
        }
    }

    .container-title {
        outline: none;
        h4 {
            color: ${(props) => props.theme.colors.primary};
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14px */
            letter-spacing: 1.4px;
            text-transform: uppercase;
            padding-bottom: 24px;
        }

        img {
            position: absolute;
            top: 72px;
            left: 0px;
            display: block;
        }
    }

    @media (max-width: 768px) {
        height: 58px;
        align-items: center;
        .container-title {
            h4 {
                padding-bottom: 0px;
            }

            img {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .container-img {
            display: none;
        }
    }
`;
