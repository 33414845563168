import styled from "styled-components";
import { Container as HeaderModal } from "../Mobile/HeaderModal/styles";

export const Container = styled.div`
    padding-bottom: 32px;
    .cropContainer {
        position: relative;
        width: 100%;
        height: 200px;
        background-color: #333;
    }

    .cropContainer {
        margin-bottom: 32px;
    }
    ${HeaderModal} {
        margin: 0 5%;
    }
`;
