import { useUser } from "../../../../Hook/system/useUser";

import { IOSSwitch } from "../../Switch";

interface BloqueioProps {
    checked: boolean;
    id: number;
}

export function Bloqueio(props: BloqueioProps) {
    const { bloqueio } = useUser();

    return (
        <IOSSwitch
            checked={props.checked}
            onChange={async (_, check) => {
                await bloqueio(props.id, check ? 0 : 1);
            }}
        />
    );
}
