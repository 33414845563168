import { useState } from "react";
import { Text } from "alisson-application";

import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";

import { Button } from "../Button";

import { Container } from "./styles";
import imgcardTemporaryBlock from "../../../assets/img/cardTemporaryBlock.svg";

export function BloquearCartao() {
    const { bloquear } = UseCartoes();
    const { setState, state, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const [loading, setLoading] = useState(false);

    async function handleBlock() {
        setLoading(true);

        const response = await bloquear();

        setLoading(false);

        if (response.status === 200) {
            setState({
                ...state,
                acaoBloquearCartao: false,
                successGeral: {
                    active: true,
                    title: "Bloqueio",
                    message: "Bloqueado com sucesso",
                },
            });
        }
    }

    return (
        <Container>
            <img src={imgcardTemporaryBlock} alt="" />

            <Text as="h3">Bloqueio Temporário</Text>
            <Text as="p">
                <strong>
                    {`Confirma o bloqueio temporário do cartão com final ${cartao?.acg_account_card_last4digits.slice(-4)}?`}
                </strong>
            </Text>

            <Text as="p">
                Você pode desfazer essa ação a qualquer momento.
            </Text>

            <Button
                onClick={async () => handleBlock()}
                isLoading={loading}
                type="submit"
                spinerColor="primary"
                spiner="BallTriangle"
                mb="32px"
            >
                Bloquear Temporariamente
            </Button>

            <button className="goBackBtn" onClick={() => handleClose("acaoBloquearCartao")}>
                Voltar ao Início
            </button>
        </Container>
    );
}
