import { useEffect, useState } from "react";

import { HeaderModal } from "../Mobile/HeaderModal";
import { useFaleConosco } from "../../Hook/user/UseFaleConosco";
import { UseModal } from "../../Hook/Usemodal";
import { DuvidasFrequentes } from "./duvidasFrequentes";
import { Buttons } from "./btns";
import { Tabs } from "./tabs";
import { Container } from "./styles";

const arrTabs = [
    {
        label: "mensagens",
        active: true,
    },
    {
        label: "enviar",
        active: false,
    },
];

interface FaleConoscoProps {
    handleClose?: () => void;
}

export interface IArrTabs {
    label: string;
    active: boolean;
}

export function FaleConosco(props: FaleConoscoProps) {
    const { handleClose } = props;
    const { getFaleConosco } = useFaleConosco();
    const { state } = UseModal();
    const [tabs, setTabs] = useState(arrTabs);

    function handleTab(str: "mensagens" | "enviar") {
        const updateTab = tabs.map((item) => {
            if (item.label === str) {
                item.active = true;
                return item;
            }

            item.active = false;
            return item;
        });

        setTabs(updateTab);
    }

    useEffect(() => {
        if (state?.faleConosco) {
            getFaleConosco();
        }
    }, [getFaleConosco, state.faleConosco]);

    return (
        <Container>
            <HeaderModal
                title="FALE CONOSCO"
                handleClose={() => {
                    if (handleClose) {
                        handleClose();
                    }
                }}
            />
            <DuvidasFrequentes />

            <h5>enviar mensagem</h5>

            <Buttons handleTab={handleTab} tabs={tabs} />

            <Tabs handleTab={handleTab} tabs={tabs} />
        </Container>
    );
}
