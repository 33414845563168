import { ModalConfig } from "../config";
import ModalRight from "../../ModalRight";
// import Modal from "../../components/NovoModal";

import { AlterarSenha } from "../../AlterarSenha";
import { useState } from "react";
import { Sucesso } from "../../AlterarSenha/sucesso";
import Modal  from "../../../components/NovoModal"

export function ModalAlterarSenha(props: ModalConfig) {
    const { open, handleClose } = props;
    const [showSucess, setShowSuccess] = useState(false);
    return showSucess   ?   <Modal open={open} handleClose={handleClose}>
                                <Sucesso />
                            </Modal>
                        :   <ModalRight open={open} handleClose={handleClose}>
                                <AlterarSenha setShowSuccess={setShowSuccess} />
                            </ModalRight>
}
