import styled from "styled-components";
import imgLupa from "../../../../../assets/img/lupa.svg";

export const Container = styled.div`
    padding-top: 32px;
    .container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 39px;
        border-bottom: 1px solid #57b847;
        padding-bottom: 16px;
        .container-title {
            display: flex;
            align-items: center;
            p {
                color: ${({ theme }) => theme.colors.secondary};
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 1.28px;
                margin-left: 8px;
            }
        }

        .container-btn {
            display: flex;
            align-items: center;
            .container-input-system {
                margin-right: 16px;
                margin-bottom: 0px;
                input {
                    height: 46px;
                    background-color: transparent;
                    border-radius: 600px;
                    margin: 0px !important;
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    left: unset;
                    transform: translateY(-50%);
                    background-color: ${(props) =>
                        props.theme.colors.secondary};
                    -webkit-mask: url(${imgLupa}) no-repeat;
                    width: 19px;
                    height: 17px;
                    z-index: 20;
                    transition: opacity 0.5s;
                }
            }

            button {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                &.active {
                    img {
                        transform: rotateX(180deg);
                        transition: all 0.5s ease-in-out;
                    }
                }

                img {
                    transition: all 0.5s ease-in-out;
                }
                p {
                    margin-left: 8px;
                    color: ${({ theme }) => theme.colors.secondary};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 18.971px;
                }
            }
        }
    }

    .cards {
        height: 0px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        &.active {
            transition: all 0.5s ease-in-out;
        }
    }
`;
