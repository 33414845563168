import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 36px;
    padding-top: 60px;
    position: relative;

    img {
        &.img-close {
            position: absolute;
            top: -87px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
        }
    }

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 36px;
    }

    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: ${({ theme }) => theme.colors.primary};
    }

    .container-itens {
        margin-bottom: 51px;
        .container-descricao {
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0;
            }
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: #000000;
                text-align: center;
            }
            .text-item {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 120%;
                text-align: center;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }

    .container-input {
        width: 100%;
    }
`;
