import styled from "styled-components";
import { Flex } from "alisson-application";
import { CardStyles as CardFront } from "../CardSimple/styles";
import { Container as CardBack } from "../CardBack/styles";

export const Container = styled.div`
    .container-tabs {
        padding-top: 34px;
        padding-bottom: 32px;
    }
`;

export const SolicitarStyles = styled.div`
    p {
        width: 250px;
        margin: 0 auto;
        margin-bottom: 37px;
    }

    .valor-cupom {
        margin-top: 27px;
    }

    form {
        height: 356px;
        display: flex;
        flex-direction: column;
    }

    button {
        margin-top: auto;
    }
`;

export const ConfirmaDadosCartaoStyles = styled.div`
    p {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 37px;
        width: 318px;
    }

    form {
        .container-input {
            &.active {
                &:after {
                    display: none;
                }
            }
        }
    }
`;

export const ConfereDados = styled.div`
    .container-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-animation-cards-firula {
        width: 158.97px;
        height: 252.67px;
        perspective: 600px;
        margin: 0 auto;
        .cards {
            width: 100%;
            height: 100%;
            position: relative;
            transform-style: preserve-3d;

            animation-play-state: paused;

            &.in {
                animation: animationCardIn 1s ease-in forwards;
            }

            &.out {
                animation: animationCardOut 1s ease-in backwards;
            }

            .card__face {
                position: absolute;
                height: 100%;
                width: 100%;
                &.card__face--back {
                    background: blue;
                    transform: rotateY(0deg) rotateZ(0deg);
                    border-radius: 8px;
                }
            }
            ${CardBack} {
                position: absolute;
                height: 100%;
                width: 100%;
                transform: rotateY(0deg) rotateZ(90deg) rotateX(180deg);
                border-radius: 8px;
                .container-img {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    img {
                        width: 252px;
                        height: auto;
                        position: absolute;
                        top: 48px;
                        left: -48px;
                    }
                }

                .container-text {
                    top: 92px;
                    left: -40px;
                    transform: rotate(0deg);
                    .numero {
                        span {
                            font-size: 8.7431px;
                            margin: 0px;
                        }
                    }
                    .conta {
                        span {
                            font-size: 8.7431px;
                        }
                    }
                    .data-code {
                        margin-bottom: 11px;
                    }
                }
            }
            ${CardFront} {
                position: absolute;
                height: 100%;
                width: 100%;
                backface-visibility: hidden;
                transform: rotateY(0deg) rotateZ(0deg);
            }
        }
    }

    h3 {
        text-align: center;
    }

    ul {
        margin-top: 23px;
        margin-bottom: 35px;
        li {
            min-height: 48px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e1e2e3;
            padding: 14px 0px;
            .container-img {
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
            }
        }
    }

    @keyframes animationCardIn {
        0% {
            transform: rotateZ(0deg);
        }
        50% {
            transform: rotateZ(-45deg);
        }
        50% {
            transform: rotateZ(-90deg);
        }

        100% {
            transform: rotateZ(-90deg) rotateY(180deg);
            backface-visibility: unset;
        }
    }
    @keyframes animationCardOut {
        0% {
            transform: rotateZ(-90deg) rotateY(180deg);
            backface-visibility: unset;
        }

        50% {
            transform: rotateZ(-45deg);
        }
        50% {
            transform: rotateZ(-90deg);
        }
        100% {
            transform: rotateZ(0deg);
        }
    }
`;

export const FinalizarStyles = styled(Flex)`
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
        width: 196px;
    }

    h3 {
        margin-top: -40px;
        margin-bottom: 26px;
    }

    p {
        width: 296px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 113px;
    }
`;

export const EnderecoStyles = styled.div`
    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 35px;
    }

    .container-endereco {
        display: grid;
        grid-template-columns: 24px 1fr;
        grid-gap: 16px;
        align-items: start;
        padding: 19px 27px 21px;
        border: 1px solid ${(props) => props.theme.colors.secondary};
        border-radius: 8px;
        &.alert {
            border: 1px solid transparent;
            img {
                margin-top: 6px;
            }
        }

        .container-img {
            text-align: center;
        }

        .container-text {
            text-transform: uppercase;
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primary};
            }

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }
`;

export const CustoCartao = styled.div`
    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        width: 244px;
        margin: 0 auto;
        margin-bottom: 19px;
    }

    p {
        font-family: "Oxanium";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryDark};
    }

    .check {
        margin: 29px 0px 22px;
        span {
            div {
                font-weight: bold;
                font-size: ${({ theme }) => theme.fonts.xs};
                white-space: nowrap;
                line-height: 23px;
            }
        }
    }
`;
