import { LimitiCardsStyles } from "./styles";
import imgCardFisico from "../../../assets/img/card-fisico.svg";
import imgCardVirtual from "../../../assets/img/card-virtual.svg";
import {
    tempoFimPlataforma,
    tempoInicioPlataforma,
} from "../../../util/helper";

export function LimitiCards() {
    return (
        <LimitiCardsStyles>
            <div className="container-alerta">
                <h3>Atenção!</h3>
                <p>
                    <strong>Pagamento de Contas com:</strong>
                </p>
            </div>

            <div className="contaner-cartao-fisico">
                <div className="container-img">
                    <img src={imgCardFisico} alt="" />
                </div>

                <div className="container-text">
                    <h4>Cartões Físicos:</h4>
                    <p>
                        {`Até R$ 10.000,00 mensais e limitado a R$ 1.500,00 por boleto, em dias úteis, das ${tempoInicioPlataforma}h às ${tempoFimPlataforma}h.`}
                    </p>
                </div>
            </div>

            <div className="contaner-cartao-virtual">
                <div className="container-img">
                    <img src={imgCardVirtual} alt="" />
                </div>

                <div className="container-text">
                    <h4>Cartões Virtuais:</h4>
                    <p> 
                        {`Até R$ 500,00 mensais, em dias úteis, das ${tempoInicioPlataforma}h às ${tempoFimPlataforma}h (para pagamentos mais altos, solicite Cartão Físico).`}
                    </p>
                </div>
            </div>
        </LimitiCardsStyles>
    );
}
