import styled from "styled-components";

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center;

    padding: 80px 30px;
    min-height: 600px;
    border-radius: 20px;

    h1 {
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 800;
        line-height: 25px;
        text-align: center;
        color: #57b847;
        max-width: 200px;
        margin: 30px 0 50px;
        text-transform: uppercase;
    }

    svg {
        width: 50px;
        height: 50px;

        path {
            fill: #57b847;
        }
    }
`;
