import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 50px 27px 35px;
    position: relative;

    form {
        width: 327px;
        button[type="submit"] {
            margin-top: 22px;
        }
    }
    img {

    }

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        color: ${({ theme }) => theme.colors.failure};
        margin-bottom: 15px;
        margin-top:23px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: #5E5F5F;
        width: 100%;
        margin-bottom: 9px;
        padding: 0 10px;
    }

    .container-input {
        width: 100%;
        &.olho {
            button {
                height: 28px;
            }
        }
    }

    button{
        height:40px;
        text-transform: none;
        font-weight: 700;
        margin:36px 0 0;
        

        &.goBackBtn{
            font-weight: 400;
            color:#5E5F5F;
            margin-top:0;
        }
    }


`;
