import { Flex, Text } from "alisson-application";
import { Button } from "../Mobile/Button";
import { LineTextBoleto } from "../LineTextBoleto";

import imgCalendatio from "../../assets/img/calendario.svg";
import { ContainerValor } from "../ContainerValor";

import { usePassos } from "../../Hook/UsePassos";
import {
    formatDate,
    formatCurrentMoney,
    isDateInFuture,
    tempoInicioPlataforma,
    tempoFimPlataforma,
} from "../../util/helper";
import { Container } from "./styles";
import { ItemConfirmacao } from "../CardConfirmacao";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { useState } from "react";
export function DadosBoleto() {
    const [descricao, setDescricao] = useState("");
    const { nextPasso, dados, setDados } = usePassos();
    const { cartao } = UseCartoes();
    const datePagamento = new Date()
        .toLocaleString()
        .split(",")[0]
        .replace(/\//g, ".");
    const linhaDigitavel = dados?.boleto?.digitable || "";

    const hasFisictype = cartao?.tipo === "cartao_fisico" ? true : false;
    let cardNumber = cartao?.acg_account_card_last4digits || "";
    const valor = dados.boleto?.value || 0;

    if (!hasFisictype && cartao?.acg_account_card_last4digits) {
        const formatCardNumber = cartao.acg_account_card_last4digits;
        cardNumber = formatCardNumber.substring(formatCardNumber.length - 4);
    } else {
        cardNumber =
            cartao?.acg_account_card_last4digits.substring(
                cartao?.acg_account_card_last4digits.length - 4
            ) || "";
    }

    const boletoVencido = isDateInFuture(new Date(dados.boleto?.dueDate));

    return (
        <Container>
            <LineTextBoleto title="Tipo de pagamento:" text="Boleto" />

            <ItemConfirmacao
                title="Instituição Emissora:"
                text={dados.boleto?.assignor || ""}
            />

            <ItemConfirmacao
                title="Linha digitável:"
                text={linhaDigitavel.replace(
                    /([0-9]{5})([0-9]{5})([0-9]{5})([0-9]{6})([0-9]{5})([0-9]{6})([0-9]{1})/,
                    "$1.$2 $3.$4 $5.$6 $7 "
                )}
            />

            <div className="container-datas">
                <LineTextBoleto
                    img={imgCalendatio}
                    title="Data de Vencimento:"
                    text={formatDate(dados.boleto?.dueDate || "")}
                />

                <Flex mb="13px" mt="30px">
                    <Text color="secondary" bold>
                        Data de Pagamento:
                    </Text>
                </Flex>

                <div className="container-input disabled">
                    <input type="text" value={datePagamento} />
                </div>
            </div>

            <LineTextBoleto
                title="Valor do Título:"
                text={`R$ ${formatCurrentMoney(valor || 0)}`}
            />
            <LineTextBoleto
                title="Valor do Desconto:"
                text={`R$ ${formatCurrentMoney(
                    dados.boleto?.discountValue || 0
                )}`}
            />
            <LineTextBoleto
                title="Valor dos Juros:"
                text={`R$ ${formatCurrentMoney(
                    dados.boleto?.interestValueCalculated || 0
                )}`}
            />

            <LineTextBoleto
                title="Valor da Multa:"
                text={`R$ ${formatCurrentMoney(
                    dados.boleto?.fineValueCalculated || 0
                )}`}
            />

            <ContainerValor
                value={formatCurrentMoney(dados.boleto?.value || 0)}
            />

            <div className="container-detalhes">
                <Text mb="27px" fontSize="xss" color="primaryDark">
                    Saldo disponível no Cartão final {cardNumber}:{" "}
                    {formatCurrentMoney(parseFloat(dados.boleto?.saldo || 0))}
                </Text>

                <Text mb="16px" fontSize="md" color="primaryDark" bold>
                    Em caso de dúvida confira...
                </Text>

                <Text
                    fontSize="xss"
                    color="secondary"
                    bold
                    textTransform="uppercase"
                >
                    cartões físicos:
                </Text>
                <Text mb="16px" fontSize="xss" color="primaryDark">
                    Boleto de até R$ 1.500,00 (máximo de R$ 10.000,00 <br /> por
                    mês, em dias úteis, das {tempoInicioPlataforma}h às{" "}
                    {tempoFimPlataforma}h).
                </Text>

                <Text
                    fontSize="xss"
                    color="secondary"
                    bold
                    textTransform="uppercase"
                >
                    cartões virtuais:
                </Text>
                <Text mb="32px" fontSize="xss" color="primaryDark">
                    Boleto de até R$ 500,00 (máximo de R$ 500,00 <br />
                    por mês, em dias úteis, das {tempoInicioPlataforma}h às{" "}
                    {tempoFimPlataforma}h).
                </Text>

                {!boletoVencido ? (
                    <Text mb="16px" fontSize="xss" color="primaryDark">
                        *Boleto vencido, valor para pagamento já está
                        atualizado.
                    </Text>
                ) : (
                    <></>
                )}
            </div>

            <div className="container-input descricao">
                <input
                    type="text"
                    placeholder="Descrição:"
                    onChange={(e) => {
                        setDescricao(e.target.value);
                    }}
                />
                <Text mt="10px" mb="38px" fontSize="xss" color="primaryDark">
                    Preenchimento do campo acima é opcional.
                </Text>
            </div>

            <Button
                onClick={() => {
                    nextPasso(3);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    setDados({
                        boleto: {
                            ...dados.boleto,
                            customerDescription: descricao,
                        },
                    });
                }}
            >
                revisar pagamento
            </Button>
        </Container>
    );
}
