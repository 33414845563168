import MobileDetec from "mobile-detect";
const hasiPhone = new MobileDetec(window.navigator.userAgent).is("iPhone");
const hasSafari =
    new MobileDetec(window.navigator.userAgent).userAgent() === "Safari";

export const fonts = {
    xl: "2.5rem",
    lg: "2rem",
    mdl: "1.5rem",
    md: "1rem",
    sm: "0.875rem",
    xss: "0.75rem",
};

// theme dark

export const lightColors = {
    white: "#FFFFFF",
    black: "#000000",

    primary: "#3A3B3D",
    primaryBright: "#919598 ",
    primaryDark: "#767B7F",

    secondary: "#57B847",
    secondarybright: "#BCDFBA",
    secondarydark: "#4C9746",

    tertiary: "#FCFCFC",

    tertiarybright:
        "linear-gradient(111.27deg, #5DB356 -2.09%, #58B356 103.94%)",
    tertiarydark: "#16343B",

    background: "#F4F3EB",
    backgroundAlt: "#F1F5F0",
    disabled: hasiPhone || hasSafari ? "#bddebb80" : "#eaf5eb",
    failure: "#EB001B",
    success: "#11ae68",
    warning: "#FF5F00",
    contrast: "#000",
};
//
// theme light
export const darkColorss = {
    white: "#000",
    primary: "#fff",
    secondary: "#1976d2",
    primaryBright: "#10161b",
    primaryDark: "#fff",
    failure: "#fa0000",
    success: "#11ae68",
    warning: "#F4D35E",
    contrast: "#000",
};
