import { usePassos } from "../../../Hook/UsePassos";
import { UseModal, Modais } from "../../../Hook/Usemodal";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";
import imgSetaEsq from "../../../assets/img/seta-esq-verde.svg";

interface HeaderPassosProps {
    title: string;
    handleClose?: () => void;
}
type keyOf<T> = keyof T;

export function HeaderPassos(props: HeaderPassosProps) {
    const history = useHistory();
    const { title, handleClose } = props;
    const { passos = [], currentPasso, prevPassos } = usePassos();
    const { setState, state } = UseModal();
    const width = window.screen.width;
    function handlePrevPassos() {
        if (currentPasso - 1 === 0) {
            if (handleClose) {
                handleClose();
            } else {
                history.goBack();
            }
        } else {
            if (passos[currentPasso - 1]?.goBack) {
                if (width <= 480) {
                    history.goBack();
                } else {
                    const keys = Object.keys(state) as keyOf<Modais>[];
                    let newState: Modais = {} as Modais;

                    keys.forEach((item) => {
                        if (item !== "errorGeral" && item !== "successGeral") {
                            newState[item] = false;
                        }
                    });

                    setState({
                        ...state,
                        ...newState,
                    });
                }
            } else {
                prevPassos(currentPasso);
            }
        }
    }

    return (
        <Container className="container">
            <button onClick={() => handlePrevPassos()}>
                <img src={imgSetaEsq} alt="Voltar" />
                {title}
            </button>

            <div className="container-passos">
                {passos.map((item, key) => (
                    <div
                        key={key}
                        className={`${item.completed ? "active" : ""}`}
                    ></div>
                ))}
            </div>
        </Container>
    );
}
