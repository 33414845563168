import { AlertDialogSlide } from "../Alert";
import { Tabs } from "./tabs";
import { useWhiteList } from "../../../Hook/system/useWhiteList";
import { Content } from "./content";

import { Header } from "./header";

export function WhiteList() {
    const { deleteList, setModaldialog, modaldialog, id } = useWhiteList();

    async function handleDelete() {
        if (id) {
            await deleteList(id + "");
            setModaldialog(false);
        }
    }

    return (
        <>
            <Header />

            <Tabs />

            <Content />

            <AlertDialogSlide
                handleClose={() => setModaldialog(false)}
                open={modaldialog}
                handleConfirm={handleDelete}
                title="Excluir"
                subTitle="Confirma exclusão"
            />
        </>
    );
}
