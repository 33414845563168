import { useEffect, useState } from "react";
import { CartaoFisico } from "../CartaoFisico";
import { CartaoDigital } from "../CartaoDigital";
import { CartaoAvulso } from "../CartaoAvulso";
import { usePassos } from "../../../Hook/UsePassos";
import { useHistory } from "react-router-dom";
import { HeaderPassos } from "../HeaderPassos";
import { UseModal } from "../../../Hook/Usemodal";
import { useTranslation } from "../../../contexts/Localization";

import { Solicitar } from "./solicitar";
import { keyOf, TipoCartao } from "./config";
import { Container } from "./styles";
import { getCampanha } from "../../../util/helper";

export const arrFisico = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
    {
        passo: 5,
        active: false,
        completed: false,
    },
    {
        passo: 6,
        active: false,
        completed: false,
    },
    {
        passo: 7,
        active: false,
        completed: false,
        goBack: true,
    },
];

export const arrFisicoSemTaxa = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
    {
        passo: 5,
        active: false,
        completed: false,
    },
    {
        passo: 6,
        active: false,
        completed: false,
        goBack: true,
    },
];

export const arrVirtual = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
    {
        passo: 5,
        active: false,
        completed: false,
        goBack: true,
    },
];

export const arrFisicoAvulso = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
];

export function SolcitarCartao() {
    const width = window.screen.width;
    const [tipoCartao, setTipoCartao] =
        useState<keyOf<TipoCartao>>("cartao_fisico");
    const { state, setState } = UseModal();
    const { setPassos, passos } = usePassos();
    const { dados, setDados } = usePassos();
    const { t } = useTranslation();
    const history = useHistory<{ valid: boolean }>();
    const hasPath = history.location.pathname.includes("/novo-cartao");

    useEffect(() => {
        if (width <= 480) {
            if (!history?.location?.state?.valid) {
                history.push("/dashboard");
            }
        }

        if (state?.novoCartao || hasPath) {
            if (tipoCartao === "cartao_fisico") {
                setPassos(arrFisico);
            }

            if (tipoCartao === "cartao_virtual") {
                setPassos(arrVirtual);
            }

            if (tipoCartao === "cartao_avulso") {
                setPassos(arrFisicoAvulso);
            }
        }
    }, [setPassos, state, hasPath, tipoCartao, width, history]);

    useEffect(() => {
        if (
            getCampanha().id &&
            state.novoCartao &&
            tipoCartao === "cartao_fisico"
        ) {
            getCampanha().produtos.forEach((prod) => {
                if (prod.produto.tipo === "cartao_fisico") {
                    if (prod.taxa_cobranca === "0.00") {
                        setPassos(arrFisicoSemTaxa);
                    }

                    setDados({
                        ...dados,
                        valor_cobranca: prod.taxa_cobranca,
                    });
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPassos, state.novoCartao, tipoCartao]);

    return (
        <Container>
            <HeaderPassos
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, novoCartao: false });
                        setPassos([]);
                    }
                }}
                title={t("solicitar cartão")}
            />

            <div className="container-tabs">
                <div
                    className={`tab ${
                        passos[0]?.active && state?.novoCartao ? "active" : ""
                    }`}
                >
                    {passos[0]?.active && state?.novoCartao ? (
                        <Solicitar setTipoCartao={setTipoCartao} />
                    ) : null}
                </div>

                {tipoCartao === "cartao_avulso" && <CartaoAvulso />}
                {tipoCartao === "cartao_fisico" && <CartaoFisico />}
                {tipoCartao === "cartao_virtual" && <CartaoDigital />}
            </div>
        </Container>
    );
}
