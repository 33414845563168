import styled from "styled-components";

export const Container = styled.div`
    h4 {
        color: ${(props) => props.theme.colors.secondary};
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 26px;
    }

    .container-informacoes {
        margin: 13px auto;
        .item {
            padding-bottom: 19px;
            border-bottom: 1px solid #e1e2e3;
            margin-bottom: 19px;
        }
    }
`;
