import styled from "styled-components";

export const SecondStrikeContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    height: 420px;
    position: relative;
    justify-content: flex-start !important;
    padding: 50px 20px 35px;

    h1 {
        margin-top:24px;
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #EB001B;
        text-transform: uppercase;
        white-space: break-spaces;
    }

    p {
        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: center;
        color: #000000;
        max-width: 260px;
        margin: 20px 0 20px;

        b {
            font-weight: 700;
        }
    }

    button {
        height: 40px;
        width: 100%;
        max-width: 273px;

        font-family: Oxanium;
        font-size: 14px;
        font-weight: 400;
        text-align: center;

        &.primary {
            color: #fff;
            font-weight: 700;
            background-color: #57b847;
            border-radius: 100px;
            text-transform: uppercase;
            margin: 0 0 20px;
            height: 40px;
        }
    }
`;
