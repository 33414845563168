import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 27px 35px;
    
    
    img {
        margin-bottom:22px; 
    }

    h1 {
        flex:1;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.secondarydark};
        margin-bottom: 15px;
    }

    p{
        color: #5e5f5f;
        font-size: 14px;
        margin-bottom: 28px;
    }


    button{
        max-width: 273px;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
    }

    .cancelar {
        margin-top: 4px;
        font-weight: 400;
        color: #5e5f5f;
    }
`;
