import { useHistory } from "react-router-dom";
import { Meses } from "../../Desktop/Meses";
import { GoBack } from "../GoBack";
// import { Relatorio } from "../Relatorio";
import { Relatorio } from "../Relatorio";
import { useExtrato, Extrato } from "../../../Hook/user/useExtrato";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import { formatDate } from "../../../util/helper";
import { Empty } from "../Empty";
import { FiltroEntradaSaida } from "./FiltroEntradaSaida";
import { FiltroDate } from "./FiltroDate";
// import { GraficoPositiveNegative } from "../GraficoPositiveNegative";

import { Container } from "./styles";
import { Loader } from "../../Loader";
import { useEffect, useState } from "react";

interface MovimentacaoProps {}

export function Movimentacao(props: MovimentacaoProps) {
    const history = useHistory();
    const width = window.screen.width;
    const [dados, setDados] = useState<Extrato[][]>([]);
    const [filtro, setFiltro] = useState<Extrato[][]>([]);
    const { extratos, getExtrato, loadingExtrato } = useExtrato();
    const { cartaoId } = UseCartoes();
    const { handleClose } = UseModal();
    const [entrada, setEntrada] = useState(false);
    const [saida, setSaida] = useState(false);

    const [antigo, setAntigo] = useState(false);
    const [recent, setRecent] = useState(false);

    async function handleClick(date: Date) {
        if (cartaoId) {
            await getExtrato(new Date(date));
        }
    }

    useEffect(() => {
        const update = [...dados];
        if (entrada) {
            setFiltro(
                update.map((item) => {
                    return item.filter((item) => item.tipo === "credito");
                })
            );
            return;
        }

        if (saida) {
            setFiltro(
                update.map((item) => {
                    return item.filter((item) => item.tipo === "debito");
                })
            );
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entrada, saida]);

    useEffect(() => {
        const update = [...dados];
        if (antigo) {
            setFiltro(update.reverse());
            return;
        }

        if (recent) {
            setFiltro(update);
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [antigo, recent]);

    useEffect(() => {
        const arrDate = [
            ...new Set(
                extratos.map((item) => {
                    return formatDate(item.created_at);
                })
            ),
        ];

        const arrGroupedDate = arrDate.map((item) => {
            return extratos.filter((extrato) => {
                return formatDate(extrato.created_at) === item;
            });
        });
        setAntigo(false);
        setRecent(true);
        setDados(arrGroupedDate);
    }, [extratos]);

    return (
        <Container>
            <GoBack
                title="EXTRATO DA CARTEIRA"
                onClick={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        handleClose("extrato");
                    }
                }}
            />
            <div className="slide-mes">
                <Meses handleClick={handleClick} />
            </div>

            <div className="container-filtros">
                <FiltroEntradaSaida
                    handleEntrada={setEntrada}
                    handleSaida={setSaida}
                />
                <FiltroDate
                    reset={recent}
                    handleAntigo={setAntigo}
                    handleRecent={setRecent}
                />
            </div>

            {loadingExtrato ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : dados.length !== 0 ? (
                filtro.length ? (
                    filtro.map((item: Extrato[]) => {
                        if (item.length === 0) return <></>;

                        return <Relatorio dados={item} />;
                    })
                ) : (
                    dados.map((item: Extrato[]) => {
                        if (item.length === 0) return <></>;

                        return <Relatorio dados={item} />;
                    })
                )
            ) : (
                <Empty />
            )}
        </Container>
    );
}
