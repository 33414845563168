import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .container-img {
        position: relative;
        .close {
            position: absolute;
            right: -5px;
            width: 30px;
            top: -30px;
            cursor: pointer;
        }
    }
    .img-banner {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 80vh;
    }

    @media (max-width: 480px) {
        width: 100%;
        height: 80vh;

        .img-pop-up {
            height: 80vh;
        }
        .close {
            top: 0;
        }
    }
`;
