import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Header } from "./header";
import { SubHeader } from "./subheader";
import { Empty } from "../Usuarios/main/empty";
import { Box, Flex } from "alisson-application";
import { TableUser } from "./table";
import { Container } from "./styles";
import { useCartao } from "../../../Hook/system/useCartao";
import { Button } from "../Button";

export function BuscaCartao() {
    const { search, cards, loading, config, getCard, exportarCartao } =
        useCartao();
    const [loadingExport, setLoadingExport] = useState(false);

    useEffect(() => {
        if (config?.total === 0) {
            toast.warn("Nenhum resultado encontrado");
        }
    }, [config]);

    return (
        <Container>
            <Header />
            <SubHeader />

            {cards.length === 0 && (
                <Flex alignItems="center" justifyContent="center" mt="75px">
                    <Empty
                        title={
                            config?.total === 0
                                ? "NENHUM RESULTADO ENCONTRADO"
                                : "PREENCHA O CAMPO DE BUSCA PARA ENCONTRAR UM USUÁRIO"
                        }
                    />
                </Flex>
            )}

            {cards.length > 0 && (
                <>
                    {!loading ? (
                        <Box width="140px">
                            <Button
                                disabled={loadingExport}
                                isLoading={loadingExport}
                                onClick={async () => {
                                    setLoadingExport(true);
                                    await exportarCartao();
                                    setLoadingExport(false);
                                }}
                            >
                                Exportar
                            </Button>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <TableUser
                        loading={loading}
                        cards={cards || []}
                        config={config}
                        pagination={getCard}
                        search={search}
                    />
                </>
            )}
        </Container>
    );
}
